import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "layout-page" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "flex-grow-1 container-p-y px-4" }
const _hoisted_4 = { class: "d-flex justify-content-between aling-items-center" }
const _hoisted_5 = { class: "flex-grow-1 container-p-y px-4" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "text-nowrap" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "py-3" }
const _hoisted_13 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_14 = {
  key: 1,
  class: "row invoice-preview"
}
const _hoisted_15 = { class: "col-xl-9 col-md-8 col-12 mb-md-0 mb-4" }
const _hoisted_16 = {
  class: "card invoice-preview-card",
  id: "invoice-container"
}
const _hoisted_17 = { class: "card-body" }
const _hoisted_18 = { class: "row p-sm-3 p-0" }
const _hoisted_19 = { class: "col-xl-12 col-md-12 col-sm-7 col-12" }
const _hoisted_20 = { class: "" }
const _hoisted_21 = { class: "card-body" }
const _hoisted_22 = { class: "d-flex py-4 flex-sm-nowrap flex-wrap justify-content-between mt-4" }
const _hoisted_23 = { class: "" }
const _hoisted_24 = { class: "border p-3" }
const _hoisted_25 = { class: "text-end mt-4 md-0" }
const _hoisted_26 = { class: "mb-0" }
const _hoisted_27 = { class: "mb-0" }
const _hoisted_28 = { class: "col-xl-3 col-md-4 col-12 invoice-actions" }
const _hoisted_29 = { class: "card mb-3" }
const _hoisted_30 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBarView = _resolveComponent("TopBarView")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopBarView),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[3] || (_cache[3] = _createElementVNode("div", null, [
                _createElementVNode("h4", { class: "fw-bold py-3 mb-4" }, [
                  _createElementVNode("span", { class: "text-muted fw-light" }, "Due"),
                  _createTextVNode(" History ")
                ])
              ], -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_router_link, {
                  to: "/due",
                  class: "btn btn-primary mt-2"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Back to List")
                  ])),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, [
                                  (_ctx.loading)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[4] || (_cache[4] = [
                                        _createElementVNode("div", {
                                          class: "spinner-border text-primary",
                                          role: "status"
                                        }, [
                                          _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                                        ], -1)
                                      ])))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                        _createElementVNode("div", _hoisted_15, [
                                          _createElementVNode("div", _hoisted_16, [
                                            _createElementVNode("div", _hoisted_17, [
                                              _createElementVNode("div", _hoisted_18, [
                                                _createElementVNode("div", _hoisted_19, [
                                                  _createElementVNode("table", null, [
                                                    _createElementVNode("tbody", null, [
                                                      _createElementVNode("tr", null, [
                                                        _cache[5] || (_cache[5] = _createElementVNode("td", { class: "pe-3" }, [
                                                          _createElementVNode("strong", null, "নাম: ")
                                                        ], -1)),
                                                        _createElementVNode("td", null, [
                                                          _createElementVNode("strong", null, _toDisplayString(_ctx.orderData?.customer?.name), 1)
                                                        ])
                                                      ]),
                                                      _createElementVNode("tr", null, [
                                                        _cache[6] || (_cache[6] = _createElementVNode("td", { class: "pe-3" }, "ফোন :", -1)),
                                                        _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer?.phone), 1)
                                                      ]),
                                                      _createElementVNode("tr", null, [
                                                        _cache[7] || (_cache[7] = _createElementVNode("td", { class: "pe-3" }, "ঠিকানা:", -1)),
                                                        _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer?.address), 1)
                                                      ])
                                                    ])
                                                  ])
                                                ])
                                              ])
                                            ]),
                                            _cache[12] || (_cache[12] = _createElementVNode("hr", { class: "my-0" }, null, -1)),
                                            _createElementVNode("div", _hoisted_20, [
                                              _createElementVNode("div", _hoisted_21, [
                                                _createElementVNode("p", null, "প্রিয় : " + _toDisplayString(_ctx.orderData?.customer?.name) + ",", 1),
                                                _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-wrap text-center" }, " আশা করি আপনি ভাল আছেন। আসছে নতুন বছর, আগামি ২৭,২৮ শে ভাদ্র ১৪২৭ (ইং ১৩ ও ১৪ ই সেপ্টেম্বর) রবিবার ও সোমবার আমাদের দকানে নতুন হালখাতার শুভ উদ্ভদন করা হইবে। উক্ত শুভক্ষণে আপনার উপস্থিত কামনা করা হইল। ", -1)),
                                                _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-wrap text-center" }, " গত বছরে আপনির লেনদেনগুলি আমাদের কাছে গুরুত্বপূর্ণ ছিল, আপনার সর্বমোট লেনদেন এর পরিমান ও বাকির পরিমানের বিবরন নিচে দেওয়া হল। ", -1)),
                                                _cache[11] || (_cache[11] = _createElementVNode("hr", { class: "my-0" }, null, -1)),
                                                _createElementVNode("div", _hoisted_22, [
                                                  _createElementVNode("div", _hoisted_23, [
                                                    _createElementVNode("span", _hoisted_24, [
                                                      _createElementVNode("strong", null, "সর্বমোট বাকিঃ " + _toDisplayString(Math.abs(_ctx.orderData?.due)) + " টাকা", 1)
                                                    ])
                                                  ]),
                                                  _createElementVNode("div", _hoisted_25, [
                                                    _cache[8] || (_cache[8] = _createElementVNode("p", { class: "mb-0" }, " শুভ কামনা,", -1)),
                                                    _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.orderData?.store.name), 1),
                                                    _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.orderData?.store.address), 1)
                                                  ])
                                                ])
                                              ])
                                            ]),
                                            _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"card-body\"><div class=\"row\"><div class=\"col-12\"><span class=\"text-wrap\">Design &amp; Developed by <strong>PhicsArt</strong>!  <img src=\"https://phicsart.com/uploads/images/top-page/menu/logo.svg\" alt=\"\" class=\"img-fluid\" style=\"max-width:13px;\"></span></div></div></div>", 1))
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_28, [
                                          _createElementVNode("div", _hoisted_29, [
                                            _createElementVNode("div", _hoisted_30, [
                                              _createElementVNode("button", {
                                                class: "btn btn-primary d-grid w-100 mb-3",
                                                "data-bs-toggle": "offcanvas",
                                                "data-bs-target": "#sendInvoiceOffcanvas",
                                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.printInvoice('print')))
                                              }, _cache[14] || (_cache[14] = [
                                                _createElementVNode("span", { class: "d-flex align-items-center justify-content-center text-nowrap" }, [
                                                  _createElementVNode("i", { class: "bx bx-printer" }),
                                                  _createTextVNode("   Print A4")
                                                ], -1)
                                              ])),
                                              _createElementVNode("button", {
                                                class: "btn btn-label-secondary btn-default border d-grid w-100 mb-3",
                                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.downloadInvoice(_ctx.orderData)))
                                              }, _cache[15] || (_cache[15] = [
                                                _createElementVNode("span", { class: "d-flex align-items-center justify-content-center text-nowrap" }, [
                                                  _createElementVNode("i", { class: "bx bx-download" }),
                                                  _createTextVNode("   PDF Download")
                                                ], -1)
                                              ])),
                                              _cache[16] || (_cache[16] = _createElementVNode("button", { class: "btn btn-label-secondary border d-grid w-100 mb-3" }, [
                                                _createElementVNode("span", { class: "d-flex align-items-center justify-content-center text-nowrap" }, [
                                                  _createElementVNode("i", { class: "bx bx-paper-plane bx-xs me-1" }),
                                                  _createTextVNode("Send Mail")
                                                ])
                                              ], -1))
                                            ])
                                          ])
                                        ])
                                      ]))
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _createVNode(_component_Footer),
      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "content-backdrop fade" }, null, -1))
    ])
  ]))
}