import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "layout-page" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "flex-grow-1 container-p-y px-4" }
const _hoisted_5 = { class: "d-flex justify-content-between aling-items-center" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = {
  key: 0,
  class: "alert alert-success alert-dismissible",
  role: "alert"
}
const _hoisted_8 = {
  key: 1,
  class: "alert alert-danger alert-dismissible",
  role: "alert"
}
const _hoisted_9 = {
  key: 2,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-md-9 col-sm-12" }
const _hoisted_12 = { class: "card" }
const _hoisted_13 = { class: "card-body" }
const _hoisted_14 = { class: "" }
const _hoisted_15 = { class: "row mb-3" }
const _hoisted_16 = { class: "col-sm-12" }
const _hoisted_17 = { class: "row mb-3" }
const _hoisted_18 = { class: "col-sm-12" }
const _hoisted_19 = { class: "row mb-3" }
const _hoisted_20 = { class: "col-md-4 col-sm-12 mb-3" }
const _hoisted_21 = { class: "col-sm-12" }
const _hoisted_22 = { class: "col-md-4 col-sm-12 mb-3" }
const _hoisted_23 = { class: "col-sm-12" }
const _hoisted_24 = { class: "col-md-4 col-sm-12 mb-3" }
const _hoisted_25 = { class: "col-sm-12" }
const _hoisted_26 = { class: "col-md-6 col-sm-12" }
const _hoisted_27 = { class: "col-sm-12" }
const _hoisted_28 = { class: "col-md-6 col-sm-12 mb-3" }
const _hoisted_29 = { class: "col-sm-12" }
const _hoisted_30 = { class: "col-md-3 col-sm-12" }
const _hoisted_31 = { class: "card mb-3" }
const _hoisted_32 = { class: "card-body" }
const _hoisted_33 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { for: "basic-icon-default-fullname" }
const _hoisted_36 = ["onUpdate:modelValue"]
const _hoisted_37 = ["value"]
const _hoisted_38 = { class: "col-md-12 col-sm-12" }
const _hoisted_39 = { class: "col-sm-12" }
const _hoisted_40 = ["disabled"]
const _hoisted_41 = { key: 0 }
const _hoisted_42 = {
  key: 1,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_43 = {
  key: 2,
  class: "visually-show"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBarView = _resolveComponent("TopBarView")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopBarView),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[10] || (_cache[10] = _createElementVNode("div", null, [
                _createElementVNode("h4", { class: "fw-bold py-3 mb-4" }, [
                  _createElementVNode("span", { class: "text-muted fw-light" }, "Product"),
                  _createTextVNode(" Settings ")
                ])
              ], -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_router_link, {
                  to: "/product",
                  class: "btn btn-primary mt-2"
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode("Back to List")
                  ])),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.successReport)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[11] || (_cache[11] = [
                    _createTextVNode(" Successfully "),
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn-close",
                      "aria-label": "Close"
                    }, null, -1)
                  ])))
                : _createCommentVNode("", true),
              (_ctx.errorPrice)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[12] || (_cache[12] = [
                    _createTextVNode(" Please Give The Valide Price Like (36.56) or (58.56) In The Buy & Sell Price "),
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn-close",
                      "aria-label": "Close"
                    }, null, -1)
                  ])))
                : _createCommentVNode("", true),
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[13] || (_cache[13] = [
                    _createElementVNode("div", {
                      class: "spinner-border text-primary",
                      role: "status"
                    }, [
                      _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                    ], -1)
                  ])))
                : (_openBlock(), _createElementBlock("form", {
                    key: 3,
                    name: "",
                    onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                _cache[14] || (_cache[14] = _createElementVNode("label", {
                                  class: "col-sm-12 col-form-label",
                                  for: "basic-icon-default-store"
                                }, "Name ", -1)),
                                _createElementVNode("div", _hoisted_16, [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "text",
                                    class: "form-control",
                                    id: "basic-icon-default-fullname",
                                    placeholder: "Name",
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
                                    required: ""
                                  }, null, 512), [
                                    [_vModelText, _ctx.formData.name]
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_17, [
                                _cache[15] || (_cache[15] = _createElementVNode("label", {
                                  class: "col-sm-12 col-form-label",
                                  for: "basic-icon-default-store"
                                }, "Description ", -1)),
                                _createElementVNode("div", _hoisted_18, [
                                  _withDirectives(_createElementVNode("textarea", {
                                    class: "form-control",
                                    id: "exampleFormControlTextarea1",
                                    rows: "3",
                                    placeholder: "Description",
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.description) = $event))
                                  }, null, 512), [
                                    [_vModelText, _ctx.formData.description]
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                  _cache[16] || (_cache[16] = _createElementVNode("label", {
                                    class: "col-sm-12 col-form-label",
                                    for: "basic-icon-default-store"
                                  }, "Buy Price ", -1)),
                                  _createElementVNode("div", _hoisted_21, [
                                    _withDirectives(_createElementVNode("input", {
                                      type: "text",
                                      class: "form-control",
                                      id: "basic-icon-default-fullname",
                                      placeholder: "Buy Price",
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.buy_price) = $event)),
                                      required: ""
                                    }, null, 512), [
                                      [_vModelText, _ctx.formData.buy_price]
                                    ])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_22, [
                                  _cache[17] || (_cache[17] = _createElementVNode("label", {
                                    class: "col-sm-12 col-form-label",
                                    for: "basic-icon-default-store"
                                  }, "Wholesale Price ", -1)),
                                  _createElementVNode("div", _hoisted_23, [
                                    _withDirectives(_createElementVNode("input", {
                                      type: "text",
                                      class: "form-control",
                                      id: "basic-icon-default-fullname",
                                      placeholder: "Wholesale Price",
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.wholesale_price) = $event)),
                                      required: ""
                                    }, null, 512), [
                                      [_vModelText, _ctx.formData.wholesale_price]
                                    ])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_24, [
                                  _cache[18] || (_cache[18] = _createElementVNode("label", {
                                    class: "col-sm-12 col-form-label",
                                    for: "basic-icon-default-store"
                                  }, "Sale Price ", -1)),
                                  _createElementVNode("div", _hoisted_25, [
                                    _withDirectives(_createElementVNode("input", {
                                      type: "text",
                                      class: "form-control",
                                      id: "basic-icon-default-fullname",
                                      placeholder: "Sale Price",
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.sell_price) = $event)),
                                      required: ""
                                    }, null, 512), [
                                      [_vModelText, _ctx.formData.sell_price]
                                    ])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_26, [
                                  _cache[19] || (_cache[19] = _createElementVNode("label", {
                                    class: "col-sm-12 col-form-label",
                                    for: "basic-icon-default-store"
                                  }, "Alert Quantity ", -1)),
                                  _createElementVNode("div", _hoisted_27, [
                                    _withDirectives(_createElementVNode("input", {
                                      type: "text",
                                      class: "form-control",
                                      id: "basic-icon-default-fullname",
                                      placeholder: "Sale Price",
                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.alert_quantity) = $event)),
                                      required: ""
                                    }, null, 512), [
                                      [_vModelText, _ctx.formData.alert_quantity]
                                    ])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_28, [
                                  _cache[21] || (_cache[21] = _createElementVNode("label", {
                                    class: "col-sm-12 col-form-label",
                                    for: "basic-icon-default-store"
                                  }, "Unit ", -1)),
                                  _createElementVNode("div", _hoisted_29, [
                                    _withDirectives(_createElementVNode("select", {
                                      class: "form-select",
                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.unit) = $event))
                                    }, _cache[20] || (_cache[20] = [
                                      _createStaticVNode("<option value=\"\">-- Select Unit--</option><option value=\"PCS\">Pcs</option><option value=\"KG\">Kg</option><option value=\"ML\">Ml</option><option value=\"GM\">Gm</option><option value=\"POUND\">Pound </option><option value=\"PACKET\">Packet </option><option value=\"MILIMETER\">Mm</option><option value=\"PAIR\">Pair</option>", 9)
                                    ]), 512), [
                                      [_vModelSelect, _ctx.formData.unit]
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "",
                            key: index
                          }, [
                            _createElementVNode("div", _hoisted_31, [
                              _createElementVNode("div", _hoisted_32, [
                                (_ctx.loading)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, _cache[22] || (_cache[22] = [
                                      _createElementVNode("div", {
                                        class: "spinner-border text-primary",
                                        role: "status"
                                      }, [
                                        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                                      ], -1)
                                    ])))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                      _createElementVNode("label", _hoisted_35, _toDisplayString(dataItem.title), 1),
                                      _withDirectives((_openBlock(), _createElementBlock("select", {
                                        class: "form-select",
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.selectedValues[index]) = $event),
                                        key: dataItem.id,
                                        onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.updateSelectedChildId && _ctx.updateSelectedChildId(...args)))
                                      }, [
                                        _cache[23] || (_cache[23] = _createElementVNode("option", { value: undefined }, " -- Select --", -1)),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataItem.children, (dataItemchildren) => {
                                          return (_openBlock(), _createElementBlock("option", {
                                            key: dataItemchildren.id,
                                            value: dataItemchildren.id
                                          }, _toDisplayString(dataItemchildren.title), 9, _hoisted_37))
                                        }), 128))
                                      ], 40, _hoisted_36)), [
                                        [_vModelSelect, _ctx.selectedValues[index]]
                                      ])
                                    ]))
                              ])
                            ])
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_38, [
                        _createElementVNode("div", _hoisted_39, [
                          _createElementVNode("button", {
                            type: "submit",
                            class: "btn btn-primary mt-3",
                            disabled: _ctx.submitBtn
                          }, [
                            (!_ctx.submitBtn)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_41, "Save"))
                              : _createCommentVNode("", true),
                            (_ctx.submitBtn)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_42))
                              : _createCommentVNode("", true),
                            (_ctx.submitBtn)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_43, " Saving ...."))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_40)
                        ])
                      ])
                    ])
                  ], 32))
            ])
          ])
        ])
      ]),
      _createVNode(_component_Footer),
      _cache[24] || (_cache[24] = _createElementVNode("div", { class: "content-backdrop fade" }, null, -1))
    ])
  ]))
}