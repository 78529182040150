import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_2 = {
  key: 1,
  class: "row"
}
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "card-title mb-2 alert-primary p-2 d-flex justify-content-between rounded" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  class: "modal fade",
  id: "modalCenter",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_11 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_12 = { class: "modal-content" }
const _hoisted_13 = { class: "modal-footer text-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
          _createElementVNode("div", {
            class: "spinner-border text-primary",
            role: "status"
          }, [
            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
          ], -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-lg-3 col-md-6 col-sm-12 mb-4",
              key: dataItem.id
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [
                        _createElementVNode("div", {
                          class: "spinner-border text-primary",
                          role: "status"
                        }, [
                          _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                        ], -1)
                      ])))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("h4", _hoisted_7, [
                          _createTextVNode(_toDisplayString(dataItem.title) + " ", 1),
                          _createElementVNode("span", null, [
                            _createVNode(_component_router_link, {
                              class: "me-0 btn btn-sm mb-0 p-1",
                              to: { name: 'features-form', params: { id: dataItem.id.toString() } }
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'pen-to-square'] })
                              ]),
                              _: 2
                            }, 1032, ["to"]),
                            _createElementVNode("button", {
                              type: "button",
                              class: "me-0 btn btn-sm p-1",
                              "data-bs-toggle": "modal",
                              "data-bs-target": "#modalCenter",
                              onClick: ($event: any) => (_ctx.fetchItem(dataItem))
                            }, [
                              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'trash-can'] })
                            ], 8, _hoisted_8)
                          ])
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataItem.children, (dataItemchildren) => {
                          return (_openBlock(), _createElementBlock("p", {
                            class: "card-text mb-0",
                            key: dataItemchildren.id
                          }, [
                            _createTextVNode(_toDisplayString(dataItemchildren.title) + " ", 1),
                            _createElementVNode("span", null, [
                              _createVNode(_component_router_link, {
                                class: "me-0 btn btn-sm p-1",
                                to: { name: 'features-form', params: { id: dataItemchildren.id.toString() } }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'pen-to-square'] })
                                ]),
                                _: 2
                              }, 1032, ["to"]),
                              _createElementVNode("button", {
                                type: "button",
                                class: "me-0 btn btn-sm p-1",
                                "data-bs-toggle": "modal",
                                "data-bs-target": "#modalCenter",
                                onClick: ($event: any) => (_ctx.fetchItem(dataItem))
                              }, [
                                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'trash-can'] })
                              ], 8, _hoisted_9)
                            ])
                          ]))
                        }), 128))
                      ]))
                ])
              ])
            ]))
          }), 128))
        ])),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"modal-header\"><h5 class=\"modal-title text-center\" id=\"modalCenterTitle\">Delete Confirmation !</h5><button type=\"button\" class=\"btn-close\" data-bs-dismiss=\"modal\" aria-label=\"Close\"></button></div><div class=\"modal-body text-center\"><i class=\"bx bx-bell bx-lg text-danger mb-3 d-inline-block\"></i><p>Are You Sure to Delete ?</p></div>", 2)),
          _createElementVNode("div", _hoisted_13, [
            _cache[3] || (_cache[3] = _createElementVNode("button", {
              type: "button",
              class: "btn btn-outline-secondary",
              "data-bs-dismiss": "modal"
            }, " Close ", -1)),
            _createElementVNode("button", {
              "data-bs-dismiss": "modal",
              type: "button",
              class: "btn btn-danger",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteItem(_ctx.deletevalue.id)))
            }, "Confirm")
          ])
        ])
      ])
    ])
  ]))
}