import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "layout-page" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "px-4 flex-grow-1 container-p-y" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-7" }
const _hoisted_6 = { class: "card mb-4" }
const _hoisted_7 = { class: "d-flex align-items-end row" }
const _hoisted_8 = { class: "col-sm-7" }
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = { class: "card-title mb-2" }
const _hoisted_11 = { class: "text-primary" }
const _hoisted_12 = { class: "mb-0" }
const _hoisted_13 = { class: "mb-0" }
const _hoisted_14 = { class: "mb-0" }
const _hoisted_15 = { class: "col-lg-2 col-md-2 col-6 mb-4" }
const _hoisted_16 = { class: "card" }
const _hoisted_17 = { class: "card-body" }
const _hoisted_18 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "card-title text-nowrap mb-1" }
const _hoisted_21 = { class: "col-lg-2 col-md-2 col-6 mb-4" }
const _hoisted_22 = { class: "card" }
const _hoisted_23 = { class: "card-body" }
const _hoisted_24 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "card-title text-nowrap mb-1" }
const _hoisted_27 = { class: "col-lg-2 col-md-2 col-6 mb-4 d-none" }
const _hoisted_28 = { class: "card" }
const _hoisted_29 = { class: "card-body" }
const _hoisted_30 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "card-title text-nowrap mb-1" }
const _hoisted_33 = { class: "col-lg-2 col-md-2 col-6 mb-4 d-none" }
const _hoisted_34 = { class: "card" }
const _hoisted_35 = { class: "card-body" }
const _hoisted_36 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_37 = { key: 1 }
const _hoisted_38 = { class: "card-title text-nowrap mb-1" }
const _hoisted_39 = { class: "col-lg-2 col-md-2 col-6 mb-4" }
const _hoisted_40 = { class: "card" }
const _hoisted_41 = { class: "card-body" }
const _hoisted_42 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_43 = { key: 1 }
const _hoisted_44 = { class: "card-title text-nowrap mb-1" }
const _hoisted_45 = { class: "col-lg-2 col-md-2 col-6 mb-4" }
const _hoisted_46 = { class: "card" }
const _hoisted_47 = { class: "card-body" }
const _hoisted_48 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_49 = { key: 1 }
const _hoisted_50 = { class: "card-title text-nowrap mb-1" }
const _hoisted_51 = { class: "col-lg-2 col-md-2 col-6 mb-4" }
const _hoisted_52 = { class: "card" }
const _hoisted_53 = { class: "card-body" }
const _hoisted_54 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_55 = { key: 1 }
const _hoisted_56 = { class: "card-title text-nowrap mb-1" }
const _hoisted_57 = { class: "col-lg-2 col-md-2 col-6 mb-4" }
const _hoisted_58 = { class: "card" }
const _hoisted_59 = { class: "card-body" }
const _hoisted_60 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_61 = { key: 1 }
const _hoisted_62 = { class: "card-title text-nowrap mb-1" }
const _hoisted_63 = { class: "col-md-12" }
const _hoisted_64 = { class: "row" }
const _hoisted_65 = { class: "col-6 col-lg-4 col-md-4" }
const _hoisted_66 = { class: "card mb-4" }
const _hoisted_67 = { class: "card-body" }
const _hoisted_68 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_69 = { key: 1 }
const _hoisted_70 = { class: "card-title mb-2" }
const _hoisted_71 = { class: "col-6 col-lg-4 col-md-4" }
const _hoisted_72 = { class: "card mb-4" }
const _hoisted_73 = { class: "card-body" }
const _hoisted_74 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_75 = { key: 1 }
const _hoisted_76 = { class: "card-title mb-2" }
const _hoisted_77 = { class: "col-6 col-lg-4 col-md-4 mb-4" }
const _hoisted_78 = { class: "card" }
const _hoisted_79 = { class: "card-body" }
const _hoisted_80 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_81 = { key: 1 }
const _hoisted_82 = { class: "card-title mb-2" }
const _hoisted_83 = { class: "col-6 col-lg-3 col-md-3 mb-4" }
const _hoisted_84 = { class: "card" }
const _hoisted_85 = { class: "card-body" }
const _hoisted_86 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_87 = { key: 1 }
const _hoisted_88 = { class: "card-title mb-2" }
const _hoisted_89 = { class: "col-6 col-lg-3 col-md-3 mb-4" }
const _hoisted_90 = { class: "card" }
const _hoisted_91 = { class: "card-body" }
const _hoisted_92 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_93 = { key: 1 }
const _hoisted_94 = { class: "card-title mb-2" }
const _hoisted_95 = { class: "col-6 col-lg-3 col-md-3 mb-4" }
const _hoisted_96 = { class: "card" }
const _hoisted_97 = { class: "card-body" }
const _hoisted_98 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_99 = { key: 1 }
const _hoisted_100 = { class: "card-title mb-2" }
const _hoisted_101 = { class: "col-6 col-lg-3 col-md-3 mb-4" }
const _hoisted_102 = { class: "card" }
const _hoisted_103 = { class: "card-body" }
const _hoisted_104 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_105 = { key: 1 }
const _hoisted_106 = { class: "card-title text-nowrap mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Topbar = _resolveComponent("Topbar")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Topbar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("h3", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.userData.firstname) + " " + _toDisplayString(_ctx.userData.lastname), 1)
                    ]),
                    _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.userData.email), 1),
                    _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.userData.phone), 1),
                    _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.userData.address), 1)
                  ])
                ]),
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-sm-5 text-center text-sm-left" }, [
                  _createElementVNode("div", { class: "card-body pb-0 px-0 px-md-4" }, [
                    _createElementVNode("img", {
                      src: "/assets/img/illustrations/man-with-laptop-light.png",
                      height: "140",
                      alt: "View Badge User",
                      "data-app-dark-img": "illustrations/man-with-laptop-dark.png",
                      "data-app-light-img": "illustrations/man-with-laptop-light.png"
                    })
                  ])
                ], -1))
              ])
            ])
          ]),
          _cache[46] || (_cache[46] = _createStaticVNode("<div class=\"col-md-5\" data-v-318443b3><div class=\"card mb-4\" data-v-318443b3><div class=\"d-flex align-items-end row\" data-v-318443b3><div class=\"col-sm-8\" data-v-318443b3><div class=\"card-body\" data-v-318443b3><h3 class=\"card-title mb-2\" data-v-318443b3><span class=\"text-primary\" data-v-318443b3>Help &amp; Support</span></h3> <strong data-v-318443b3>Developed by —</strong><a href=\"http://phicsart.com\" target=\"_blank\" rel=\"noopener noreferrer\" data-v-318443b3> PhicsArt</a> <br data-v-318443b3><strong data-v-318443b3>Contact —</strong><a href=\"https://wa.me/+8801618180022\" target=\"_blank\" rel=\"noopener noreferrer\" data-v-318443b3>Whatsapp (+880 1618 180022)</a><br data-v-318443b3><a href=\"tel:+8801618180022\" target=\"_blank\" rel=\"noopener noreferrer\" data-v-318443b3>Call (+880 1618 180022)</a></div></div><div class=\"col-sm-4 text-center text-sm-left\" data-v-318443b3><div class=\"card-body pb-0 px-0 px-md-4\" data-v-318443b3><img src=\"https://phicsart.com/uploads/images/top-page/menu/logo.svg\" height=\"140\" alt=\"View Badge User\" data-app-dark-img=\"illustrations/man-with-laptop-dark.png\" data-app-light-img=\"illustrations/man-with-laptop-light.png\" class=\"img-fluid\" style=\"height:140px;\" data-v-318443b3></div></div></div></div></div>", 1)),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[1] || (_cache[1] = [
                      _createElementVNode("div", {
                        class: "spinner-border text-primary",
                        role: "status"
                      }, [
                        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                      ], -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                        _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                          _createElementVNode("img", {
                            src: "/assets/img/icons/unicons/wallet-info.png",
                            alt: "Credit Card",
                            class: "rounded"
                          })
                        ])
                      ], -1)),
                      _cache[3] || (_cache[3] = _createElementVNode("span", null, "Total Card", -1)),
                      _createElementVNode("h3", _hoisted_20, _toDisplayString(_ctx.fettchDataArray.no_of_card), 1)
                    ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, _cache[4] || (_cache[4] = [
                      _createElementVNode("div", {
                        class: "spinner-border text-primary",
                        role: "status"
                      }, [
                        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                      ], -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                        _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                          _createElementVNode("img", {
                            src: "/assets/img/icons/unicons/wallet-info.png",
                            alt: "Credit Card",
                            class: "rounded"
                          })
                        ])
                      ], -1)),
                      _cache[6] || (_cache[6] = _createElementVNode("span", null, "Initial Card", -1)),
                      _createElementVNode("h3", _hoisted_26, _toDisplayString(_ctx.fettchDataArray.no_of_card_0), 1)
                    ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, _cache[7] || (_cache[7] = [
                      _createElementVNode("div", {
                        class: "spinner-border text-primary",
                        role: "status"
                      }, [
                        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                      ], -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_31, [
                      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                        _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                          _createElementVNode("img", {
                            src: "/assets/img/icons/unicons/wallet-info.png",
                            alt: "Credit Card",
                            class: "rounded"
                          })
                        ])
                      ], -1)),
                      _cache[9] || (_cache[9] = _createElementVNode("span", null, "Warehouse", -1)),
                      _createElementVNode("h3", _hoisted_32, _toDisplayString(_ctx.fettchDataArray.no_of_card_1), 1)
                    ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_33, [
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_36, _cache[10] || (_cache[10] = [
                      _createElementVNode("div", {
                        class: "spinner-border text-primary",
                        role: "status"
                      }, [
                        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                      ], -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_37, [
                      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                        _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                          _createElementVNode("img", {
                            src: "/assets/img/icons/unicons/wallet-info.png",
                            alt: "Credit Card",
                            class: "rounded"
                          })
                        ])
                      ], -1)),
                      _cache[12] || (_cache[12] = _createElementVNode("span", null, "Dispatch", -1)),
                      _createElementVNode("h3", _hoisted_38, _toDisplayString(_ctx.fettchDataArray.no_of_card_2), 1)
                    ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("div", _hoisted_41, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_42, _cache[13] || (_cache[13] = [
                      _createElementVNode("div", {
                        class: "spinner-border text-primary",
                        role: "status"
                      }, [
                        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                      ], -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_43, [
                      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                        _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                          _createElementVNode("img", {
                            src: "/assets/img/icons/unicons/wallet-info.png",
                            alt: "Credit Card",
                            class: "rounded"
                          })
                        ])
                      ], -1)),
                      _cache[15] || (_cache[15] = _createElementVNode("span", null, "In Store", -1)),
                      _createElementVNode("h3", _hoisted_44, _toDisplayString(_ctx.fettchDataArray.no_of_card_3), 1)
                    ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_45, [
            _createElementVNode("div", _hoisted_46, [
              _createElementVNode("div", _hoisted_47, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_48, _cache[16] || (_cache[16] = [
                      _createElementVNode("div", {
                        class: "spinner-border text-primary",
                        role: "status"
                      }, [
                        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                      ], -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_49, [
                      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                        _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                          _createElementVNode("img", {
                            src: "/assets/img/icons/unicons/wallet-info.png",
                            alt: "Credit Card",
                            class: "rounded"
                          })
                        ])
                      ], -1)),
                      _cache[18] || (_cache[18] = _createElementVNode("span", null, "Activated", -1)),
                      _createElementVNode("h3", _hoisted_50, _toDisplayString(_ctx.fettchDataArray.no_of_card_4), 1)
                    ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_51, [
            _createElementVNode("div", _hoisted_52, [
              _createElementVNode("div", _hoisted_53, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_54, _cache[19] || (_cache[19] = [
                      _createElementVNode("div", {
                        class: "spinner-border text-primary",
                        role: "status"
                      }, [
                        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                      ], -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_55, [
                      _cache[20] || (_cache[20] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                        _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                          _createElementVNode("img", {
                            src: "/assets/img/icons/unicons/wallet-info.png",
                            alt: "Credit Card",
                            class: "rounded"
                          })
                        ])
                      ], -1)),
                      _cache[21] || (_cache[21] = _createElementVNode("span", null, "Claim", -1)),
                      _createElementVNode("h3", _hoisted_56, _toDisplayString(_ctx.fettchDataArray.no_of_card_5), 1)
                    ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_57, [
            _createElementVNode("div", _hoisted_58, [
              _createElementVNode("div", _hoisted_59, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_60, _cache[22] || (_cache[22] = [
                      _createElementVNode("div", {
                        class: "spinner-border text-primary",
                        role: "status"
                      }, [
                        _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                      ], -1)
                    ])))
                  : (_openBlock(), _createElementBlock("div", _hoisted_61, [
                      _cache[23] || (_cache[23] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                        _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                          _createElementVNode("img", {
                            src: "/assets/img/icons/unicons/wallet-info.png",
                            alt: "Credit Card",
                            class: "rounded"
                          })
                        ])
                      ], -1)),
                      _cache[24] || (_cache[24] = _createElementVNode("span", null, "Claim Confirm", -1)),
                      _createElementVNode("h3", _hoisted_62, _toDisplayString(_ctx.fettchDataArray.no_of_card_6), 1)
                    ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_63, [
            _createElementVNode("div", _hoisted_64, [
              _createElementVNode("div", _hoisted_65, [
                _createElementVNode("div", _hoisted_66, [
                  _createElementVNode("div", _hoisted_67, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_68, _cache[25] || (_cache[25] = [
                          _createElementVNode("div", {
                            class: "spinner-border text-primary",
                            role: "status"
                          }, [
                            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                          ], -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_69, [
                          _cache[26] || (_cache[26] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                            _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                              _createElementVNode("img", {
                                src: "/assets/img/icons/unicons/chart-success.png",
                                alt: "chart success",
                                class: "rounded"
                              })
                            ])
                          ], -1)),
                          _cache[27] || (_cache[27] = _createElementVNode("span", { class: "fw-semibold d-block mb-1" }, "Number of Store", -1)),
                          _createElementVNode("h3", _hoisted_70, _toDisplayString(_ctx.fettchDataArray.no_of_store), 1)
                        ]))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_71, [
                _createElementVNode("div", _hoisted_72, [
                  _createElementVNode("div", _hoisted_73, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_74, _cache[28] || (_cache[28] = [
                          _createElementVNode("div", {
                            class: "spinner-border text-primary",
                            role: "status"
                          }, [
                            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                          ], -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_75, [
                          _cache[29] || (_cache[29] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                            _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                              _createElementVNode("img", {
                                src: "/assets/img/icons/unicons/chart-success.png",
                                alt: "chart success",
                                class: "rounded"
                              })
                            ])
                          ], -1)),
                          _cache[30] || (_cache[30] = _createElementVNode("span", { class: "fw-semibold d-block mb-1" }, "Number of Product", -1)),
                          _createElementVNode("h3", _hoisted_76, _toDisplayString(_ctx.fettchDataArray.no_of_product), 1)
                        ]))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_77, [
                _createElementVNode("div", _hoisted_78, [
                  _createElementVNode("div", _hoisted_79, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_80, _cache[31] || (_cache[31] = [
                          _createElementVNode("div", {
                            class: "spinner-border text-primary",
                            role: "status"
                          }, [
                            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                          ], -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_81, [
                          _cache[32] || (_cache[32] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                            _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                              _createElementVNode("img", {
                                src: "/assets/img/icons/unicons/cc-warning.png",
                                alt: "Credit Card",
                                class: "rounded"
                              })
                            ])
                          ], -1)),
                          _cache[33] || (_cache[33] = _createElementVNode("span", { class: "fw-semibold d-block mb-1" }, "Alert Product", -1)),
                          _createElementVNode("h3", _hoisted_82, _toDisplayString(_ctx.fettchDataArray.no_of_alert_product), 1)
                        ]))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_83, [
                _createElementVNode("div", _hoisted_84, [
                  _createElementVNode("div", _hoisted_85, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_86, _cache[34] || (_cache[34] = [
                          _createElementVNode("div", {
                            class: "spinner-border text-primary",
                            role: "status"
                          }, [
                            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                          ], -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_87, [
                          _cache[35] || (_cache[35] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                            _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                              _createElementVNode("img", {
                                src: "/assets/img/icons/unicons/cube-secondary.png",
                                alt: "cube",
                                class: "rounded"
                              })
                            ])
                          ], -1)),
                          _cache[36] || (_cache[36] = _createElementVNode("span", { class: "fw-medium d-block mb-1" }, "Sales", -1)),
                          _createElementVNode("h3", _hoisted_88, _toDisplayString(_ctx.fettchDataArray.no_of_sales) + " (" + _toDisplayString(_ctx.fettchDataArray.amount_of_sales) + ") ", 1)
                        ]))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_89, [
                _createElementVNode("div", _hoisted_90, [
                  _createElementVNode("div", _hoisted_91, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_92, _cache[37] || (_cache[37] = [
                          _createElementVNode("div", {
                            class: "spinner-border text-primary",
                            role: "status"
                          }, [
                            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                          ], -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_93, [
                          _cache[38] || (_cache[38] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                            _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                              _createElementVNode("img", {
                                src: "/assets/img/icons/unicons/cc-primary.png",
                                alt: "Credit Card",
                                class: "rounded"
                              })
                            ])
                          ], -1)),
                          _cache[39] || (_cache[39] = _createElementVNode("span", { class: "fw-semibold d-block mb-1" }, "Wholesale", -1)),
                          _createElementVNode("h3", _hoisted_94, _toDisplayString(_ctx.fettchDataArray.no_of_sales_by_wholesale) + " (" + _toDisplayString(_ctx.fettchDataArray.amount_of_sales_by_wholesale) + ")", 1)
                        ]))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_95, [
                _createElementVNode("div", _hoisted_96, [
                  _createElementVNode("div", _hoisted_97, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_98, _cache[40] || (_cache[40] = [
                          _createElementVNode("div", {
                            class: "spinner-border text-primary",
                            role: "status"
                          }, [
                            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                          ], -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_99, [
                          _cache[41] || (_cache[41] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                            _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                              _createElementVNode("img", {
                                src: "/assets/img/icons/unicons/cc-primary.png",
                                alt: "Credit Card",
                                class: "rounded"
                              })
                            ])
                          ], -1)),
                          _cache[42] || (_cache[42] = _createElementVNode("span", { class: "fw-semibold d-block mb-1" }, "Regular", -1)),
                          _createElementVNode("h3", _hoisted_100, _toDisplayString(_ctx.fettchDataArray.no_of_sales_by_regular) + " (" + _toDisplayString(_ctx.fettchDataArray.amount_of_sales_by_regular) + ")", 1)
                        ]))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_101, [
                _createElementVNode("div", _hoisted_102, [
                  _createElementVNode("div", _hoisted_103, [
                    (_ctx.loading)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_104, _cache[43] || (_cache[43] = [
                          _createElementVNode("div", {
                            class: "spinner-border text-primary",
                            role: "status"
                          }, [
                            _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                          ], -1)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_105, [
                          _cache[44] || (_cache[44] = _createElementVNode("div", { class: "card-title d-flex align-items-start justify-content-between" }, [
                            _createElementVNode("div", { class: "avatar flex-shrink-0" }, [
                              _createElementVNode("img", {
                                src: "/assets/img/icons/unicons/paypal.png",
                                alt: "Credit Card",
                                class: "rounded"
                              })
                            ])
                          ], -1)),
                          _cache[45] || (_cache[45] = _createElementVNode("span", { class: "d-block mb-1" }, "Total Due", -1)),
                          _createElementVNode("h3", _hoisted_106, _toDisplayString(_ctx.fettchDataArray.no_of_due) + " (" + _toDisplayString(_ctx.fettchDataArray.amount_of_due) + ")", 1)
                        ]))
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _createVNode(_component_Footer)
    ])
  ]))
}