import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-success alert-dismissible",
  role: "alert"
}
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  key: 0,
  class: "alert alert-success alert-dismissible",
  role: "alert"
}
const _hoisted_6 = {
  key: 1,
  class: "alert alert-warning alert-dismissible",
  role: "alert"
}
const _hoisted_7 = { class: "mb-3 form-password-toggle" }
const _hoisted_8 = { class: "input-group input-group-merge" }
const _hoisted_9 = ["type"]
const _hoisted_10 = { class: "mb-3 form-password-toggle" }
const _hoisted_11 = { class: "input-group input-group-merge" }
const _hoisted_12 = ["type"]
const _hoisted_13 = { class: "mb-3 form-password-toggle" }
const _hoisted_14 = { class: "input-group input-group-merge" }
const _hoisted_15 = ["type"]
const _hoisted_16 = { class: "mb-3 mt-3" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = {
  key: 1,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_20 = {
  key: 2,
  class: "visually-show"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.successReport)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[7] || (_cache[7] = [
          _createTextVNode(" Successfully "),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "aria-label": "Close"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      id: "formAuthentication",
      class: "mb-3",
      onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.setPassword && _ctx.setPassword(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.actionReport)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[8] || (_cache[8] = [
                _createTextVNode(" Password Changed Sccessfully ! "),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn-close",
                  "data-bs-dismiss": "alert",
                  "aria-label": "Close"
                }, null, -1)
              ])))
            : _createCommentVNode("", true),
          (_ctx.badactionReport)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[9] || (_cache[9] = [
                _createTextVNode(" Old Password Not Match ! "),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn-close",
                  "data-bs-dismiss": "alert",
                  "aria-label": "Close"
                }, null, -1)
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _cache[10] || (_cache[10] = _createElementVNode("label", {
              for: "email",
              class: "form-label"
            }, "Enter Your Old Password", -1)),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                type: _ctx.showPassword.old ? 'text' : 'password',
                class: "form-control",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.oldPasswordForm.password) = $event)),
                placeholder: "Enter Old password",
                "aria-describedby": "password"
              }, null, 8, _hoisted_9), [
                [_vModelDynamic, _ctx.oldPasswordForm.password]
              ]),
              _createElementVNode("span", {
                class: "input-group-text cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.togglePassword('old')))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(_ctx.showPassword.old ? 'bx bx-show' : 'bx bx-hide')
                }, null, 2)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[11] || (_cache[11] = _createElementVNode("label", {
              for: "email",
              class: "form-label"
            }, "New Password", -1)),
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                type: _ctx.showPassword.new ? 'text' : 'password',
                class: "form-control",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.setPasswordForm.new_password) = $event)),
                placeholder: "Enter New password",
                "aria-describedby": "password"
              }, null, 8, _hoisted_12), [
                [_vModelDynamic, _ctx.setPasswordForm.new_password]
              ]),
              _createElementVNode("span", {
                class: "input-group-text cursor-pointer",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.togglePassword('new')))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(_ctx.showPassword.new ? 'bx bx-show' : 'bx bx-hide')
                }, null, 2)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[12] || (_cache[12] = _createElementVNode("label", {
              for: "email",
              class: "form-label"
            }, "Confirm Password", -1)),
            _createElementVNode("div", _hoisted_14, [
              _withDirectives(_createElementVNode("input", {
                type: _ctx.showPassword.confirm ? 'text' : 'password',
                id: "password",
                class: "form-control",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.setPasswordForm.confirm_password) = $event)),
                placeholder: "Confirm New password"
              }, null, 8, _hoisted_15), [
                [_vModelDynamic, _ctx.setPasswordForm.confirm_password]
              ]),
              _createElementVNode("span", {
                class: "input-group-text cursor-pointer",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.togglePassword('confirm')))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(_ctx.showPassword.confirm ? 'bx bx-show' : 'bx bx-hide')
                }, null, 2)
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("button", {
          type: "submit",
          class: "btn btn-primary justify-content-center",
          disabled: _ctx.submitBtn
        }, [
          (!_ctx.submitBtn)
            ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Change Password"))
            : _createCommentVNode("", true),
          (_ctx.submitBtn)
            ? (_openBlock(), _createElementBlock("span", _hoisted_19))
            : _createCommentVNode("", true),
          (_ctx.submitBtn)
            ? (_openBlock(), _createElementBlock("span", _hoisted_20, " Changing...."))
            : _createCommentVNode("", true)
        ], 8, _hoisted_17)
      ])
    ], 32)
  ]))
}