import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  class: "content-wrapper d-flex align-items-center justify-content-center",
  style: {"min-height":"100vh","width":"100%"}
}
const _hoisted_3 = { class: "w-100" }
const _hoisted_4 = { class: "flex-grow-1 container-p-y px-4" }
const _hoisted_5 = { class: "col-md-9 col-sm-12 mx-auto" }
const _hoisted_6 = { class: "row h-100" }
const _hoisted_7 = { class: "col-md-6 mx-auto" }
const _hoisted_8 = { class: "h-100" }
const _hoisted_9 = { class: "d-flex align-items-center justify-content-center h-100" }
const _hoisted_10 = { class: "w-100" }
const _hoisted_11 = { class: "authentication-wrapper authentication-basic container-p-y" }
const _hoisted_12 = { class: "authentication-inner w-100" }
const _hoisted_13 = { class: "card" }
const _hoisted_14 = { class: "card-body" }
const _hoisted_15 = { class: "mb-3" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", _hoisted_14, [
                                _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"app-brand justify-content-center\"><a href=\"/\" class=\"app-brand-link gap-2\"><span class=\"app-brand-text demo text-body fw-bold text-uppercase mb-3\"><img src=\"https://pos-file.phicsart.com/profile_user.png\" alt=\"n/a\" class=\"img-fluid\" style=\"width:80px;height:80px;\"></span></a></div><h4 class=\"mb-2 text-center mb-5\">Raida Trade International 👋</h4>", 2)),
                                _createElementVNode("div", _hoisted_15, [
                                  _createElementVNode("div", _hoisted_16, [
                                    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"col-12\"><div class=\"text-center\"><div class=\"row\"><i class=\"fa fa-check\" aria-hidden=\"true\" style=\"font-size:100px;color:green;\"></i></div></div><h3 class=\"text-center mt-2\">Varified !</h3></div>", 1)),
                                    _createElementVNode("div", _hoisted_17, [
                                      _createElementVNode("button", {
                                        type: "submit",
                                        class: "btn btn-primary w-100 my-3 btn-lg justify-content-center",
                                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.activationHandle && _ctx.activationHandle(...args)))
                                      }, " Activate Now ")
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "content-backdrop fade" }, null, -1))
    ])
  ]))
}