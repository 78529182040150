import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-4" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "input-group" }
const _hoisted_5 = { class: "card mb-4 d-none d-md-block" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_8 = {
  key: 1,
  class: "table-responsive"
}
const _hoisted_9 = {
  key: 0,
  class: "table card-table text-center"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "table-border-bottom-0" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "mobile-view d-block d-md-none" }
const _hoisted_17 = {
  key: 0,
  class: "card"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "card-body" }
const _hoisted_20 = { class: "mb-0 text-wrap" }
const _hoisted_21 = { class: "mb-0 text-wrap" }
const _hoisted_22 = { class: "mb-0 text-wrap" }
const _hoisted_23 = { class: "mb-0 text-wrap" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { key: 2 }
const _hoisted_27 = {
  key: 0,
  class: ""
}
const _hoisted_28 = { class: "d-flex justify-content-between flex-wrap" }
const _hoisted_29 = { "aria-label": "Page navigation" }
const _hoisted_30 = { class: "pagination flex-wrap" }
const _hoisted_31 = ["onClick", "innerHTML"]
const _hoisted_32 = { class: "row" }
const _hoisted_33 = { class: "col-12" }
const _hoisted_34 = { class: "input-group" }
const _hoisted_35 = { class: "mt-3" }
const _hoisted_36 = { class: "p-3 bg-light" }
const _hoisted_37 = {
  class: "modal fade",
  id: "modalCenter",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_38 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_39 = { class: "modal-content" }
const _hoisted_40 = { class: "modal-body text-center" }
const _hoisted_41 = { class: "modal-footer text-center justify-content-center" }
const _hoisted_42 = {
  class: "modal fade",
  id: "viewModal",
  "data-bs-backdrop": "static",
  "data-bs-keyboard": "false",
  tabindex: "-1",
  "aria-labelledby": "staticBackdropLabel",
  "aria-hidden": "true"
}
const _hoisted_43 = { class: "modal-dialog modal-dialog-scrollable" }
const _hoisted_44 = { class: "modal-content" }
const _hoisted_45 = { class: "modal-body" }
const _hoisted_46 = { class: "text-start" }
const _hoisted_47 = {
  class: "table",
  style: {"border-color":"transparent"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control w-25",
              placeholder: "Enter Search Key !",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchKey) = $event))
            }, null, 512), [
              [_vModelText, _ctx.searchKey]
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary me-1 ms-1",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.searchData && _ctx.searchData(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'magnifying-glass'] })
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-default border",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchKey = '', _ctx.fetchAllData()))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'broom'] })
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[6] || (_cache[6] = [
              _createElementVNode("div", {
                class: "spinner-border text-primary",
                role: "status"
              }, [
                _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
              ], -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_ctx.fettchDataArray.length > 0)
                ? (_openBlock(), _createElementBlock("table", _hoisted_9, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _cache[7] || (_cache[7] = _createElementVNode("th", null, "Id", -1)),
                        _cache[8] || (_cache[8] = _createElementVNode("th", null, "Name", -1)),
                        _cache[9] || (_cache[9] = _createElementVNode("th", null, "Buy Price", -1)),
                        _cache[10] || (_cache[10] = _createElementVNode("th", null, "Wholesale Price", -1)),
                        _cache[11] || (_cache[11] = _createElementVNode("th", null, "Sell Price", -1)),
                        _cache[12] || (_cache[12] = _createElementVNode("th", null, "Warning Quantity", -1)),
                        (_ctx.userData?.type === 'SUPERADMIN')
                          ? (_openBlock(), _createElementBlock("th", _hoisted_10, "Action"))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("tbody", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem, index) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: dataItem.id
                        }, [
                          _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                          _createElementVNode("td", null, _toDisplayString(dataItem.name), 1),
                          _createElementVNode("td", null, _toDisplayString(dataItem.buy_price), 1),
                          _createElementVNode("td", null, _toDisplayString(dataItem.sell_price), 1),
                          _createElementVNode("td", null, _toDisplayString(dataItem.sell_price), 1),
                          _createElementVNode("td", null, _toDisplayString(dataItem.alert_quantity), 1),
                          (_ctx.userData?.type == 'SUPERADMIN')
                            ? (_openBlock(), _createElementBlock("td", _hoisted_12, [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "me-2 btn btn-sm btn-primary",
                                  onClick: ($event: any) => (_ctx.viewItem(dataItem)),
                                  "data-bs-toggle": "modal",
                                  "data-bs-target": "#viewModal"
                                }, [
                                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'eye'] })
                                ], 8, _hoisted_13),
                                _createVNode(_component_router_link, {
                                  class: "me-2 btn btn-sm btn-warning",
                                  to: { name: 'product-form', params: { id: dataItem.id.toString() } }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_font_awesome_icon, { icon: ['fas', 'pen-to-square'] })
                                  ]),
                                  _: 2
                                }, 1032, ["to"]),
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "me-2 btn btn-sm btn-danger",
                                  "data-bs-toggle": "modal",
                                  "data-bs-target": "#modalCenter",
                                  onClick: ($event: any) => (_ctx.fetchItem(dataItem))
                                }, [
                                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'trash-can'] })
                                ], 8, _hoisted_14)
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[13] || (_cache[13] = [
                    _createElementVNode("h5", { class: "text-center mb-0" }, "Please Add Some Product", -1)
                  ])))
            ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[14] || (_cache[14] = [
            _createElementVNode("div", { class: "card-body" }, [
              _createElementVNode("div", { class: "d-flex justify-content-center align-items-center" }, [
                _createElementVNode("div", {
                  class: "spinner-border text-primary",
                  role: "status"
                }, [
                  _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                ])
              ])
            ], -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.fettchDataArray.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "card mb-4",
                key: dataItem.id
              }, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("p", _hoisted_20, "Name: " + _toDisplayString(dataItem.name), 1),
                  _createElementVNode("p", _hoisted_21, "Description: " + _toDisplayString(dataItem.description ?? 'N/A'), 1),
                  _createElementVNode("p", _hoisted_22, "Buy Price: " + _toDisplayString(dataItem.buy_price), 1),
                  _createElementVNode("p", _hoisted_23, "Sell Price: " + _toDisplayString(dataItem.sell_price), 1),
                  _createElementVNode("p", null, "Alert Quantity: " + _toDisplayString(dataItem.alert_quantity), 1),
                  _createElementVNode("button", {
                    type: "button",
                    class: "me-2 btn btn-sm btn-warning",
                    onClick: ($event: any) => (_ctx.editItem(dataItem.id.toString()))
                  }, [
                    _createVNode(_component_font_awesome_icon, { icon: ['fas', 'pen-to-square'] })
                  ], 8, _hoisted_24),
                  _createElementVNode("button", {
                    type: "button",
                    class: "me-2 btn btn-sm btn-danger",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#modalCenter",
                    onClick: ($event: any) => (_ctx.fetchItem(dataItem))
                  }, [
                    _createVNode(_component_font_awesome_icon, { icon: ['fas', 'trash-can'] })
                  ], 8, _hoisted_25)
                ])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_26, _cache[15] || (_cache[15] = [
            _createElementVNode("div", { class: "card" }, [
              _createElementVNode("div", { class: "card-body" }, [
                _createElementVNode("h5", { class: "text-center mb-0" }, "Please Add Some Product")
              ])
            ], -1)
          ])))
    ]),
    (_ctx.fettchDataArray.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("nav", _hoisted_29, [
              _createElementVNode("ul", _hoisted_30, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.links, (page, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    class: _normalizeClass(["page-item myPagination m-1", { active: page.active }])
                  }, [
                    _createElementVNode("button", {
                      class: "page-link",
                      onClick: ($event: any) => (_ctx.goToPage(Number(page.label))),
                      innerHTML: page.label
                    }, null, 8, _hoisted_31)
                  ], 2))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _cache[17] || (_cache[17] = _createElementVNode("label", {
                    class: "input-group-text",
                    for: "inputGroupSelect01"
                  }, "Size", -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select",
                    id: "inputGroupSelect01",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageSize) = $event)),
                    onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.fetchData && _ctx.fetchData(...args)))
                  }, _cache[16] || (_cache[16] = [
                    _createStaticVNode("<option value=\"10\">10</option><option value=\"20\">20</option><option value=\"30\">30</option><option value=\"40\">40</option><option value=\"50\">50</option><option value=\"100\">100</option>", 6)
                  ]), 544), [
                    [_vModelSelect, _ctx.pageSize]
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("span", _hoisted_36, " Showing: " + _toDisplayString(_ctx.paginationData.from) + " - " + _toDisplayString(_ctx.paginationData.to) + " of " + _toDisplayString(_ctx.paginationData.total), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_37, [
      _createElementVNode("div", _hoisted_38, [
        _createElementVNode("div", _hoisted_39, [
          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("h5", {
              class: "modal-title text-center",
              id: "modalCenterTitle"
            }, "Delete Confirmation !"),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_40, [
            _cache[19] || (_cache[19] = _createElementVNode("i", { class: "bx bx-bell bx-lg text-danger mb-3 d-inline-block" }, null, -1)),
            _cache[20] || (_cache[20] = _createElementVNode("p", null, "Are You Sure to Delete ?", -1)),
            _createElementVNode("p", null, [
              _createElementVNode("b", null, _toDisplayString(_ctx.deletevalue.name), 1),
              _cache[18] || (_cache[18] = _createTextVNode(" Page !"))
            ])
          ]),
          _createElementVNode("div", _hoisted_41, [
            _cache[21] || (_cache[21] = _createElementVNode("button", {
              type: "button",
              class: "btn btn-outline-secondary",
              "data-bs-dismiss": "modal"
            }, " Close ", -1)),
            _createElementVNode("button", {
              "data-bs-dismiss": "modal",
              type: "button",
              class: "btn btn-danger",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deleteItem(_ctx.deletevalue.id)))
            }, "Confirm")
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_42, [
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("div", _hoisted_44, [
          _cache[39] || (_cache[39] = _createElementVNode("div", { class: "modal-header alert alert-primary" }, [
            _createElementVNode("h5", {
              class: "modal-title mb-3",
              id: "staticBackdropLabel",
              style: {"color":"#696cff"}
            }, "Product Details"),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_45, [
            _createElementVNode("div", _hoisted_46, [
              _createElementVNode("table", _hoisted_47, [
                _createElementVNode("tbody", null, [
                  _createElementVNode("tr", null, [
                    _cache[23] || (_cache[23] = _createElementVNode("th", { style: {"width":"20%"} }, "Product Name", -1)),
                    _cache[24] || (_cache[24] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                    _createElementVNode("td", null, _toDisplayString(_ctx.viewData.name), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[25] || (_cache[25] = _createElementVNode("th", { style: {"width":"20%"} }, "Buy Price", -1)),
                    _cache[26] || (_cache[26] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                    _createElementVNode("td", null, _toDisplayString(_ctx.viewData.buy_price), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[27] || (_cache[27] = _createElementVNode("th", { style: {"width":"20%"} }, "Sale Price", -1)),
                    _cache[28] || (_cache[28] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                    _createElementVNode("td", null, _toDisplayString(_ctx.viewData.sell_price), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[29] || (_cache[29] = _createElementVNode("th", { style: {"width":"20%"} }, "Wholesale Price", -1)),
                    _cache[30] || (_cache[30] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                    _createElementVNode("td", null, _toDisplayString(_ctx.viewData.wholesale_price), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[31] || (_cache[31] = _createElementVNode("th", { style: {"width":"20%"} }, "Alert Quantity", -1)),
                    _cache[32] || (_cache[32] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                    _createElementVNode("td", null, _toDisplayString(_ctx.viewData.alert_quantity), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[33] || (_cache[33] = _createElementVNode("th", { style: {"width":"20%"} }, "In Stock", -1)),
                    _cache[34] || (_cache[34] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                    _createElementVNode("td", null, _toDisplayString(_ctx.viewData.unit), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[35] || (_cache[35] = _createElementVNode("th", { style: {"width":"20%"} }, "Features", -1)),
                    _cache[36] || (_cache[36] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                    _createElementVNode("td", null, _toDisplayString(_ctx.viewData.features), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[37] || (_cache[37] = _createElementVNode("th", { style: {"width":"20%"} }, "Details", -1)),
                    _cache[38] || (_cache[38] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                    _createElementVNode("td", null, _toDisplayString(_ctx.viewData.description), 1)
                  ])
                ])
              ])
            ])
          ]),
          _cache[40] || (_cache[40] = _createElementVNode("div", { class: "modal-footer" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              "data-bs-dismiss": "modal"
            }, "Close")
          ], -1))
        ])
      ])
    ])
  ]))
}