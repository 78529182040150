import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-success alert-dismissible",
  role: "alert"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-9 col-sm-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "row mb-3" }
const _hoisted_9 = { class: "col-sm-12" }
const _hoisted_10 = { class: "row mb-3" }
const _hoisted_11 = { class: "col-sm-12" }
const _hoisted_12 = { class: "row mb-3" }
const _hoisted_13 = { class: "col-sm-12" }
const _hoisted_14 = { class: "row mb-3" }
const _hoisted_15 = { class: "col-sm-12" }
const _hoisted_16 = { class: "row mb-3" }
const _hoisted_17 = { class: "col-sm-12" }
const _hoisted_18 = { class: "row mb-3" }
const _hoisted_19 = { class: "col-sm-12" }
const _hoisted_20 = { class: "col-md-3 col-sm-12" }
const _hoisted_21 = { class: "card mb-3" }
const _hoisted_22 = { class: "card-body" }
const _hoisted_23 = { class: "row mb-3" }
const _hoisted_24 = { class: "col-sm-12" }
const _hoisted_25 = { class: "row mb-3" }
const _hoisted_26 = { class: "col-sm-12" }
const _hoisted_27 = ["id", "value"]
const _hoisted_28 = ["for"]
const _hoisted_29 = {
  key: 0,
  class: "card"
}
const _hoisted_30 = { class: "card-body" }
const _hoisted_31 = { class: "row mb-3" }
const _hoisted_32 = { class: "col-sm-12" }
const _hoisted_33 = ["required"]
const _hoisted_34 = { class: "row mb-3" }
const _hoisted_35 = { class: "col-sm-12" }
const _hoisted_36 = ["required"]
const _hoisted_37 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_38 = { class: "col-md-12 col-sm-12" }
const _hoisted_39 = { class: "row justify-content-end" }
const _hoisted_40 = { class: "col-sm-12" }
const _hoisted_41 = ["disabled"]
const _hoisted_42 = { key: 0 }
const _hoisted_43 = {
  key: 1,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_44 = {
  key: 2,
  class: "visually-show"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.successReport)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[11] || (_cache[11] = [
          _createTextVNode(" Successfully "),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "aria-label": "Close"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      name: "",
      onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[12] || (_cache[12] = _createElementVNode("label", {
                    class: "col-sm-12 col-form-label",
                    for: "basic-icon-default-store"
                  }, [
                    _createTextVNode("First Name "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: "basic-icon-default-fullname",
                      placeholder: "First Name",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.firstname) = $event)),
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.formData.firstname]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[13] || (_cache[13] = _createElementVNode("label", {
                    class: "col-sm-12 col-form-label",
                    for: "basic-icon-default-store"
                  }, [
                    _createTextVNode("Last Name "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_11, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: "basic-icon-default-fullname",
                      placeholder: "Last Name",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.lastname) = $event)),
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.formData.lastname]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[14] || (_cache[14] = _createElementVNode("label", {
                    class: "col-sm-12 col-form-label",
                    for: "basic-icon-default-store"
                  }, [
                    _createTextVNode("Email "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_13, [
                    _withDirectives(_createElementVNode("input", {
                      type: "email",
                      class: "form-control",
                      id: "basic-icon-default-fullname",
                      placeholder: "Email",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.email) = $event)),
                      required: ""
                    }, null, 512), [
                      [_vModelText, _ctx.formData.email]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _cache[15] || (_cache[15] = _createElementVNode("label", {
                    class: "col-sm-12 col-form-label",
                    for: "basic-icon-default-store"
                  }, "Phone ", -1)),
                  _createElementVNode("div", _hoisted_15, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: "basic-icon-default-fullname",
                      placeholder: "Phone",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.phone) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.formData.phone]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[16] || (_cache[16] = _createElementVNode("label", {
                    class: "col-sm-12 col-form-label",
                    for: "basic-icon-default-store"
                  }, "National Id ", -1)),
                  _createElementVNode("div", _hoisted_17, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "form-control",
                      id: "basic-icon-default-fullname",
                      placeholder: "NID",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.national_id_no) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.formData.national_id_no]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[17] || (_cache[17] = _createElementVNode("label", {
                    class: "col-sm-12 col-form-label",
                    for: "basic-icon-default-store"
                  }, "Address ", -1)),
                  _createElementVNode("div", _hoisted_19, [
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control",
                      id: "exampleFormControlTextarea1",
                      rows: "5",
                      placeholder: "Address",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.address) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.formData.address]
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[19] || (_cache[19] = _createElementVNode("label", {
                  class: "col-sm-12 col-form-label",
                  for: "basic-icon-default-store"
                }, "Type ", -1)),
                _createElementVNode("div", _hoisted_24, [
                  _withDirectives(_createElementVNode("select", {
                    name: "",
                    class: "form-control",
                    id: "",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.type) = $event))
                  }, _cache[18] || (_cache[18] = [
                    _createStaticVNode("<option value=\"\">-- Select Type--</option><option value=\"SUPPLIER\">Supplier</option><option value=\"CUSTOMER\">Customer</option><option value=\"ADMIN\">Admin</option><option value=\"USER\">User</option>", 5)
                  ]), 512), [
                    [_vModelSelect, _ctx.formData.type]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _cache[20] || (_cache[20] = _createElementVNode("label", {
                  class: "col-sm-12 col-form-label",
                  for: "basic-icon-default-store"
                }, "User Scopes ", -1)),
                _createElementVNode("div", _hoisted_26, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userScopes, (userScope, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        class: "form-check-input",
                        id: 'user-scopes-' + index,
                        value: userScope,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.scopes) = $event))
                      }, null, 8, _hoisted_27), [
                        [_vModelCheckbox, _ctx.formData.scopes]
                      ]),
                      _createElementVNode("label", {
                        for: 'user-scopes-' + index
                      }, "   " + _toDisplayString(userScope), 9, _hoisted_28)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ]),
          (_ctx.isNew)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _cache[21] || (_cache[21] = _createElementVNode("label", {
                      class: "col-sm-12 col-form-label",
                      for: "basic-icon-default-store"
                    }, [
                      _createTextVNode("Password "),
                      _createElementVNode("span", { class: "text-danger" }, "*")
                    ], -1)),
                    _createElementVNode("div", _hoisted_32, [
                      _withDirectives(_createElementVNode("input", {
                        type: "password",
                        class: "form-control",
                        id: "basic-icon-default-fullname",
                        placeholder: "Password",
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.password) = $event)),
                        required: _ctx.isNew
                      }, null, 8, _hoisted_33), [
                        [_vModelText, _ctx.formData.password]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _cache[22] || (_cache[22] = _createElementVNode("label", {
                      class: "col-sm-12 col-form-label",
                      for: "basic-icon-default-store"
                    }, [
                      _createTextVNode("Confirm Password "),
                      _createElementVNode("span", { class: "text-danger" }, "*")
                    ], -1)),
                    _createElementVNode("div", _hoisted_35, [
                      _withDirectives(_createElementVNode("input", {
                        type: "password",
                        class: "form-control",
                        id: "basic-icon-default-fullname",
                        placeholder: "Confirm Password",
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formData.password_confirmation) = $event)),
                        required: _ctx.isNew
                      }, null, 8, _hoisted_36), [
                        [_vModelText, _ctx.formData.password_confirmation]
                      ]),
                      (_ctx.formData.password_confirmation !== _ctx.formData.password)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_37, "Passwords do not match"))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_38, [
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn-primary mt-3",
                disabled: _ctx.submitBtn
              }, [
                (!_ctx.submitBtn)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_42, "Save"))
                  : _createCommentVNode("", true),
                (_ctx.submitBtn)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_43))
                  : _createCommentVNode("", true),
                (_ctx.submitBtn)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_44, " Saving ..."))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_41)
            ])
          ])
        ])
      ])
    ], 32)
  ]))
}