import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "layout-page" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "flex-grow-1 container-p-y px-4" }
const _hoisted_4 = { class: "d-flex justify-content-between aling-items-center" }
const _hoisted_5 = {
  key: 0,
  class: ""
}
const _hoisted_6 = {
  key: 0,
  class: "alert alert-success alert-dismissible",
  role: "alert"
}
const _hoisted_7 = { class: "card" }
const _hoisted_8 = { class: "card-body" }
const _hoisted_9 = { class: "table-responsive text-nowrap mt-4" }
const _hoisted_10 = {
  class: "table",
  style: {"border-color":"transparent"}
}
const _hoisted_11 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBarView = _resolveComponent("TopBarView")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopBarView),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("div", null, [
                _createElementVNode("h4", { class: "fw-bold py-3 mb-4" }, [
                  _createElementVNode("span", { class: "text-muted fw-light" }, "Store "),
                  _createTextVNode(" Details ")
                ])
              ], -1)),
              _createElementVNode("div", null, [
                _createVNode(_component_router_link, {
                  to: { path: '/store' },
                  class: "btn btn-primary mt-2"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Back to List ")
                  ])),
                  _: 1
                })
              ])
            ]),
            (_ctx.isDataTrue == false)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_ctx.successReport)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[2] || (_cache[2] = [
                        _createTextVNode(" Successfully "),
                        _createElementVNode("button", {
                          type: "button",
                          class: "btn-close",
                          "aria-label": "Close"
                        }, null, -1)
                      ])))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[31] || (_cache[31] = _createElementVNode("h3", { class: "card-title px-3" }, "Store/ Dealer — Information", -1)),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("table", _hoisted_10, [
                          _createElementVNode("tbody", _hoisted_11, [
                            _createElementVNode("tr", null, [
                              _cache[3] || (_cache[3] = _createElementVNode("th", { style: {"width":"20%"} }, "Store Name", -1)),
                              _cache[4] || (_cache[4] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.name), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[5] || (_cache[5] = _createElementVNode("th", null, "Type", -1)),
                              _cache[6] || (_cache[6] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.type_id), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[7] || (_cache[7] = _createElementVNode("th", null, "Address", -1)),
                              _cache[8] || (_cache[8] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.address), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[9] || (_cache[9] = _createElementVNode("th", null, "Phone", -1)),
                              _cache[10] || (_cache[10] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.phone), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[11] || (_cache[11] = _createElementVNode("th", null, "Email", -1)),
                              _cache[12] || (_cache[12] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.email), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[13] || (_cache[13] = _createElementVNode("th", null, "District", -1)),
                              _cache[14] || (_cache[14] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.district), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[15] || (_cache[15] = _createElementVNode("th", null, "Division", -1)),
                              _cache[16] || (_cache[16] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.division), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[17] || (_cache[17] = _createElementVNode("th", null, "Address", -1)),
                              _cache[18] || (_cache[18] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.address), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[19] || (_cache[19] = _createElementVNode("th", null, "Trade License", -1)),
                              _cache[20] || (_cache[20] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.trade_license), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[21] || (_cache[21] = _createElementVNode("th", null, "Facebook", -1)),
                              _cache[22] || (_cache[22] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.facebook_url), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[23] || (_cache[23] = _createElementVNode("th", null, "Twitter", -1)),
                              _cache[24] || (_cache[24] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.twitter_url), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[25] || (_cache[25] = _createElementVNode("th", null, "Instagram", -1)),
                              _cache[26] || (_cache[26] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.instagram_url), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[27] || (_cache[27] = _createElementVNode("th", null, "Logo", -1)),
                              _cache[28] || (_cache[28] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.logo), 1)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[29] || (_cache[29] = _createElementVNode("th", null, "Cover Image", -1)),
                              _cache[30] || (_cache[30] = _createElementVNode("th", { style: {"width":"2%"} }, ":", -1)),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formData.cover_img), 1)
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createVNode(_component_Footer),
      _cache[32] || (_cache[32] = _createElementVNode("div", { class: "content-backdrop fade" }, null, -1))
    ])
  ]))
}