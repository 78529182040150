import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "layout-wrapper layout-content-navbar" }
const _hoisted_2 = { class: "layout-container" }
const _hoisted_3 = {
  id: "layout-menu",
  class: "layout-menu menu-vertical menu bg-menu-theme"
}
const _hoisted_4 = { class: "app-brand demo" }
const _hoisted_5 = { class: "menu-inner py-1" }
const _hoisted_6 = { class: "menu-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("aside", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "app-brand-link"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("span", { class: "app-brand-text demo text-body fw-bold text-uppercase mb-3" }, [
                _createElementVNode("img", {
                  src: "https://pos-file.phicsart.com/profile_user.png",
                  alt: "n/a",
                  class: "img-fluid",
                  style: {"width":"60px","height":"60px"}
                })
              ], -1)
            ])),
            _: 1
          })
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "menu-inner-shadow" }, null, -1)),
        _createElementVNode("ul", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu, index) => {
            return _withDirectives((_openBlock(), _createElementBlock("li", {
              key: index,
              class: _normalizeClass(["menu-item", { active: _ctx.isActive(menu) }])
            }, [
              _createVNode(_component_router_link, {
                class: "menu-link",
                to: menu.path
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(menu.title), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ], 2)), [
              [_vShow, menu.show]
            ])
          }), 128)),
          _createElementVNode("li", _hoisted_6, [
            _createElementVNode("span", {
              class: "menu-link cursor-pointer",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
            }, " Log Out ")
          ])
        ])
      ]),
      _createVNode(_component_router_view)
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", {
      class: "layout-overlay layout-menu-toggle",
      onclick: "toggleSidebar()"
    }, null, -1))
  ]))
}