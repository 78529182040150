import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "layout-page d-flex align-items-center",
  style: {"min-height":"100vh","width":"100%"}
}
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "flex-grow-1 container-p-y px-4" }
const _hoisted_4 = { class: "col-md-9 col-sm-12 mx-auto" }
const _hoisted_5 = { class: "row h-100" }
const _hoisted_6 = { class: "col-md-6 mx-auto" }
const _hoisted_7 = { class: "h-100" }
const _hoisted_8 = { class: "d-flex align-items-center justify-content-center h-100" }
const _hoisted_9 = { class: "w-100" }
const _hoisted_10 = { class: "authentication-wrapper authentication-basic container-p-y" }
const _hoisted_11 = { class: "authentication-inner w-100" }
const _hoisted_12 = { class: "card" }
const _hoisted_13 = { class: "card-body" }
const _hoisted_14 = { class: "mb-3" }
const _hoisted_15 = { class: "mb-3" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 1,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_21 = {
  key: 2,
  class: "visually-show"
}
const _hoisted_22 = {
  key: 0,
  class: "alert alert-warning text-center alert-dismissible py-3 display-6",
  role: "alert"
}
const _hoisted_23 = {
  key: 1,
  class: "alert alert-success alert-dismissible text-center py-3 display-6",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, [
                                _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"app-brand justify-content-center\"><a href=\"/\" class=\"app-brand-link gap-2\"><span class=\"app-brand-text demo text-body fw-bold text-uppercase mb-3\"><img src=\"https://pos-file.phicsart.com/profile_user.png\" alt=\"n/a\" class=\"img-fluid\" style=\"width:80px;height:80px;\"></span></a></div><h4 class=\"mb-4 text-center\">Raida Trade International — Code Varify 👋</h4>", 2)),
                                _createElementVNode("form", {
                                  id: "formVarify",
                                  class: "mb-3",
                                  onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"]))
                                }, [
                                  _createElementVNode("div", _hoisted_14, [
                                    _cache[2] || (_cache[2] = _createElementVNode("label", {
                                      for: "firstname",
                                      class: "form-label"
                                    }, [
                                      _createTextVNode("Scan Barcode "),
                                      _createElementVNode("sup", { class: "text-danger" }, "*")
                                    ], -1)),
                                    _withDirectives(_createElementVNode("input", {
                                      type: "text",
                                      class: "form-control",
                                      id: "code",
                                      name: "code",
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.code) = $event)),
                                      placeholder: "Code!"
                                    }, null, 512), [
                                      [_vModelText, _ctx.formData.code]
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("div", _hoisted_16, [
                                      _createElementVNode("div", _hoisted_17, [
                                        _createElementVNode("button", {
                                          type: "submit",
                                          class: "btn btn-primary btn-lg w-100 justify-content-center",
                                          disabled: _ctx.submitBtn
                                        }, [
                                          (!_ctx.submitBtn)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_19, "Varify Code"))
                                            : _createCommentVNode("", true),
                                          (_ctx.submitBtn)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_20))
                                            : _createCommentVNode("", true),
                                          (_ctx.submitBtn)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_21, " Varifying ...."))
                                            : _createCommentVNode("", true)
                                        ], 8, _hoisted_18)
                                      ])
                                    ])
                                  ])
                                ], 32),
                                (_ctx.errorMessages)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, " Opps, Code Invalid! "))
                                  : _createCommentVNode("", true),
                                (_ctx.successReport)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[3] || (_cache[3] = [
                                      _createTextVNode(" Code Varified "),
                                      _createElementVNode("button", {
                                        type: "button",
                                        class: "btn-close",
                                        "aria-label": "Close"
                                      }, null, -1)
                                    ])))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "content-backdrop fade" }, null, -1))
    ])
  ]))
}