import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container-xxl" }
const _hoisted_2 = {
  class: "row",
  style: {"min-height":"100vh"}
}
const _hoisted_3 = { class: "col-md-9 col-sm-12 mx-auto" }
const _hoisted_4 = { class: "row h-100" }
const _hoisted_5 = { class: "col-md-6 mx-auto" }
const _hoisted_6 = { class: "d-flex align-items-center justify-content-center h-100" }
const _hoisted_7 = { class: "w-100" }
const _hoisted_8 = { class: "authentication-wrapper authentication-basic container-p-y" }
const _hoisted_9 = {
  class: "authentication-inner",
  style: {"max-width":"550px","margin":"0 auto"}
}
const _hoisted_10 = { class: "card" }
const _hoisted_11 = { class: "card-body" }
const _hoisted_12 = {
  key: 0,
  class: "alert alert-success alert-dismissible",
  role: "alert"
}
const _hoisted_13 = {
  key: 1,
  class: "alert alert-warning alert-dismissible",
  role: "alert"
}
const _hoisted_14 = { class: "mb-3 form-password-toggle" }
const _hoisted_15 = { class: "input-group input-group-merge" }
const _hoisted_16 = ["type"]
const _hoisted_17 = { class: "mb-3 form-password-toggle" }
const _hoisted_18 = { class: "input-group input-group-merge" }
const _hoisted_19 = ["type"]
const _hoisted_20 = { class: "mb-3" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = { key: 0 }
const _hoisted_23 = {
  key: 1,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_24 = {
  key: 2,
  class: "visually-show"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"app-brand justify-content-center\"><a href=\"/\" class=\"app-brand-link gap-2\"><span class=\"app-brand-text demo text-body fw-bold text-uppercase mb-3\"><img src=\"https://pos-file.phicsart.com/profile_user.png\" alt=\"n/a\" class=\"img-fluid\" style=\"width:80px;height:80px;\"></span></a></div><h4 class=\"mb-2 text-center\">Set Password 👋</h4>", 2)),
                        (_ctx.actionReport)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[5] || (_cache[5] = [
                              _createTextVNode(" Password Changed Sccessfully ! Please Login With New Password "),
                              _createElementVNode("button", {
                                type: "button",
                                class: "btn-close",
                                "data-bs-dismiss": "alert",
                                "aria-label": "Close"
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        (_ctx.badactionReport)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[6] || (_cache[6] = [
                              _createTextVNode(" Password & Comfirm Password Not Match ! "),
                              _createElementVNode("button", {
                                type: "button",
                                class: "btn-close",
                                "data-bs-dismiss": "alert",
                                "aria-label": "Close"
                              }, null, -1)
                            ])))
                          : _createCommentVNode("", true),
                        _createElementVNode("form", {
                          id: "formAuthentication",
                          class: "mb-3",
                          onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.setPassword && _ctx.setPassword(...args)), ["prevent"]))
                        }, [
                          _createElementVNode("div", _hoisted_14, [
                            _cache[7] || (_cache[7] = _createElementVNode("label", {
                              for: "email",
                              class: "form-label"
                            }, "New Password", -1)),
                            _createElementVNode("div", _hoisted_15, [
                              _withDirectives(_createElementVNode("input", {
                                type: _ctx.showPassword.new ? 'text' : 'password',
                                class: "form-control",
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.setPasswordForm.new_password) = $event)),
                                placeholder: "Enter New password",
                                "aria-describedby": "password"
                              }, null, 8, _hoisted_16), [
                                [_vModelDynamic, _ctx.setPasswordForm.new_password]
                              ]),
                              _createElementVNode("span", {
                                class: "input-group-text cursor-pointer",
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.togglePassword('new')))
                              }, [
                                _createElementVNode("i", {
                                  class: _normalizeClass(_ctx.showPassword.new ? 'bx bx-show' : 'bx bx-hide')
                                }, null, 2)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _cache[8] || (_cache[8] = _createElementVNode("label", {
                              for: "email",
                              class: "form-label"
                            }, "Confirm Password", -1)),
                            _createElementVNode("div", _hoisted_18, [
                              _withDirectives(_createElementVNode("input", {
                                type: _ctx.showPassword.confirm ? 'text' : 'password',
                                id: "password",
                                class: "form-control",
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.setPasswordForm.confirm_password) = $event)),
                                placeholder: "Confirm New password"
                              }, null, 8, _hoisted_19), [
                                [_vModelDynamic, _ctx.setPasswordForm.confirm_password]
                              ]),
                              _createElementVNode("span", {
                                class: "input-group-text cursor-pointer",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.togglePassword('confirm')))
                              }, [
                                _createElementVNode("i", {
                                  class: _normalizeClass(_ctx.showPassword.confirm ? 'bx bx-show' : 'bx bx-hide')
                                }, null, 2)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("button", {
                              type: "submit",
                              class: "btn btn-primary d-grid w-100 justify-content-center",
                              disabled: _ctx.submitBtn
                            }, [
                              (!_ctx.submitBtn)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_22, "Set"))
                                : _createCommentVNode("", true),
                              (_ctx.submitBtn)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_23))
                                : _createCommentVNode("", true),
                              (_ctx.submitBtn)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_24, " Setting...."))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_21)
                          ])
                        ], 32),
                        _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-center" }, [
                          _createElementVNode("span", null, "You already have an account?"),
                          _createElementVNode("button", {
                            type: "button",
                            class: "btn btn-sm",
                            style: {"color":"#696cff"}
                          }, [
                            _createElementVNode("span", null, "Login")
                          ])
                        ], -1))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}