import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "d-flex align-items-center",
  style: {"min-height":"100vh","width":"100%"}
}
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "w-100" }
const _hoisted_4 = { class: "flex-grow-1 container-p-y px-4" }
const _hoisted_5 = { class: "col-md-9 col-sm-12 mx-auto" }
const _hoisted_6 = { class: "row h-100" }
const _hoisted_7 = { class: "col-md-6 mx-auto" }
const _hoisted_8 = { class: "h-100" }
const _hoisted_9 = { class: "d-flex align-items-center justify-content-center h-100" }
const _hoisted_10 = { class: "w-100" }
const _hoisted_11 = { class: "authentication-wrapper authentication-basic container-p-y" }
const _hoisted_12 = { class: "authentication-inner w-100" }
const _hoisted_13 = { class: "card" }
const _hoisted_14 = { class: "card-body" }
const _hoisted_15 = {
  key: 0,
  class: "alert alert-danger alert-dismissible",
  role: "alert"
}
const _hoisted_16 = { class: "text-center pb-3" }
const _hoisted_17 = { class: "mt-3" }
const _hoisted_18 = { class: "alert alert-success text-black" }
const _hoisted_19 = { class: "mb-0" }
const _hoisted_20 = { class: "mb-0" }
const _hoisted_21 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", _hoisted_14, [
                                _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"app-brand justify-content-center\"><a href=\"/\" class=\"app-brand-link gap-2\"><span class=\"app-brand-text demo text-body fw-bold text-uppercase mb-3\"><img src=\"https://pos-file.phicsart.com/profile_user.png\" alt=\"n/a\" class=\"img-fluid\" style=\"width:80px;height:80px;\"></span></a></div><h4 class=\"mb-4 text-center\">Raida Trade International — Status 👋</h4>", 2)),
                                (_ctx.errorMessages.message)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                      _createTextVNode(_toDisplayString(_ctx.errorMessages.message) + " ", 1),
                                      _cache[1] || (_cache[1] = _createElementVNode("button", {
                                        type: "button",
                                        class: "btn-close",
                                        "aria-label": "Close"
                                      }, null, -1))
                                    ]))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_16, [
                                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "row" }, [
                                    _createElementVNode("i", {
                                      class: "fa fa-check",
                                      "aria-hidden": "true",
                                      style: {"font-size":"100px","color":"green"}
                                    })
                                  ], -1)),
                                  _createElementVNode("div", _hoisted_17, [
                                    _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Warranty Activated !", -1)),
                                    _createElementVNode("div", _hoisted_18, [
                                      _createElementVNode("p", _hoisted_19, [
                                        _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Product Info: ", -1)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.barcodeData.product.name), 1)
                                      ]),
                                      _createElementVNode("p", _hoisted_20, [
                                        _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Warranty Activated Date: ", -1)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.barcodeData.activation_date), 1)
                                      ]),
                                      _createElementVNode("p", _hoisted_21, [
                                        _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Warranty Expire Date: ", -1)),
                                        _createTextVNode(" " + _toDisplayString(_ctx.barcodeData.expire_date), 1)
                                      ])
                                    ])
                                  ]),
                                  _createElementVNode("div", null, [
                                    _createElementVNode("button", {
                                      type: "button",
                                      class: "btn btn-primary w-100 mt-3",
                                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backtoHome && _ctx.backtoHome(...args)))
                                    }, " Back")
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "content-backdrop fade" }, null, -1))
    ])
  ]))
}