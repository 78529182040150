import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container-xxl" }
const _hoisted_2 = {
  class: "row",
  style: {"min-height":"100vh"}
}
const _hoisted_3 = { class: "col-md-9 col-sm-12 mx-auto" }
const _hoisted_4 = { class: "row h-100" }
const _hoisted_5 = { class: "col-md-6 mx-auto" }
const _hoisted_6 = { class: "h-100" }
const _hoisted_7 = { class: "d-flex align-items-center justify-content-center h-100" }
const _hoisted_8 = { class: "w-100" }
const _hoisted_9 = { class: "authentication-wrapper authentication-basic container-p-y" }
const _hoisted_10 = { class: "authentication-inner w-100" }
const _hoisted_11 = { class: "card" }
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = {
  key: 0,
  class: "alert alert-warning alert-dismissible",
  role: "alert"
}
const _hoisted_14 = { class: "mb-3" }
const _hoisted_15 = { class: "mb-3" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 1,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_19 = {
  key: 2,
  class: "visually-show"
}
const _hoisted_20 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"app-brand justify-content-center\"><a href=\"/\" class=\"app-brand-link gap-2\"><span class=\"app-brand-text demo text-body fw-bold text-uppercase mb-3\"><img src=\"https://pos-file.phicsart.com/profile_user.png\" alt=\"n/a\" class=\"img-fluid\" style=\"width:80px;height:80px;\"></span></a></div><h4 class=\"mb-2 text-center\">Recover Password 👋</h4>", 2)),
                          (_ctx.actionReport)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[3] || (_cache[3] = [
                                _createTextVNode(" Account Not Found .! "),
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn-close",
                                  "aria-label": "Close"
                                }, null, -1)
                              ])))
                            : _createCommentVNode("", true),
                          _createElementVNode("form", {
                            id: "formAuthentication",
                            class: "mb-3",
                            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.forgetFunc && _ctx.forgetFunc(...args)), ["prevent"]))
                          }, [
                            _createElementVNode("div", _hoisted_14, [
                              _cache[4] || (_cache[4] = _createElementVNode("label", {
                                for: "email",
                                class: "form-label"
                              }, "Email", -1)),
                              _withDirectives(_createElementVNode("input", {
                                type: "email",
                                class: "form-control",
                                id: "email",
                                name: "email-username",
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ForgetPasswordForm.email) = $event)),
                                placeholder: "Enter your email"
                              }, null, 512), [
                                [_vModelText, _ctx.ForgetPasswordForm.email]
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("button", {
                                type: "submit",
                                class: "btn btn-primary w-100 justify-content-center",
                                disabled: _ctx.submitBtn
                              }, [
                                (!_ctx.submitBtn)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Send Verification Code"))
                                  : _createCommentVNode("", true),
                                (_ctx.submitBtn)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_18))
                                  : _createCommentVNode("", true),
                                (_ctx.submitBtn)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_19, " Sending ...."))
                                  : _createCommentVNode("", true)
                              ], 8, _hoisted_16)
                            ])
                          ], 32),
                          _createElementVNode("p", _hoisted_20, [
                            _cache[6] || (_cache[6] = _createElementVNode("span", null, "Already have an account?", -1)),
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-sm",
                              style: {"color":"#696cff"},
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.switchToLogin()))
                            }, _cache[5] || (_cache[5] = [
                              _createElementVNode("span", null, "Login", -1)
                            ]))
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}