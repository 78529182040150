import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "layout-page" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "flex-grow-1 container-p-y px-4" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "text-nowrap" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "py-3" }
const _hoisted_11 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_12 = {
  key: 1,
  class: "row invoice-preview"
}
const _hoisted_13 = { class: "col-xl-9 col-md-8 col-12 mb-md-0 mb-4" }
const _hoisted_14 = {
  class: "card invoice-preview-card",
  id: "invoice-container"
}
const _hoisted_15 = { class: "card-body" }
const _hoisted_16 = { class: "d-flex justify-content-between flex-xl-row flex-md-column flex-sm-row flex-column p-sm-3 p-0" }
const _hoisted_17 = { class: "text-wrap" }
const _hoisted_18 = { class: "mb-2" }
const _hoisted_19 = { class: "fw-medium" }
const _hoisted_20 = { class: "mb-xl-0 mb-4" }
const _hoisted_21 = { class: "d-flex svg-illustration mb-2 gap-2" }
const _hoisted_22 = { class: "app-brand-text demo menu-text fw-bolder text-uppercase" }
const _hoisted_23 = { class: "mb-1" }
const _hoisted_24 = { class: "mb-0" }
const _hoisted_25 = { class: "card-body" }
const _hoisted_26 = { class: "row p-sm-3 p-0" }
const _hoisted_27 = { class: "col-xl-12 col-md-12 col-sm-7 col-12" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { key: 2 }
const _hoisted_31 = { key: 3 }
const _hoisted_32 = { class: "d-none d-md-block" }
const _hoisted_33 = { class: "table-responsive mx-4" }
const _hoisted_34 = { class: "table table-bordered m-0 text-center" }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { class: "mobile-view d-block d-md-none" }
const _hoisted_37 = { class: "mb-4" }
const _hoisted_38 = { class: "card-body" }
const _hoisted_39 = { class: "mb-0" }
const _hoisted_40 = { class: "mb-0" }
const _hoisted_41 = { class: "mb-0" }
const _hoisted_42 = { class: "mb-0 text-end" }
const _hoisted_43 = { class: "mb-0 text-end" }
const _hoisted_44 = { class: "mb-0 text-end" }
const _hoisted_45 = { class: "col-sm-3 d-none" }
const _hoisted_46 = { id: "invoice-container-57mm" }
const _hoisted_47 = { class: "card invoice-preview-card" }
const _hoisted_48 = { class: "card-body m-0" }
const _hoisted_49 = { class: "" }
const _hoisted_50 = { class: "mb-xl-0" }
const _hoisted_51 = { class: "d-flex svg-illustration mb-2 gap-2" }
const _hoisted_52 = { class: "app-brand-text demo menu-text fw-bolder text-uppercase" }
const _hoisted_53 = { class: "mb-0" }
const _hoisted_54 = { class: "mb-0" }
const _hoisted_55 = { class: "text-wrap m-0" }
const _hoisted_56 = { class: "mb-2" }
const _hoisted_57 = { class: "fw-medium" }
const _hoisted_58 = { class: "card-body" }
const _hoisted_59 = { class: "table" }
const _hoisted_60 = { class: "" }
const _hoisted_61 = { class: "table text-center" }
const _hoisted_62 = { class: "col-sm-4 d-none" }
const _hoisted_63 = { id: "invoice-container-80mm" }
const _hoisted_64 = { class: "card" }
const _hoisted_65 = { class: "card-body m-0" }
const _hoisted_66 = { class: "" }
const _hoisted_67 = { class: "mb-xl-0" }
const _hoisted_68 = { class: "d-flex svg-illustration mb-2 gap-2" }
const _hoisted_69 = { class: "app-brand-text demo menu-text fw-bolder text-uppercase" }
const _hoisted_70 = { class: "mb-0" }
const _hoisted_71 = { class: "mb-0" }
const _hoisted_72 = { class: "text-wrap m-0" }
const _hoisted_73 = { class: "mb-2" }
const _hoisted_74 = { class: "fw-medium" }
const _hoisted_75 = { class: "card-body" }
const _hoisted_76 = { class: "table" }
const _hoisted_77 = { class: "" }
const _hoisted_78 = { class: "table text-center" }
const _hoisted_79 = { class: "col-xl-3 col-md-4 col-12 invoice-actions" }
const _hoisted_80 = { class: "card mb-3" }
const _hoisted_81 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBarView = _resolveComponent("TopBarView")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TopBarView),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _cache[52] || (_cache[52] = _createElementVNode("div", { class: "d-flex justify-content-between aling-items-center" }, [
                _createElementVNode("div", null, [
                  _createElementVNode("h4", { class: "fw-bold py-3 mb-4 ps-2" }, [
                    _createElementVNode("span", { class: "text-muted fw-light" }, "Invoice "),
                    _createTextVNode("View ")
                  ])
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              (_ctx.loading)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[4] || (_cache[4] = [
                                    _createElementVNode("div", {
                                      class: "spinner-border text-primary",
                                      role: "status"
                                    }, [
                                      _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                                    ], -1)
                                  ])))
                                : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    _createElementVNode("div", _hoisted_13, [
                                      _createElementVNode("div", _hoisted_14, [
                                        _createElementVNode("div", _hoisted_15, [
                                          _createElementVNode("div", _hoisted_16, [
                                            _createElementVNode("div", null, [
                                              _createElementVNode("h4", _hoisted_17, "Invoice No: " + _toDisplayString(_ctx.orderData.invoice_no), 1),
                                              _createElementVNode("div", _hoisted_18, [
                                                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "me-1" }, "Date:", -1)),
                                                _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.orderData.created_at), 1)
                                              ])
                                            ]),
                                            _createElementVNode("div", _hoisted_20, [
                                              _createElementVNode("div", _hoisted_21, [
                                                _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.storeData.name), 1)
                                              ]),
                                              _createElementVNode("p", _hoisted_23, "Address: " + _toDisplayString(_ctx.orderData?.store?.address), 1),
                                              _createElementVNode("p", _hoisted_24, "Phone: " + _toDisplayString(_ctx.orderData?.store?.phone), 1)
                                            ])
                                          ])
                                        ]),
                                        _cache[21] || (_cache[21] = _createElementVNode("hr", { class: "my-0" }, null, -1)),
                                        _createElementVNode("div", _hoisted_25, [
                                          _createElementVNode("div", _hoisted_26, [
                                            _createElementVNode("div", _hoisted_27, [
                                              _cache[10] || (_cache[10] = _createElementVNode("h6", { class: "pb-0" }, [
                                                _createElementVNode("strong", null, " Customer: ")
                                              ], -1)),
                                              _createElementVNode("table", null, [
                                                _createElementVNode("tbody", null, [
                                                  (_ctx.orderData?.customer?.name)
                                                    ? (_openBlock(), _createElementBlock("tr", _hoisted_28, [
                                                        _cache[6] || (_cache[6] = _createElementVNode("td", { class: "pe-3" }, "Name: ", -1)),
                                                        _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer?.name), 1)
                                                      ]))
                                                    : _createCommentVNode("", true),
                                                  (_ctx.orderData?.customer_type)
                                                    ? (_openBlock(), _createElementBlock("tr", _hoisted_29, [
                                                        _cache[7] || (_cache[7] = _createElementVNode("td", { class: "pe-3" }, "Type: ", -1)),
                                                        _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer_type), 1)
                                                      ]))
                                                    : _createCommentVNode("", true),
                                                  (_ctx.orderData?.customer?.phone)
                                                    ? (_openBlock(), _createElementBlock("tr", _hoisted_30, [
                                                        _cache[8] || (_cache[8] = _createElementVNode("td", { class: "pe-3" }, "Phone:", -1)),
                                                        _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer?.phone), 1)
                                                      ]))
                                                    : _createCommentVNode("", true),
                                                  (_ctx.orderData?.customer?.address)
                                                    ? (_openBlock(), _createElementBlock("tr", _hoisted_31, [
                                                        _cache[9] || (_cache[9] = _createElementVNode("td", { class: "pe-3" }, "Address:", -1)),
                                                        _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer?.address), 1)
                                                      ]))
                                                    : _createCommentVNode("", true)
                                                ])
                                              ])
                                            ])
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_32, [
                                          _createElementVNode("div", _hoisted_33, [
                                            _createElementVNode("table", _hoisted_34, [
                                              _cache[15] || (_cache[15] = _createElementVNode("thead", null, [
                                                _createElementVNode("tr", null, [
                                                  _createElementVNode("th", null, "Id"),
                                                  _createElementVNode("th", null, "Name"),
                                                  _createElementVNode("th", null, "Qty X Price "),
                                                  _createElementVNode("th", null, "Total")
                                                ])
                                              ], -1)),
                                              _createElementVNode("tbody", null, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderData.order_items, (dataItem, index) => {
                                                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                                    _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                                                    _createElementVNode("td", null, _toDisplayString(dataItem?.product_name), 1),
                                                    _createElementVNode("td", null, _toDisplayString(dataItem.quantity) + " x " + _toDisplayString(dataItem.sell_price), 1),
                                                    _createElementVNode("td", null, _toDisplayString(dataItem.total), 1)
                                                  ]))
                                                }), 128))
                                              ]),
                                              _createElementVNode("tfoot", null, [
                                                _createElementVNode("tr", null, [
                                                  _cache[11] || (_cache[11] = _createElementVNode("th", {
                                                    colspan: "3",
                                                    class: "text-end"
                                                  }, "Total =", -1)),
                                                  _createElementVNode("th", null, _toDisplayString(_ctx.orderData.total), 1)
                                                ]),
                                                _createElementVNode("tr", null, [
                                                  _cache[12] || (_cache[12] = _createElementVNode("th", {
                                                    colspan: "3",
                                                    class: "text-end"
                                                  }, "Discount = ", -1)),
                                                  _createElementVNode("th", null, _toDisplayString((_ctx.orderData.total - _ctx.orderData.sub_total).toFixed(2)), 1)
                                                ]),
                                                (_ctx.orderData.due < 0)
                                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_35, [
                                                      _cache[13] || (_cache[13] = _createElementVNode("th", {
                                                        colspan: "3",
                                                        class: "text-end"
                                                      }, "Due =", -1)),
                                                      _createElementVNode("th", null, _toDisplayString(_ctx.orderData.due), 1)
                                                    ]))
                                                  : _createCommentVNode("", true),
                                                _createElementVNode("tr", null, [
                                                  _cache[14] || (_cache[14] = _createElementVNode("th", {
                                                    colspan: "3",
                                                    class: "text-end"
                                                  }, "Sub Total =", -1)),
                                                  _createElementVNode("th", null, _toDisplayString(_ctx.orderData.sub_total), 1)
                                                ])
                                              ])
                                            ])
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_36, [
                                          _createElementVNode("div", _hoisted_37, [
                                            _createElementVNode("div", _hoisted_38, [
                                              _cache[20] || (_cache[20] = _createElementVNode("h6", null, [
                                                _createElementVNode("strong", null, " Order Details: ")
                                              ], -1)),
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderData.order_items, (dataItem, index) => {
                                                return (_openBlock(), _createElementBlock("div", { key: index }, [
                                                  _createElementVNode("strong", null, " Sl: " + _toDisplayString(index + 1), 1),
                                                  _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
                                                  _createElementVNode("p", _hoisted_39, [
                                                    _createElementVNode("strong", null, " Name: " + _toDisplayString(dataItem?.product_name), 1)
                                                  ]),
                                                  _createElementVNode("p", _hoisted_40, "Qty X Price: " + _toDisplayString(dataItem.quantity) + " x " + _toDisplayString(dataItem.sell_price), 1),
                                                  _createElementVNode("p", _hoisted_41, "Total: " + _toDisplayString(dataItem.total), 1),
                                                  _cache[17] || (_cache[17] = _createElementVNode("hr", null, null, -1))
                                                ]))
                                              }), 128)),
                                              _createElementVNode("div", null, [
                                                _createElementVNode("p", _hoisted_42, [
                                                  _cache[18] || (_cache[18] = _createTextVNode(" Total: ")),
                                                  _createElementVNode("strong", null, _toDisplayString(_ctx.orderData.total), 1)
                                                ]),
                                                _createElementVNode("p", _hoisted_43, " Discount: " + _toDisplayString((_ctx.orderData.total - _ctx.orderData.sub_total).toFixed(2)), 1),
                                                _createElementVNode("p", _hoisted_44, [
                                                  _cache[19] || (_cache[19] = _createTextVNode(" Sub Total: ")),
                                                  _createElementVNode("strong", null, _toDisplayString(_ctx.orderData.sub_total), 1)
                                                ])
                                              ])
                                            ])
                                          ])
                                        ]),
                                        _cache[22] || (_cache[22] = _createStaticVNode("<div class=\"card-body\"><div class=\"row\"><div class=\"col-12\"><span class=\"text-wrap\">Design &amp; Developed by <strong>PhicsArt</strong>!  <img src=\"https://phicsart.com/uploads/images/top-page/menu/logo.svg\" alt=\"\" class=\"img-fluid\" style=\"max-width:13px;\"></span></div></div></div>", 1))
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_45, [
                                      _createElementVNode("div", _hoisted_46, [
                                        _createElementVNode("div", _hoisted_47, [
                                          _createElementVNode("div", _hoisted_48, [
                                            _createElementVNode("div", _hoisted_49, [
                                              _createElementVNode("div", _hoisted_50, [
                                                _createElementVNode("div", _hoisted_51, [
                                                  _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.storeData.name), 1)
                                                ]),
                                                _createElementVNode("p", _hoisted_53, "Address: " + _toDisplayString(_ctx.orderData?.store?.address), 1),
                                                _createElementVNode("p", _hoisted_54, "Phone: " + _toDisplayString(_ctx.orderData?.store?.phone), 1)
                                              ]),
                                              _createElementVNode("div", null, [
                                                _createElementVNode("h4", _hoisted_55, "Invoice No: " + _toDisplayString(_ctx.orderData.invoice_no), 1),
                                                _createElementVNode("div", _hoisted_56, [
                                                  _cache[23] || (_cache[23] = _createElementVNode("span", { class: "me-1" }, "Date:", -1)),
                                                  _createElementVNode("span", _hoisted_57, _toDisplayString(_ctx.orderData.created_at), 1)
                                                ])
                                              ])
                                            ])
                                          ]),
                                          _cache[33] || (_cache[33] = _createElementVNode("hr", { class: "my-0" }, null, -1)),
                                          _createElementVNode("div", _hoisted_58, [
                                            _cache[28] || (_cache[28] = _createElementVNode("h6", { class: "pb-0" }, [
                                              _createElementVNode("strong", null, " Customer: ")
                                            ], -1)),
                                            _createElementVNode("table", _hoisted_59, [
                                              _createElementVNode("tbody", null, [
                                                _createElementVNode("tr", null, [
                                                  _cache[24] || (_cache[24] = _createElementVNode("td", { class: "pe-3" }, "Name: ", -1)),
                                                  _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer?.name), 1)
                                                ]),
                                                _createElementVNode("tr", null, [
                                                  _cache[25] || (_cache[25] = _createElementVNode("td", { class: "pe-3" }, "Type: ", -1)),
                                                  _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer_type), 1)
                                                ]),
                                                _createElementVNode("tr", null, [
                                                  _cache[26] || (_cache[26] = _createElementVNode("td", { class: "pe-3" }, "Phone:", -1)),
                                                  _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer?.phone), 1)
                                                ]),
                                                _createElementVNode("tr", null, [
                                                  _cache[27] || (_cache[27] = _createElementVNode("td", { class: "pe-3" }, "Address:", -1)),
                                                  _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer?.address), 1)
                                                ])
                                              ])
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_60, [
                                            _createElementVNode("table", _hoisted_61, [
                                              _cache[32] || (_cache[32] = _createElementVNode("thead", null, [
                                                _createElementVNode("tr", null, [
                                                  _createElementVNode("th", null, "Id"),
                                                  _createElementVNode("th", null, "Name"),
                                                  _createElementVNode("th", null, "Qty "),
                                                  _createElementVNode("th", null, "Price "),
                                                  _createElementVNode("th", null, "Total")
                                                ])
                                              ], -1)),
                                              _createElementVNode("tbody", null, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderData.order_items, (dataItem, index) => {
                                                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                                    _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                                                    _createElementVNode("td", null, _toDisplayString(dataItem?.product_name), 1),
                                                    _createElementVNode("td", null, _toDisplayString(dataItem.quantity), 1),
                                                    _createElementVNode("td", null, _toDisplayString(dataItem.sell_price), 1),
                                                    _createElementVNode("td", null, _toDisplayString(dataItem.total), 1)
                                                  ]))
                                                }), 128))
                                              ]),
                                              _createElementVNode("tfoot", null, [
                                                _createElementVNode("tr", null, [
                                                  _cache[29] || (_cache[29] = _createElementVNode("th", {
                                                    colspan: "4",
                                                    class: "text-end"
                                                  }, "Total =", -1)),
                                                  _createElementVNode("th", null, _toDisplayString(_ctx.orderData.total), 1)
                                                ]),
                                                _createElementVNode("tr", null, [
                                                  _cache[30] || (_cache[30] = _createElementVNode("th", {
                                                    colspan: "4",
                                                    class: "text-end"
                                                  }, "Discount = ", -1)),
                                                  _createElementVNode("th", null, _toDisplayString(_ctx.orderData.discount), 1)
                                                ]),
                                                _createElementVNode("tr", null, [
                                                  _cache[31] || (_cache[31] = _createElementVNode("th", {
                                                    colspan: "4",
                                                    class: "text-end"
                                                  }, "Sub Total =", -1)),
                                                  _createElementVNode("th", null, _toDisplayString(_ctx.orderData.sub_total), 1)
                                                ])
                                              ])
                                            ])
                                          ]),
                                          _cache[34] || (_cache[34] = _createStaticVNode("<div class=\"card-body\"><div class=\"row\"><div class=\"col-12\"><span class=\"text-wrap\">Design &amp; Developed by <strong>PhicsArt</strong>!  <img src=\"https://phicsart.com/uploads/images/top-page/menu/logo.svg\" alt=\"\" class=\"img-fluid\" style=\"max-width:13px;\"></span></div></div></div>", 1))
                                        ])
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_62, [
                                      _createElementVNode("div", _hoisted_63, [
                                        _createElementVNode("div", _hoisted_64, [
                                          _createElementVNode("div", _hoisted_65, [
                                            _createElementVNode("div", _hoisted_66, [
                                              _createElementVNode("div", _hoisted_67, [
                                                _createElementVNode("div", _hoisted_68, [
                                                  _createElementVNode("span", _hoisted_69, _toDisplayString(_ctx.storeData.name), 1)
                                                ]),
                                                _createElementVNode("p", _hoisted_70, "Address: " + _toDisplayString(_ctx.orderData?.store?.address), 1),
                                                _createElementVNode("p", _hoisted_71, "Phone: " + _toDisplayString(_ctx.orderData?.store?.phone), 1)
                                              ]),
                                              _createElementVNode("div", null, [
                                                _createElementVNode("h4", _hoisted_72, "Invoice No: " + _toDisplayString(_ctx.orderData.invoice_no), 1),
                                                _createElementVNode("div", _hoisted_73, [
                                                  _cache[35] || (_cache[35] = _createElementVNode("span", { class: "me-1" }, "Date:", -1)),
                                                  _createElementVNode("span", _hoisted_74, _toDisplayString(_ctx.orderData.created_at), 1)
                                                ])
                                              ])
                                            ])
                                          ]),
                                          _cache[45] || (_cache[45] = _createElementVNode("hr", { class: "my-0" }, null, -1)),
                                          _createElementVNode("div", _hoisted_75, [
                                            _cache[40] || (_cache[40] = _createElementVNode("h6", { class: "pb-0" }, [
                                              _createElementVNode("strong", null, " Customer: ")
                                            ], -1)),
                                            _createElementVNode("table", _hoisted_76, [
                                              _createElementVNode("tbody", null, [
                                                _createElementVNode("tr", null, [
                                                  _cache[36] || (_cache[36] = _createElementVNode("td", { class: "pe-3" }, "Name: ", -1)),
                                                  _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer?.name), 1)
                                                ]),
                                                _createElementVNode("tr", null, [
                                                  _cache[37] || (_cache[37] = _createElementVNode("td", { class: "pe-3" }, "Type: ", -1)),
                                                  _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer_type), 1)
                                                ]),
                                                _createElementVNode("tr", null, [
                                                  _cache[38] || (_cache[38] = _createElementVNode("td", { class: "pe-3" }, "Phone:", -1)),
                                                  _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer?.phone), 1)
                                                ]),
                                                _createElementVNode("tr", null, [
                                                  _cache[39] || (_cache[39] = _createElementVNode("td", { class: "pe-3" }, "Address:", -1)),
                                                  _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer?.address), 1)
                                                ])
                                              ])
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_77, [
                                            _createElementVNode("table", _hoisted_78, [
                                              _cache[44] || (_cache[44] = _createElementVNode("thead", null, [
                                                _createElementVNode("tr", null, [
                                                  _createElementVNode("th", null, "Id"),
                                                  _createElementVNode("th", null, "Name"),
                                                  _createElementVNode("th", null, "Qty "),
                                                  _createElementVNode("th", null, "Price "),
                                                  _createElementVNode("th", null, "Total")
                                                ])
                                              ], -1)),
                                              _createElementVNode("tbody", null, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderData.order_items, (dataItem, index) => {
                                                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                                    _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                                                    _createElementVNode("td", null, _toDisplayString(dataItem?.product_name), 1),
                                                    _createElementVNode("td", null, _toDisplayString(dataItem.quantity), 1),
                                                    _createElementVNode("td", null, _toDisplayString(dataItem.sell_price), 1),
                                                    _createElementVNode("td", null, _toDisplayString(dataItem.total), 1)
                                                  ]))
                                                }), 128))
                                              ]),
                                              _createElementVNode("tfoot", null, [
                                                _createElementVNode("tr", null, [
                                                  _cache[41] || (_cache[41] = _createElementVNode("th", {
                                                    colspan: "4",
                                                    class: "text-end"
                                                  }, "Total =", -1)),
                                                  _createElementVNode("th", null, _toDisplayString(_ctx.orderData.total), 1)
                                                ]),
                                                _createElementVNode("tr", null, [
                                                  _cache[42] || (_cache[42] = _createElementVNode("th", {
                                                    colspan: "4",
                                                    class: "text-end"
                                                  }, "Discount = ", -1)),
                                                  _createElementVNode("th", null, _toDisplayString(_ctx.orderData.discount), 1)
                                                ]),
                                                _createElementVNode("tr", null, [
                                                  _cache[43] || (_cache[43] = _createElementVNode("th", {
                                                    colspan: "4",
                                                    class: "text-end"
                                                  }, "Sub Total =", -1)),
                                                  _createElementVNode("th", null, _toDisplayString(_ctx.orderData.sub_total), 1)
                                                ])
                                              ])
                                            ])
                                          ]),
                                          _cache[46] || (_cache[46] = _createStaticVNode("<div class=\"card-body\"><div class=\"row\"><div class=\"col-12\"><span class=\"text-wrap\">Design &amp; Developed by <strong>PhicsArt</strong>!  <img src=\"https://phicsart.com/uploads/images/top-page/menu/logo.svg\" alt=\"\" class=\"img-fluid\" style=\"max-width:13px;\"></span></div></div></div>", 1))
                                        ])
                                      ])
                                    ]),
                                    _createElementVNode("div", _hoisted_79, [
                                      _createElementVNode("div", _hoisted_80, [
                                        _createElementVNode("div", _hoisted_81, [
                                          _createElementVNode("button", {
                                            class: "btn btn-primary d-grid w-100 mb-3",
                                            "data-bs-toggle": "offcanvas",
                                            "data-bs-target": "#sendInvoiceOffcanvas",
                                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.printInvoice('print')))
                                          }, _cache[47] || (_cache[47] = [
                                            _createElementVNode("span", { class: "d-flex align-items-center justify-content-center text-nowrap" }, [
                                              _createElementVNode("i", { class: "bx bx-printer" }),
                                              _createTextVNode("   Print A4")
                                            ], -1)
                                          ])),
                                          _createElementVNode("button", {
                                            class: "btn btn-default border d-grid w-100 mb-3",
                                            "data-bs-toggle": "offcanvas",
                                            "data-bs-target": "#sendInvoiceOffcanvas",
                                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.printInvoice('80mm')))
                                          }, _cache[48] || (_cache[48] = [
                                            _createElementVNode("span", { class: "d-flex align-items-center justify-content-center text-nowrap" }, [
                                              _createElementVNode("i", { class: "bx bx-printer" }),
                                              _createTextVNode("   Print 80MM")
                                            ], -1)
                                          ])),
                                          _createElementVNode("button", {
                                            class: "btn btn-default border d-grid w-100 mb-3",
                                            "data-bs-toggle": "offcanvas",
                                            "data-bs-target": "#sendInvoiceOffcanvas",
                                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.printInvoice('57mm')))
                                          }, _cache[49] || (_cache[49] = [
                                            _createElementVNode("span", { class: "d-flex align-items-center justify-content-center text-nowrap" }, [
                                              _createElementVNode("i", { class: "bx bx-printer" }),
                                              _createTextVNode("   Print 57MM")
                                            ], -1)
                                          ])),
                                          _createElementVNode("button", {
                                            class: "btn btn-label-secondary btn-default border d-grid w-100 mb-3",
                                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.downloadInvoice(_ctx.orderData)))
                                          }, _cache[50] || (_cache[50] = [
                                            _createElementVNode("span", { class: "d-flex align-items-center justify-content-center text-nowrap" }, [
                                              _createElementVNode("i", { class: "bx bx-download" }),
                                              _createTextVNode("   PDF Download")
                                            ], -1)
                                          ])),
                                          _cache[51] || (_cache[51] = _createElementVNode("button", { class: "btn btn-label-secondary border d-grid w-100 mb-3" }, [
                                            _createElementVNode("span", { class: "d-flex align-items-center justify-content-center text-nowrap" }, [
                                              _createElementVNode("i", { class: "bx bx-paper-plane bx-xs me-1" }),
                                              _createTextVNode("Send Mail")
                                            ])
                                          ], -1))
                                        ])
                                      ])
                                    ])
                                  ]))
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createVNode(_component_Footer),
        _cache[53] || (_cache[53] = _createElementVNode("div", { class: "content-backdrop fade" }, null, -1))
      ])
    ]),
    _cache[54] || (_cache[54] = _createElementVNode("div", { class: "" }, null, -1))
  ], 64))
}