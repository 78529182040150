import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container-xxl" }
const _hoisted_2 = {
  class: "row",
  style: {"min-height":"100vh"}
}
const _hoisted_3 = { class: "col-md-9 col-sm-12 mx-auto" }
const _hoisted_4 = { class: "row h-100" }
const _hoisted_5 = { class: "col-md-6 mx-auto" }
const _hoisted_6 = { class: "h-100" }
const _hoisted_7 = { class: "d-flex align-items-center justify-content-center h-100" }
const _hoisted_8 = { class: "w-100" }
const _hoisted_9 = { class: "authentication-wrapper authentication-basic container-p-y" }
const _hoisted_10 = { class: "authentication-inner w-100" }
const _hoisted_11 = { class: "card" }
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = {
  key: 0,
  class: "alert alert-danger alert-dismissible",
  role: "alert"
}
const _hoisted_14 = { class: "mb-3" }
const _hoisted_15 = { class: "mb-3 form-password-toggle" }
const _hoisted_16 = { class: "d-flex justify-content-between" }
const _hoisted_17 = { class: "input-group input-group-merge" }
const _hoisted_18 = ["type"]
const _hoisted_19 = { class: "mb-3" }
const _hoisted_20 = ["disabled"]
const _hoisted_21 = { key: 0 }
const _hoisted_22 = {
  key: 1,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_23 = {
  key: 2,
  class: "visually-show"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"app-brand justify-content-center\"><a href=\"/\" class=\"app-brand-link gap-2\"><span class=\"app-brand-text demo text-body fw-bold text-uppercase mb-3\"><img src=\"https://pos-file.phicsart.com/profile_user.png\" alt=\"n/a\" class=\"img-fluid\" style=\"width:80px;height:80px;\"></span></a></div><h4 class=\"mb-4 text-center\">Login Form 👋</h4>", 2)),
                          (_ctx.errorMessages.message)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createTextVNode(_toDisplayString(_ctx.errorMessages.message) + " ", 1),
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn-close",
                                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearError('message'))),
                                  "aria-label": "Close"
                                })
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("form", {
                            id: "formAuthentication",
                            class: "mb-3",
                            onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onLogin && _ctx.onLogin(...args)), ["prevent"]))
                          }, [
                            _createElementVNode("div", _hoisted_14, [
                              _cache[6] || (_cache[6] = _createElementVNode("label", {
                                for: "email",
                                class: "form-label"
                              }, [
                                _createTextVNode("Email"),
                                _createElementVNode("sup", { class: "text-danger" }, "*")
                              ], -1)),
                              _withDirectives(_createElementVNode("input", {
                                type: "email",
                                class: "form-control",
                                id: "email",
                                name: "email-username",
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginForm.email) = $event)),
                                placeholder: "Enter your email"
                              }, null, 512), [
                                [_vModelText, _ctx.loginForm.email]
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _cache[8] || (_cache[8] = _createElementVNode("label", {
                                  class: "form-label pt-2",
                                  for: "password"
                                }, [
                                  _createTextVNode("Password"),
                                  _createElementVNode("sup", { class: "text-danger" }, "*")
                                ], -1)),
                                _createElementVNode("button", {
                                  class: "btn btn-outline-default",
                                  type: "button",
                                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.switchToForget()))
                                }, _cache[7] || (_cache[7] = [
                                  _createElementVNode("small", null, "Forgot Password?", -1)
                                ]))
                              ]),
                              _createElementVNode("div", _hoisted_17, [
                                _withDirectives(_createElementVNode("input", {
                                  type: _ctx.showPassword ? 'text' : 'password',
                                  id: "password",
                                  class: "form-control",
                                  name: "password",
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.loginForm.password) = $event)),
                                  placeholder: " Enter your Password"
                                }, null, 8, _hoisted_18), [
                                  [_vModelDynamic, _ctx.loginForm.password]
                                ]),
                                _createElementVNode("span", {
                                  class: "input-group-text cursor-pointer",
                                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.togglePassword && _ctx.togglePassword(...args)))
                                }, [
                                  _createElementVNode("i", {
                                    class: _normalizeClass(_ctx.showPassword ? 'bx bx-show' : 'bx bx-hide')
                                  }, null, 2)
                                ])
                              ])
                            ]),
                            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "mb-3" }, [
                              _createElementVNode("div", { class: "form-check" }, [
                                _createElementVNode("input", {
                                  class: "form-check-input",
                                  type: "checkbox",
                                  id: "remember-me"
                                }),
                                _createElementVNode("label", {
                                  class: "form-check-label",
                                  for: "remember-me"
                                }, " Remember Me ")
                              ])
                            ], -1)),
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("button", {
                                type: "submit",
                                class: "btn btn-primary w-100 justify-content-center",
                                disabled: _ctx.submitBtn
                              }, [
                                (!_ctx.submitBtn)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_21, "Sign in"))
                                  : _createCommentVNode("", true),
                                (_ctx.submitBtn)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_22))
                                  : _createCommentVNode("", true),
                                (_ctx.submitBtn)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_23, " Sign in ...."))
                                  : _createCommentVNode("", true)
                              ], 8, _hoisted_20)
                            ])
                          ], 32)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}