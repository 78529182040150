import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-page" }
const _hoisted_2 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBarView = _resolveComponent("TopBarView")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopBarView),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"flex-grow-1 container-p-y px-4\" data-v-1c156f66><div data-v-1c156f66><h1 class=\"fw-bold py-3 mb-4\" data-v-1c156f66><span class=\"text-muted fw-light\" data-v-1c156f66></span><div class=\"mb-2 text-center\" data-v-1c156f66>404</div></h1><p class=\"text-center\" data-v-1c156f66>Sorry, the page you are looking for does not exist.</p></div></div>", 1)),
      _createVNode(_component_Footer),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "content-backdrop fade" }, null, -1))
    ])
  ]))
}