import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-4" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_4 = {
  key: 1,
  class: "table card-table"
}
const _hoisted_5 = { class: "table-border-bottom-0" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  class: "modal fade",
  id: "modalCenter",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_9 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_10 = { class: "modal-content" }
const _hoisted_11 = { class: "modal-body text-center" }
const _hoisted_12 = { class: "modal-footer text-center justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"card mb-4\"><div class=\"card-body\"><div class=\"row\"><div class=\"input-group\"><div class=\"input-group-prepend\"><select class=\"form-select\" aria-label=\"Default select example\"><option selected>Search By-</option><option value=\"1\">Category</option><option value=\"2\">Product Name </option></select></div><input type=\"text\" class=\"form-control w-25\" placeholder=\"Enter Search Key !\"><span class=\"input-group-text\" id=\"basic-addon1\">From</span><input type=\"date\" class=\"form-control\" placeholder=\"Enter Search Key !\"><span class=\"input-group-text\" id=\"basic-addon1\">To</span><input type=\"date\" class=\"form-control\" placeholder=\"Enter Search Key !\"><button type=\"button\" class=\"btn btn-primary me-1 ms-1\">Search</button><button type=\"button\" class=\"btn btn-default border\">Clear</button></div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
              _createElementVNode("div", {
                class: "spinner-border text-primary",
                role: "status"
              }, [
                _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
              ], -1)
            ])))
          : (_openBlock(), _createElementBlock("table", _hoisted_4, [
              _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "Id"),
                  _createElementVNode("th", null, "Invoice No"),
                  _createElementVNode("th", null, "Due"),
                  _createElementVNode("th", null, "Paid"),
                  _createElementVNode("th", null, "Profit"),
                  _createElementVNode("th", null, "Action")
                ])
              ], -1)),
              _createElementVNode("tbody", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: dataItem.id
                  }, [
                    _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                    _createElementVNode("td", null, _toDisplayString(dataItem.type), 1),
                    _createElementVNode("td", null, _toDisplayString(dataItem.quantity), 1),
                    _createElementVNode("td", null, _toDisplayString(dataItem.store?.name), 1),
                    _createElementVNode("td", null, _toDisplayString(dataItem.user?.firstname) + " " + _toDisplayString(dataItem.user?.lastname) + " - " + _toDisplayString(dataItem.user?.type), 1),
                    _createElementVNode("td", null, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "me-2 btn btn-sm btn-warning",
                        onClick: ($event: any) => (_ctx.editItem(dataItem.id.toString()))
                      }, [
                        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'pen-to-square'] })
                      ], 8, _hoisted_6),
                      _createElementVNode("button", {
                        type: "button",
                        class: "me-2 btn btn-sm btn-danger",
                        "data-bs-toggle": "modal",
                        "data-bs-target": "#modalCenter",
                        onClick: ($event: any) => (_ctx.fetchItem(dataItem))
                      }, [
                        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'trash-can'] })
                      ], 8, _hoisted_7)
                    ])
                  ]))
                }), 128))
              ])
            ]))
      ])
    ]),
    _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"card\"><div class=\"card-body d-flex justify-content-center\"><nav aria-label=\"Page navigation\"><ul class=\"pagination mb-0\"><li class=\"page-item first\"><a class=\"page-link\" href=\"javascript:void(0);\"><i class=\"tf-icon bx bx-chevrons-left\"></i></a></li><li class=\"page-item prev\"><a class=\"page-link\" href=\"javascript:void(0);\"><i class=\"tf-icon bx bx-chevron-left\"></i></a></li><li class=\"page-item active\"><a class=\"page-link\" href=\"javascript:void(0);\">1</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"javascript:void(0);\">2</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"javascript:void(0);\">3</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"javascript:void(0);\">4</a></li><li class=\"page-item\"><a class=\"page-link\" href=\"javascript:void(0);\">5</a></li><li class=\"page-item next\"><a class=\"page-link\" href=\"javascript:void(0);\"><i class=\"tf-icon bx bx-chevron-right\"></i></a></li><li class=\"page-item last\"><a class=\"page-link\" href=\"javascript:void(0);\"><i class=\"tf-icon bx bx-chevrons-right\"></i></a></li></ul></nav></div></div>", 1)),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("h5", {
              class: "modal-title text-center",
              id: "modalCenterTitle"
            }, "Delete Confirmation !"),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_11, [
            _cache[4] || (_cache[4] = _createElementVNode("i", { class: "bx bx-bell bx-lg text-danger mb-3 d-inline-block" }, null, -1)),
            _cache[5] || (_cache[5] = _createElementVNode("p", null, "Are You Sure to Delete ?", -1)),
            _createElementVNode("p", null, [
              _createElementVNode("b", null, _toDisplayString(_ctx.deletevalue.name), 1),
              _cache[3] || (_cache[3] = _createTextVNode(" Page !"))
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[6] || (_cache[6] = _createElementVNode("button", {
              type: "button",
              class: "btn btn-outline-secondary",
              "data-bs-dismiss": "modal"
            }, " Close ", -1)),
            _createElementVNode("button", {
              "data-bs-dismiss": "modal",
              type: "button",
              class: "btn btn-danger",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteItem(_ctx.deletevalue.id)))
            }, "Confirm")
          ])
        ])
      ])
    ])
  ]))
}