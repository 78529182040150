import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, vModelRadio as _vModelRadio, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "position-fixed d-block d-md-none",
  style: {"z-index":"999","right":"20px","top":"90px"}
}
const _hoisted_2 = {
  class: "nav-link dropdown-toggle hide-arrow",
  href: "#addtocard"
}
const _hoisted_3 = { class: "badge bg-danger rounded-pill badge-notifications" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-6" }
const _hoisted_6 = { class: "card mb-1" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "d-flex justify-content-between" }
const _hoisted_9 = { class: "w-100" }
const _hoisted_10 = { class: "input-group pe-2" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "card" }
const _hoisted_13 = { class: "card-body table-responsive" }
const _hoisted_14 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "d-md-block d-none" }
const _hoisted_17 = {
  key: 0,
  class: "table card-table"
}
const _hoisted_18 = { class: "table-border-bottom-0" }
const _hoisted_19 = { class: "mb-0" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = {
  key: 0,
  class: "text-danger text-capitalize"
}
const _hoisted_23 = {
  key: 1,
  class: "text-danger text-capitalize"
}
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "d-md-none d-block" }
const _hoisted_27 = { class: "" }
const _hoisted_28 = { class: "mb-0" }
const _hoisted_29 = { class: "mb-0 fw-thin" }
const _hoisted_30 = { class: "" }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { key: 1 }
const _hoisted_33 = {
  key: 0,
  class: "text-danger text-capitalize"
}
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { class: "col-md-6" }
const _hoisted_36 = {
  class: "card mb-1 mt-sm-0 mt-3",
  id: "addtocard"
}
const _hoisted_37 = { class: "card-body" }
const _hoisted_38 = {
  class: "nav nav-pills nav-fill flex-nowrap",
  id: "pillNav2",
  role: "tablist"
}
const _hoisted_39 = {
  class: "tab-content py-4 px-0",
  id: "myTabContent"
}
const _hoisted_40 = {
  class: "tab-pane fade show active",
  id: "walk-in-customer",
  role: "tabpanel",
  "aria-labelledby": "walk-tab"
}
const _hoisted_41 = { key: 0 }
const _hoisted_42 = { class: "d-flex flex-sm-nowrap flex-wrap justify-content-between" }
const _hoisted_43 = { class: "mb-3 me-3 w-100" }
const _hoisted_44 = ["required"]
const _hoisted_45 = {
  key: 0,
  class: "form-control suggestions-list"
}
const _hoisted_46 = ["onClick"]
const _hoisted_47 = { class: "mb-3 me-3 w-100" }
const _hoisted_48 = { class: "d-flex flex-sm-nowrap flex-wrap justify-content-between" }
const _hoisted_49 = { class: "mb-3 me-3 w-100" }
const _hoisted_50 = {
  class: "tab-pane fade",
  id: "registered-tab-pane",
  role: "tabpanel",
  "aria-labelledby": "registered-tab"
}
const _hoisted_51 = { key: 0 }
const _hoisted_52 = { class: "d-flex flex-sm-nowrap flex-wrap justify-content-between" }
const _hoisted_53 = { class: "mb-3 me-3 w-100" }
const _hoisted_54 = ["required"]
const _hoisted_55 = {
  key: 0,
  class: "form-control suggestions-list"
}
const _hoisted_56 = ["onClick"]
const _hoisted_57 = { class: "mb-3 me-3 w-100" }
const _hoisted_58 = { class: "d-flex flex-sm-nowrap flex-wrap justify-content-between" }
const _hoisted_59 = { class: "mb-3 me-3 w-100" }
const _hoisted_60 = { class: "card" }
const _hoisted_61 = { class: "" }
const _hoisted_62 = { class: "d-md-flex d-none" }
const _hoisted_63 = { class: "card-body table-responsive" }
const _hoisted_64 = {
  key: 0,
  class: "table card-table"
}
const _hoisted_65 = { class: "table-border-bottom-0" }
const _hoisted_66 = { class: "input-group" }
const _hoisted_67 = {
  key: 0,
  class: "form-control-sm mx-0"
}
const _hoisted_68 = {
  key: 1,
  class: "form-control-sm mx-0"
}
const _hoisted_69 = ["onUpdate:modelValue", "onInput"]
const _hoisted_70 = ["onClick"]
const _hoisted_71 = {
  key: 1,
  class: "text-center fw-bold"
}
const _hoisted_72 = { class: "mobile-view d-block d-md-none" }
const _hoisted_73 = {
  key: 0,
  class: "card-body"
}
const _hoisted_74 = { class: "" }
const _hoisted_75 = { class: "input-group" }
const _hoisted_76 = {
  key: 0,
  class: ""
}
const _hoisted_77 = {
  key: 1,
  class: ""
}
const _hoisted_78 = ["onUpdate:modelValue", "onInput"]
const _hoisted_79 = { key: 0 }
const _hoisted_80 = { key: 1 }
const _hoisted_81 = ["onClick"]
const _hoisted_82 = {
  key: 1,
  class: "card-body"
}
const _hoisted_83 = { class: "card mt-1" }
const _hoisted_84 = { class: "card-body table-responsive my-0 d-md-flex d-none" }
const _hoisted_85 = { class: "table card-table" }
const _hoisted_86 = { class: "table-border-bottom-0 text-end" }
const _hoisted_87 = { class: "form-group" }
const _hoisted_88 = { class: "input-group input-group-sm w-100" }
const _hoisted_89 = { key: 0 }
const _hoisted_90 = { class: "card-body table-responsive my-0 d-md-none d-block" }
const _hoisted_91 = { key: 0 }
const _hoisted_92 = { class: "card mt-1" }
const _hoisted_93 = { class: "card-body" }
const _hoisted_94 = { class: "row" }
const _hoisted_95 = { class: "col-md-8 col-sm-12 text-end" }
const _hoisted_96 = {
  href: "",
  class: "btn btn-default border m-1"
}
const _hoisted_97 = ["disabled"]
const _hoisted_98 = { key: 0 }
const _hoisted_99 = {
  key: 1,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_100 = {
  key: 2,
  class: "visually-show"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.totalQuantity > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", _hoisted_2, [
            _cache[32] || (_cache[32] = _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "30",
              height: "30",
              fill: "currentColor",
              class: "bi bi-cart4",
              viewBox: "0 0 16 16"
            }, [
              _createElementVNode("path", { d: "M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" })
            ], -1)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.totalQuantity), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    placeholder: "Enter Search Key!",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchKey) = $event))
                  }, null, 512), [
                    [_vModelText, _ctx.searchKey]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary me-2 mt-sm-0 mt-2",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.searchProductData && _ctx.searchProductData(...args)))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'magnifying-glass'] })
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-default border mt-sm-0 mt-2",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearSearchKey && _ctx.clearSearchKey(...args)))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'xmark'] })
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[33] || (_cache[33] = [
                  _createElementVNode("div", {
                    class: "spinner-border text-primary",
                    role: "status"
                  }, [
                    _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                  ], -1)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    (_ctx.fettchDataArray.length > 0)
                      ? (_openBlock(), _createElementBlock("table", _hoisted_17, [
                          _cache[34] || (_cache[34] = _createElementVNode("thead", null, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, "Id"),
                              _createElementVNode("th", null, "Name"),
                              _createElementVNode("th", null, "Price"),
                              _createElementVNode("th", null, "In Stock"),
                              _createElementVNode("th", null, "#")
                            ])
                          ], -1)),
                          _createElementVNode("tbody", _hoisted_18, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem, index) => {
                              return (_openBlock(), _createElementBlock("tr", {
                                key: dataItem.id
                              }, [
                                _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                                _createElementVNode("td", null, [
                                  _createElementVNode("p", _hoisted_19, _toDisplayString(dataItem.name), 1)
                                ]),
                                _createElementVNode("td", null, " P: " + _toDisplayString(dataItem.buy_price) + " | W: " + _toDisplayString(dataItem.wholesale_price) + " | S: " + _toDisplayString(dataItem.sell_price), 1),
                                _createElementVNode("td", null, [
                                  (dataItem.stocks[0]?.total_quantity)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(dataItem.stocks[0]?.total_quantity), 1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_21, "00 "))
                                ]),
                                _createElementVNode("td", null, [
                                  (dataItem.stocks[0]?.total_quantity == null)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_22, "Add Stock"))
                                    : (parseInt(dataItem.stocks[0]?.total_quantity) <= 0)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_23, " Stock Out "))
                                      : (_openBlock(), _createElementBlock("button", {
                                          key: 2,
                                          type: "button",
                                          class: "me-2 btn btn-sm btn-primary",
                                          onClick: ($event: any) => (_ctx.addItemToCart(dataItem))
                                        }, [
                                          _createVNode(_component_font_awesome_icon, { icon: ['fas', 'plus'] })
                                        ], 8, _hoisted_24))
                                ])
                              ]))
                            }), 128))
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[35] || (_cache[35] = [
                          _createElementVNode("h5", { class: "m-0 text-center" }, " No Item Found", -1)
                        ])))
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "mb-4",
                        key: dataItem.id
                      }, [
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("strong", _hoisted_28, "Name: " + _toDisplayString(dataItem.name), 1),
                          _createElementVNode("small", null, " (" + _toDisplayString(dataItem.category?.name) + ") ", 1),
                          _createElementVNode("p", _hoisted_29, [
                            _cache[36] || (_cache[36] = _createElementVNode("strong", null, "Sell ", -1)),
                            _cache[37] || (_cache[37] = _createTextVNode("/ Buy ")),
                            _cache[38] || (_cache[38] = _createElementVNode("strong", null, "Price:", -1)),
                            _createTextVNode(" P: " + _toDisplayString(dataItem.buy_price) + " | W: " + _toDisplayString(dataItem.wholesale_price) + " | S: " + _toDisplayString(dataItem.sell_price), 1)
                          ]),
                          _createElementVNode("p", _hoisted_30, [
                            (dataItem.stocks[0]?.total_quantity)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_31, "Qantity: " + _toDisplayString(dataItem.stocks[0]?.total_quantity), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_32, "00"))
                          ]),
                          (parseInt(dataItem.stocks[0]?.total_quantity) <= 0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_33, "Out of Stock"))
                            : (_openBlock(), _createElementBlock("button", {
                                key: 1,
                                type: "button",
                                class: "me-2 btn btn-sm btn-primary",
                                onClick: ($event: any) => (_ctx.addItemToCart(dataItem))
                              }, [
                                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'plus'] })
                              ], 8, _hoisted_34))
                        ]),
                        _cache[39] || (_cache[39] = _createElementVNode("hr", null, null, -1))
                      ]))
                    }), 128))
                  ])
                ]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_35, [
        _createElementVNode("form", {
          onSubmit: _cache[31] || (_cache[31] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addOrderFunc && _ctx.addOrderFunc(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("button", {
                    class: "nav-link active rounded-5",
                    id: "walk-tab",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.saleForm.customer_type = 'WHOLESALE')),
                    "data-bs-toggle": "pill",
                    "data-bs-target": "#walk-in-customer",
                    type: "button",
                    role: "tab",
                    "aria-selected": "true"
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input d-none",
                      type: "radio",
                      name: "customer_type",
                      id: "whole_sale",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.saleForm.customer_type) = $event)),
                      value: "WHOLESALE",
                      checked: ""
                    }, null, 512), [
                      [_vModelRadio, _ctx.saleForm.customer_type]
                    ]),
                    _cache[40] || (_cache[40] = _createElementVNode("label", {
                      class: "form-check-label w-100",
                      for: "whole_sale"
                    }, " Wholesale ", -1))
                  ]),
                  _createElementVNode("button", {
                    class: "nav-link rounded-5",
                    id: "registered-tab",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.saleForm.customer_type = 'REGULAR')),
                    "data-bs-toggle": "pill",
                    "data-bs-target": "#registered-tab-pane",
                    type: "button",
                    role: "tab",
                    "aria-selected": "false"
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input d-none",
                      type: "radio",
                      name: "customer_type",
                      id: "regular",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.saleForm.customer_type) = $event)),
                      value: "REGULAR"
                    }, null, 512), [
                      [_vModelRadio, _ctx.saleForm.customer_type]
                    ]),
                    _cache[41] || (_cache[41] = _createElementVNode("label", {
                      class: "form-check-label w-100",
                      for: "regular"
                    }, " Regular ", -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("div", _hoisted_40, [
                    (_ctx.registrationMessage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_41, _cache[42] || (_cache[42] = [
                          _createElementVNode("p", { class: "text-danger text-wrap" }, " Please Enter the Registered Customer To Manage the Due Amount", -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_42, [
                      _createElementVNode("div", _hoisted_43, [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          placeholder: "Store Phone",
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.saleForm.customer.phone) = $event)),
                          onInput: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.searchCustomer && _ctx.searchCustomer(...args))),
                          required: _ctx.saleForm.customer_type == 'WHOLESALE'
                        }, null, 40, _hoisted_44), [
                          [_vModelText, _ctx.saleForm.customer.phone]
                        ]),
                        (_ctx.suggestions.length)
                          ? (_openBlock(), _createElementBlock("ul", _hoisted_45, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestions, (customer) => {
                                return (_openBlock(), _createElementBlock("li", {
                                  key: customer.id,
                                  onClick: ($event: any) => (_ctx.selectCustomer(customer)),
                                  class: "p-2"
                                }, _toDisplayString(customer.phone), 9, _hoisted_46))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_47, [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          id: "name",
                          placeholder: "Store Name",
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.saleForm.customer.name) = $event)),
                          onInput: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.searchCustomer && _ctx.searchCustomer(...args)))
                        }, null, 544), [
                          [_vModelText, _ctx.saleForm.customer.name]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_48, [
                      _createElementVNode("div", _hoisted_49, [
                        _withDirectives(_createElementVNode("textarea", {
                          type: "text",
                          class: "form-control",
                          id: "name",
                          placeholder: "Store Address",
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.saleForm.customer.address) = $event)),
                          rows: "3"
                        }, null, 512), [
                          [_vModelText, _ctx.saleForm.customer.address]
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_50, [
                    (_ctx.registrationMessage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_51, _cache[43] || (_cache[43] = [
                          _createElementVNode("p", { class: "text-danger text-wrap" }, " Please Enter the Registered Customer To Manage the Due Amount", -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_52, [
                      _createElementVNode("div", _hoisted_53, [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          placeholder: "Customer Phone",
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.saleForm.customer.phone) = $event)),
                          onInput: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.searchCustomer && _ctx.searchCustomer(...args))),
                          required: _ctx.saleForm.customer_type == 'WHOLESALE'
                        }, null, 40, _hoisted_54), [
                          [_vModelText, _ctx.saleForm.customer.phone]
                        ]),
                        (_ctx.suggestions.length)
                          ? (_openBlock(), _createElementBlock("ul", _hoisted_55, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestions, (customer) => {
                                return (_openBlock(), _createElementBlock("li", {
                                  key: customer.id,
                                  onClick: ($event: any) => (_ctx.selectCustomer(customer)),
                                  class: "p-2"
                                }, [
                                  _createTextVNode(_toDisplayString(customer.phone) + " " + _toDisplayString(customer.firstname) + " ", 1),
                                  _createElementVNode("small", null, _toDisplayString(customer.firstname) + " " + _toDisplayString(customer.lastname), 1)
                                ], 8, _hoisted_56))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_57, [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "form-control",
                          id: "name",
                          placeholder: "Customer Name",
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.saleForm.customer.name) = $event)),
                          onInput: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.searchCustomer && _ctx.searchCustomer(...args)))
                        }, null, 544), [
                          [_vModelText, _ctx.saleForm.customer.name]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_58, [
                      _createElementVNode("div", _hoisted_59, [
                        _withDirectives(_createElementVNode("textarea", {
                          type: "text",
                          class: "form-control",
                          id: "name",
                          placeholder: "Customer Address",
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.saleForm.customer.address) = $event)),
                          rows: "3"
                        }, null, 512), [
                          [_vModelText, _ctx.saleForm.customer.address]
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_60, [
            _createElementVNode("div", _hoisted_61, [
              _createElementVNode("div", _hoisted_62, [
                _createElementVNode("div", _hoisted_63, [
                  (_ctx.cartItems.length > 0)
                    ? (_openBlock(), _createElementBlock("table", _hoisted_64, [
                        _cache[45] || (_cache[45] = _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Sl."),
                            _createElementVNode("th", null, "Product"),
                            _createElementVNode("th", null, "Price x Qty"),
                            _createElementVNode("th", null, "Total"),
                            _createElementVNode("th", null, "-")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", _hoisted_65, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartItems, (item, index) => {
                            return (_openBlock(), _createElementBlock("tr", { key: index }, [
                              _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                              _createElementVNode("td", null, _toDisplayString(item.product), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_66, [
                                  (_ctx.saleForm.customer_type == 'WHOLESALE')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_67, _toDisplayString(item.wholesale_price), 1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_68, _toDisplayString(item.price), 1)),
                                  _cache[44] || (_cache[44] = _createElementVNode("span", { class: "form-control-sm mx-0" }, "X", -1)),
                                  _withDirectives(_createElementVNode("input", {
                                    type: "number",
                                    class: "form-control form-control-sm w-50",
                                    min: "1",
                                    "onUpdate:modelValue": ($event: any) => ((item.quantity) = $event),
                                    onInput: ($event: any) => (_ctx.calculateItemPriceByQuantity(index))
                                  }, null, 40, _hoisted_69), [
                                    [_vModelText, item.quantity]
                                  ])
                                ])
                              ]),
                              _createElementVNode("td", null, _toDisplayString(parseFloat(item.total.toString()).toFixed(2)), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-danger",
                                  onClick: ($event: any) => (_ctx.removeFromCart(index))
                                }, " X ", 8, _hoisted_70)
                              ])
                            ]))
                          }), 128))
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_71, _cache[46] || (_cache[46] = [
                        _createElementVNode("h6", { class: "m-0" }, "Please Add Some Product", -1)
                      ])))
                ])
              ]),
              _createElementVNode("div", _hoisted_72, [
                (_ctx.cartItems.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartItems, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "",
                          key: index
                        }, [
                          _createElementVNode("div", _hoisted_74, [
                            _createElementVNode("strong", null, "Name: " + _toDisplayString(item.product), 1),
                            _createElementVNode("div", _hoisted_75, [
                              (_ctx.saleForm.customer_type == 'WHOLESALE')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_76, "Price: " + _toDisplayString(item.wholesale_price) + " Buy Price: " + _toDisplayString(item.buy_price), 1))
                                : _createCommentVNode("", true),
                              _cache[47] || (_cache[47] = _createTextVNode("   ")),
                              _cache[48] || (_cache[48] = _createElementVNode("strong", null, " X ", -1)),
                              _cache[49] || (_cache[49] = _createTextVNode("  ")),
                              (_ctx.saleForm.customer_type == 'REGULAR')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_77, " Price: " + _toDisplayString(item.price) + " Buy Price: " + _toDisplayString(item.buy_price), 1))
                                : _createCommentVNode("", true),
                              _cache[50] || (_cache[50] = _createElementVNode("span", { class: "" }, " Quantity:", -1)),
                              _withDirectives(_createElementVNode("input", {
                                type: "number",
                                class: "form-control form-control-sm ms-2",
                                min: "1",
                                "onUpdate:modelValue": ($event: any) => ((item.quantity) = $event),
                                onInput: ($event: any) => (_ctx.calculateItemPriceByQuantity(index))
                              }, null, 40, _hoisted_78), [
                                [_vModelText, item.quantity]
                              ])
                            ]),
                            (_ctx.saleForm.customer_type == 'WHOLESALE')
                              ? (_openBlock(), _createElementBlock("p", _hoisted_79, "Total: " + _toDisplayString(parseFloat(item.wholesale_total.toString()).toFixed(2)), 1))
                              : (_openBlock(), _createElementBlock("p", _hoisted_80, "Total: " + _toDisplayString(parseFloat(item.total.toString()).toFixed(2)), 1)),
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-sm btn-danger",
                              onClick: ($event: any) => (_ctx.removeFromCart(index))
                            }, [
                              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'circle-xmark'] })
                            ], 8, _hoisted_81)
                          ]),
                          _cache[51] || (_cache[51] = _createElementVNode("hr", null, null, -1))
                        ]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_82, _cache[52] || (_cache[52] = [
                      _createElementVNode("h6", { class: "text-center fw-bold m-0 p-0" }, "Please Add Some Product", -1)
                    ])))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_83, [
            _createElementVNode("div", _hoisted_84, [
              _createElementVNode("table", _hoisted_85, [
                _createElementVNode("tbody", _hoisted_86, [
                  _createElementVNode("tr", null, [
                    _cache[53] || (_cache[53] = _createElementVNode("th", { class: "col-8" }, "Total", -1)),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        class: "form-control form-control-sm",
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.saleForm.total) = $event)),
                        min: "0",
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, _ctx.saleForm.total]
                      ])
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[54] || (_cache[54] = _createElementVNode("th", null, "Discount (%)", -1)),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        class: "form-control form-control-sm",
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.saleForm.discount) = $event)),
                        min: "0",
                        onInput: _cache[19] || (_cache[19] = ($event: any) => (_ctx.calculateSubTotal()))
                      }, null, 544), [
                        [_vModelText, _ctx.saleForm.discount]
                      ])
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[55] || (_cache[55] = _createElementVNode("th", null, "Sub Total", -1)),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        class: "form-control form-control-sm",
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.saleForm.sub_total) = $event)),
                        min: "0",
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, _ctx.saleForm.sub_total]
                      ])
                    ])
                  ]),
                  _createElementVNode("tr", null, [
                    _cache[57] || (_cache[57] = _createElementVNode("th", null, "Payment", -1)),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_87, [
                        _createElementVNode("div", _hoisted_88, [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            class: "form-control form-control-sm w-50",
                            onInput: _cache[21] || (_cache[21] = ($event: any) => (_ctx.calculateChangeAmount())),
                            placeholder: "Enter Payment",
                            "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.saleForm.payment) = $event)),
                            min: "0"
                          }, null, 544), [
                            [_vModelText, _ctx.saleForm.payment]
                          ]),
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            class: "form-control form-control-sm",
                            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.change_amount) = $event)),
                            readonly: ""
                          }, null, 512), [
                            [_vModelText, _ctx.change_amount]
                          ])
                        ]),
                        (_ctx.inputValidity)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_89, _cache[56] || (_cache[56] = [
                              _createElementVNode("small", { class: "text-danger" }, "Please Enter Valid Amount", -1)
                            ])))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_90, [
              _createElementVNode("p", null, [
                _cache[58] || (_cache[58] = _createTextVNode("Total: ")),
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  class: "form-control form-control-sm",
                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.saleForm.total) = $event)),
                  min: "0",
                  readonly: ""
                }, null, 512), [
                  [_vModelText, _ctx.saleForm.total]
                ])
              ]),
              _createElementVNode("p", null, [
                _cache[59] || (_cache[59] = _createTextVNode("Discount (%) ")),
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  class: "form-control form-control-sm",
                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.saleForm.discount) = $event)),
                  min: "0",
                  onInput: _cache[26] || (_cache[26] = ($event: any) => (_ctx.calculateSubTotal()))
                }, null, 544), [
                  [_vModelText, _ctx.saleForm.discount]
                ])
              ]),
              _createElementVNode("p", null, [
                _cache[60] || (_cache[60] = _createTextVNode("Sub Total: ")),
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  class: "form-control form-control-sm",
                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.saleForm.sub_total) = $event)),
                  min: "0",
                  readonly: ""
                }, null, 512), [
                  [_vModelText, _ctx.saleForm.sub_total]
                ])
              ]),
              _createElementVNode("p", null, [
                _cache[61] || (_cache[61] = _createTextVNode("Payment: ")),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "form-control form-control-sm",
                  onInput: _cache[28] || (_cache[28] = ($event: any) => (_ctx.calculateChangeAmount())),
                  placeholder: "Enter Payment",
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.saleForm.payment) = $event)),
                  min: "0"
                }, null, 544), [
                  [_vModelText, _ctx.saleForm.payment]
                ])
              ]),
              _createElementVNode("p", null, [
                _cache[62] || (_cache[62] = _createTextVNode("Due: ")),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "form-control form-control-sm",
                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.change_amount) = $event)),
                  readonly: ""
                }, null, 512), [
                  [_vModelText, _ctx.change_amount]
                ])
              ]),
              (_ctx.inputValidity)
                ? (_openBlock(), _createElementBlock("div", _hoisted_91, _cache[63] || (_cache[63] = [
                    _createElementVNode("small", { class: "text-danger" }, "Please Enter Valid Amount", -1)
                  ])))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_92, [
            _createElementVNode("div", _hoisted_93, [
              _createElementVNode("div", _hoisted_94, [
                _cache[64] || (_cache[64] = _createElementVNode("div", { class: "col-md-4 col-sm-12" }, null, -1)),
                _createElementVNode("div", _hoisted_95, [
                  _createElementVNode("a", _hoisted_96, [
                    _createVNode(_component_font_awesome_icon, { icon: ['fas', 'broom'] })
                  ]),
                  _createElementVNode("button", {
                    type: "submit",
                    class: "btn btn-primary m-1",
                    disabled: _ctx.submitBtn || _ctx.cartItems.length <= 0
                  }, [
                    (!_ctx.submitBtn)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_98, "Confirm"))
                      : _createCommentVNode("", true),
                    (_ctx.submitBtn)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_99))
                      : _createCommentVNode("", true),
                    (_ctx.submitBtn)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_100, " Selling..."))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_97)
                ])
              ])
            ])
          ])
        ], 32)
      ])
    ])
  ], 64))
}