import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "layout-page" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "flex-grow-1 container-p-y px-4" }
const _hoisted_4 = { class: "d-flex justify-content-between aling-items-center" }
const _hoisted_5 = { class: "fw-bold py-3 mb-4" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = {
  key: 0,
  class: ""
}
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "text-nowrap" }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBarView = _resolveComponent("TopBarView")!
  const _component_List = _resolveComponent("List")!
  const _component_Summary = _resolveComponent("Summary")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopBarView),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("h4", _hoisted_5, [
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-muted fw-light" }, "Stock", -1)),
                  (_ctx.listShow)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "List"))
                    : _createCommentVNode("", true),
                  (_ctx.summary)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Summary"))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", null, [
                (_ctx.summary)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-primary mt-2",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleComponent && _ctx.toggleComponent(...args)))
                    }, "Back to List"))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                (_ctx.listShow)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_List)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.summary)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(_component_Summary)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ]),
      _createVNode(_component_Footer),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "content-backdrop fade" }, null, -1))
    ])
  ]))
}