import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  class: "content-wrapper d-flex align-items-center",
  style: {"min-height":"100vh","width":"100%"}
}
const _hoisted_3 = { class: "w-100" }
const _hoisted_4 = { class: "flex-grow-1 container-p-y px-4" }
const _hoisted_5 = { class: "col-md-9 col-sm-12 mx-auto" }
const _hoisted_6 = { class: "row h-100" }
const _hoisted_7 = { class: "col-md-6 mx-auto" }
const _hoisted_8 = { class: "h-100" }
const _hoisted_9 = { class: "d-flex align-items-center justify-content-center h-100" }
const _hoisted_10 = { class: "w-100" }
const _hoisted_11 = { class: "authentication-wrapper authentication-basic container-p-y" }
const _hoisted_12 = { class: "authentication-inner w-100" }
const _hoisted_13 = { class: "card" }
const _hoisted_14 = { class: "card-body" }
const _hoisted_15 = {
  key: 0,
  class: "alert alert-danger alert-dismissible",
  role: "alert"
}
const _hoisted_16 = {
  key: 1,
  class: "alert alert-success alert-dismissible",
  role: "alert"
}
const _hoisted_17 = { class: "mb-3" }
const _hoisted_18 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_19 = { class: "mb-3" }
const _hoisted_20 = { class: "mb-3" }
const _hoisted_21 = { class: "mb-3" }
const _hoisted_22 = { class: "mb-3" }
const _hoisted_23 = { class: "mb-3" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "col-12" }
const _hoisted_26 = ["disabled"]
const _hoisted_27 = { key: 0 }
const _hoisted_28 = {
  key: 1,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_29 = {
  key: 2,
  class: "visually-show"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeLect2 = _resolveComponent("SeLect2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", _hoisted_14, [
                                _cache[15] || (_cache[15] = _createElementVNode("div", { class: "app-brand justify-content-center" }, [
                                  _createElementVNode("a", {
                                    href: "/",
                                    class: "app-brand-link gap-2"
                                  }, [
                                    _createElementVNode("span", { class: "app-brand-text demo text-body fw-bold text-uppercase mb-3" }, [
                                      _createElementVNode("img", {
                                        src: "https://pos-file.phicsart.com/profile_user.png",
                                        alt: "n/a",
                                        class: "img-fluid",
                                        style: {"width":"80px","height":"80px"}
                                      })
                                    ])
                                  ])
                                ], -1)),
                                _createElementVNode("span", null, _toDisplayString(_ctx.cardData), 1),
                                _cache[16] || (_cache[16] = _createElementVNode("h4", { class: "mb-4 text-center" }, "Raida Trade International — Customer Info 👋", -1)),
                                (_ctx.errorMessages.message)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                      _createTextVNode(_toDisplayString(_ctx.errorMessages.message) + " ", 1),
                                      _cache[7] || (_cache[7] = _createElementVNode("button", {
                                        type: "button",
                                        class: "btn-close",
                                        "aria-label": "Close"
                                      }, null, -1))
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.successReport)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[8] || (_cache[8] = [
                                      _createTextVNode(" Successfully "),
                                      _createElementVNode("button", {
                                        type: "button",
                                        class: "btn-close",
                                        "aria-label": "Close"
                                      }, null, -1)
                                    ])))
                                  : _createCommentVNode("", true),
                                _createElementVNode("form", {
                                  id: "formAuthentication",
                                  class: "mb-3",
                                  onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.handleSubmit()), ["prevent"]))
                                }, [
                                  _createElementVNode("div", _hoisted_17, [
                                    _cache[9] || (_cache[9] = _createElementVNode("label", {
                                      for: "phone",
                                      class: "form-label"
                                    }, [
                                      _createTextVNode("Phone Number "),
                                      _createElementVNode("sup", { class: "text-danger" }, "*")
                                    ], -1)),
                                    _cache[10] || (_cache[10] = _createTextVNode()),
                                    (_ctx.invalidPhone)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, "Phone number must be 11 digits"))
                                      : _createCommentVNode("", true),
                                    _withDirectives(_createElementVNode("input", {
                                      type: "text",
                                      class: "form-control",
                                      id: "phone",
                                      name: "phone",
                                      placeholder: "Enter your Phone ",
                                      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkUser && _ctx.checkUser(...args))),
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.phone) = $event)),
                                      required: ""
                                    }, null, 544), [
                                      [_vModelText, _ctx.formData.phone]
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_19, [
                                    _cache[11] || (_cache[11] = _createElementVNode("label", {
                                      for: "firstname",
                                      class: "form-label"
                                    }, [
                                      _createTextVNode("Name "),
                                      _createElementVNode("sup", { class: "text-danger" }, "*")
                                    ], -1)),
                                    _withDirectives(_createElementVNode("input", {
                                      type: "text",
                                      class: "form-control",
                                      id: "firstname",
                                      name: "firstname",
                                      placeholder: "Enter your Name",
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.firstname) = $event))
                                    }, null, 512), [
                                      [_vModelText, _ctx.formData.firstname]
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_20, [
                                    _cache[12] || (_cache[12] = _createElementVNode("label", {
                                      for: "email",
                                      class: "form-label"
                                    }, "Email ", -1)),
                                    _withDirectives(_createElementVNode("input", {
                                      type: "Email",
                                      class: "form-control",
                                      id: "email",
                                      name: "email",
                                      placeholder: "Enter your Email",
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.email) = $event))
                                    }, null, 512), [
                                      [_vModelText, _ctx.formData.email]
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_21, [
                                    _cache[13] || (_cache[13] = _createElementVNode("label", {
                                      class: "form-label",
                                      for: "store"
                                    }, "Store ", -1)),
                                    _createVNode(_component_SeLect2, {
                                      modelValue: _ctx.formData.store_id,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.store_id) = $event)),
                                      required: "",
                                      placeholder: 'Select Store',
                                      options: _ctx.fetchCategoryList,
                                      class: "w-100 my-select",
                                      onChange: _ctx.myChangeEvent,
                                      onSelect: _ctx.mySelectEvent,
                                      multiple: false,
                                      "aria-required": "true"
                                    }, null, 8, ["modelValue", "options", "onChange", "onSelect"])
                                  ]),
                                  _createElementVNode("div", _hoisted_22, [
                                    _cache[14] || (_cache[14] = _createElementVNode("label", {
                                      for: "address",
                                      class: "form-label"
                                    }, "Address ", -1)),
                                    _withDirectives(_createElementVNode("textarea", {
                                      class: "form-control",
                                      placeholder: "Enter your Address ...",
                                      rows: "3",
                                      id: "address",
                                      name: "address",
                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.address) = $event))
                                    }, null, 512), [
                                      [_vModelText, _ctx.formData.address]
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_23, [
                                    _createElementVNode("div", _hoisted_24, [
                                      _createElementVNode("div", _hoisted_25, [
                                        _createElementVNode("button", {
                                          type: "submit",
                                          class: "btn btn-primary w-100 justify-content-center btn-lg",
                                          disabled: _ctx.submitBtn
                                        }, [
                                          (!_ctx.submitBtn)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_27, "Active Warranty"))
                                            : _createCommentVNode("", true),
                                          (_ctx.submitBtn)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_28))
                                            : _createCommentVNode("", true),
                                          (_ctx.submitBtn)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_29, " Saving ...."))
                                            : _createCommentVNode("", true)
                                        ], 8, _hoisted_26)
                                      ])
                                    ])
                                  ])
                                ], 32)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "content-backdrop fade" }, null, -1))
    ])
  ]))
}