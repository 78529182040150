<template>
  <div class="">
   <!-- Basic Layout & Basic with Icons -->
   <div v-if="successReport" class="alert alert-success alert-dismissible" role="alert">
     Successfully
     <button type="button" class="btn-close"  aria-label="Close"></button>
   </div>
   <div class="card d-none d-md-block">
     <div class="card-body" id="invoice-container">
        <!-- <div >
          <div v-for="(dataItem ) in fettchDataArray" :key="dataItem.id" style="float: left; margin: 10px 10px; padding: 10px 0;">
            <svg :ref="el => barcodeRefs[dataItem.id] = el" style="width: 200px; height: 100px;"></svg>
          </div>
        </div> -->
        <table class="table card-table text-center" v-if="fettchDataArray.length > 0">
            <thead>
              <tr>
                <th>Id</th>
                <!-- <th v-if="userData?.type=='SUPERADMIN'">Company</th> -->
                <th>Type</th>
                <th>Quantity</th>
                <th>Unit</th>
                <th>Stock Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(dataItem, index) in fettchDataArray" :key="dataItem.id">
                <td>{{ index + 1 }}</td>
                <!-- <td v-if="userData?.type=='SUPERADMIN'">{{ dataItem.store?.name }}</td> -->
                <td>{{ dataItem.type }}</td>
                <!-- <td> -->
                  <!-- <span v-if="dataItem.stocks[0]?.total_quantity ">{{ dataItem.stocks[0]?.total_quantity }} </span> -->
                  <!-- <span v-else>00</span> -->
                <!-- </td> -->
                <td> <span v-if="dataItem.type == 'IN'"><span class="badge bg-success">{{ dataItem.quantity }} </span></span> <span v-if="dataItem.type == 'OUT'"><span class="badge bg-danger">{{ dataItem.quantity }} </span></span></td>
                <td>{{ dataItem.product.unit }}</td>
                <td> <small> WH- 00 | DE- 00 | ST- 00</small></td>
                <td>
                  <a
                    :href="`https://pos-api.raidatrade.com/api/barcode-by-stock-id/${dataItem.id}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="me-2 btn btn-sm btn-primary"
                  >
                  <!-- //print icon -->
                    <i class="fa fa-print me-1 fs-6"></i>
                    PDF
                  </a>
                  <button class="btn btn-sm btn-secondary">
                    <i class="fa fa-cog me-1 fs-6"></i>
                    More
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
     </div>
   </div>
   <div class="mobile-view d-block d-md-none">
      <div class="card" v-if="loading">
        <div class="card-body">
          <div class="d-flex justify-content-center align-items-center" >
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-4" v-for="dataItem in fettchDataArray" :key="dataItem.id">
        <div class="card-body">
        <!-- <span>{{index+1}}</span><br> -->
        <!-- <strong> Barcode: {{ dataItem.barcode }}</strong><br/> -->
        <small>Remarks: {{ dataItem.remarks }} </small> <br/>
        <!-- <small>Quantity: 
          <span v-if="dataItem.quantity">
            <span v-if="dataItem.quantity >= 0" class="badge bg-success">{{ dataItem.quantity }} {{ dataItem.product.unit }}</span>
            <span v-else class="badge bg-danger">{{ dataItem.quantity }} {{ dataItem.product.unit }}</span> 
          </span>
          <span v-else>00</span>
        </small><br/> -->
        <br>
        <small>Time:{{ formatDateTime(dataItem.created_at) }}</small>
        </div>
      </div>
    </div>
    <!-- <button class="btn btn-primary d-grid w-100 mb-3" data-bs-toggle="offcanvas" data-bs-target="#sendInvoiceOffcanvas" @click="printInvoice('print')">
      <span class="d-flex align-items-center justify-content-center text-nowrap"><i class="bx bx-printer"></i> &nbsp; Print A4</span>
    </button>
    <button class="btn btn-label-secondary btn-default border d-grid w-100 mb-3" @click="downloadInvoice('orderData')">
      <span class="d-flex align-items-center justify-content-center text-nowrap"><i class="bx bx-download"></i> &nbsp; PDF Download</span>
    </button> -->

    <!-- Offcanvas structure -->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
      </div>
    </div>
 </div>
</template>
<script lang="ts">
// import { defineComponent, ref, onMounted, nextTick } from 'vue'
import { defineComponent, ref } from 'vue'
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";
import JsBarcode from 'jsbarcode';
import * as html2pdf from 'html2pdf.js';
import Links from '@/views/Warranty/Components/Links.vue';
interface mappingData {
  id: number;
  type: string;
  quantity: number;
  purchase_price: number | null;
  purchase_invoice: string | null;
  remarks: string | null;
  status: number;
  product_id: number;
  user_id: number;
  supplier_id: number | null;
  created_at: string;
  updated_at: string;
  product: {
    id: number;
    name: string;
    description: string;
    thumb: string | null;
    buy_price: number;
    wholesale_price: number;
    sell_price: number;
    unit: string;
    alert_quantity: number;
    features: string | null;
    user_id: number | null;
    created_at: string;
    updated_at: string;
  };
  user: {
    id: number;
    email: string;
    email_verified_at: string;
    phone: string;
    firstname: string;
    lastname: string;
    profile_img: string;
    national_id_no: string;
    address: string;
    type: string;
    created_at: string;
    updated_at: string;
  };
}

export default defineComponent({
name: "stock-summary", // Rename the component to a multi-word name
components: {
  // 'barcode': VueBarcode
},
 data() {
   const store = useStore();
   const token = store.state.auth.token || localStorage.getItem('token');
   const userId = store.state.auth.userId || localStorage.getItem('userId');
   const storeId = store.state.auth.storeId || localStorage.getItem('storeId');
   let userData = store.state.auth.userData || localStorage.getItem('userData');
    userData = JSON.parse(userData);
    
   return {
       successReport: false,
       storeId,
       itemId: '',
       store,
       userData,
       token,
       userId, 
       fettchDataArray: ref([] as mappingData[]), // Explicitly define the type as Post[] // Explicitly define the type as Post[]
       loading: false,
       formData: {
         id: Number,
         type: '', 
         quantity:'',
         product_id: '',
         user_id:'',
         store_id:'',
         supplier_id:'',
       },
       barcodeRefs: {} as { [key: number]: SVGSVGElement | null },
   }
 },

watch: {
 '$route.params.id': {
   immediate: true,
   handler(newVal) {
     if (newVal) {
       this.fetchData(newVal);
       this.itemId = newVal;
     }
   },
 },
},
methods: {
  formatDateTime(isoString: string | number | Date) {
      const date = new Date(isoString);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        // timeZoneName: 'short',
      };
      return date.toLocaleString(undefined, options);
    },
 async fetchData(product_id: number) {
   try {
    this.loading = true;
     if (!this.token) {
       throw new Error('Token not found');
     }     
     const response = await (axios as any).get(DOAMIN_PATH + `stock`, {
       headers: {
         Authorization: `Bearer ${this.token}`,
        //  userId: this.userId,
       },
       params: {
        //  product_id: product_id,
        //  store_id: this.storeId,
        //  user_id: this.userId,
        product_id: product_id,
       }
     });
     if (response.status === 200) {
       this.fettchDataArray = response.data.data;
       this.loading = false;
      //  this.fettchDataArray.created_at = response.data.data.created_at;
      //     const date = new Date(this.fettchDataArray.created_at);
      //     const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      //   // Assign the formatted date to the data property
      //     this.fettchDataArray.created_at = formattedDate,
      // nextTick(() => {
      //       this.generateBarcodes();
      //     });
     }
   } catch (error) {
     console.error(error);
   }
 },
 async handleSubmit() {
   try {
     if (this.itemId) {
       this.formData.store_id = this.storeId;
       this.formData.user_id = this.userId;
       const response = await (axios as any).put(
         DOAMIN_PATH + 'stock/' + this.itemId,
         this.formData,
         {
           headers: {
             Authorization: `Bearer ${this.token}`,
             userId: this.userId,
             TypeId: this.userData.store[0].type_id,
           }
         }
       );
       if
       (response.status == 204) {
         console.log();
         this.successReport = true;
         setTimeout(() => { this.successReport = false; }, 3000);
       }
     } else {
       this.formData.store_id = this.storeId;
       this.formData.user_id = this.userId;
       const response = await (axios as any).post(DOAMIN_PATH + 'stock', 
        this.formData,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            }
          }
        );
        console.log('_category_respons_', response.data);
        if (response.status == 201) {
          this.formData = {
            id: Number,
            type: '', 
            quantity:'',
            product_id: '',
            supplier_id:'',
            user_id: this.userId,
            store_id: this.storeId,
          };
          this.successReport = true;
          setTimeout(() => { this.successReport = false; }, 3000);
        }
      
     }
   } catch (error) {
     console.error(error);
   }
 },
//  async barcodeRefs(id: string) {
   
//  },
//  async generateBarcodes() {
//       this.fettchDataArray.forEach((dataItem) => {
//         const svgElement = this.barcodeRefs[dataItem.id];
//         if (svgElement && dataItem.barcode) {
//           // Explicitly cast to SVGSVGElement
//           JsBarcode(svgElement as SVGSVGElement, dataItem.barcode, {
//             format: 'CODE128',
//             lineColor: '#000',
//             width: 1,
//             height: 50,
//             displayValue: true,
//           });
//           console.log('_svgElement', svgElement);
//         }
//       });
//     },
    async printInvoice(paperSize: string) {

// Choose the template based on the selected paper size
if (paperSize === '57mm') {       
  const element57mm = document.getElementById('invoice-container-57mm');
    if (element57mm) {
    // Create a new window and copy the content of the element
    const printWindow = window.open('');
      if (printWindow) {
        // printWindow.document.write('<html><head><link rel="icon" href="https://phicsart.com/uploads/images/top-page/menu/logo.svg"> <link rel="icon" type="image/x-icon" href="./assets/img/favicon/favicon.ico" /> <link rel="preconnect" href="https://fonts.googleapis.com" /><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin /><link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/fonts/boxicons.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/core.css" class="template-customizer-core-css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/theme-default.css" class="template-customizer-theme-css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/css/demo.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/apex-charts/apex-charts.css" /> <title>Print</title></head><body>');
        // printWindow.document.write(element.innerHTML);
        // printWindow.document.write('</body></html>');
        // printWindow.document.close();

        // // Add a load event listener to ensure the content is fully loaded before printing
        // printWindow.onload = () => {
        //   printWindow.print();
        //   printWindow.onafterprint = () => {
        //     printWindow.close();
        //   };
        // };
        printWindow.document.write('<html><head><link rel="icon" href="https://phicsart.com/uploads/images/top-page/menu/logo.svg"> <link rel="icon" type="image/x-icon" href="./assets/img/favicon/favicon.ico" /> <link rel="preconnect" href="https://fonts.googleapis.com" /><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin /><link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/fonts/boxicons.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/core.css" class="template-customizer-core-css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/theme-default.css" class="template-customizer-theme-css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/css/demo.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/apex-charts/apex-charts.css" /><title>Print</title>');
        // Add CSS styles for print media
        printWindow.document.write(`
          <style>
            @media print {
              body {
                margin: 20px; /* Reset margin for print */
                font-size: 10px !important; /* Adjust the font size as needed */
                width: 57mm; /* Ensure the content fits within the paper size */
              }

              /* Add more print styles here based on the paper size */
              @page {
                size: 80mm; /* Set the paper size */
                margin: 20px; /* Set margins as needed */
              }

              /* Add other print styles specific to your design */
              /* Example: */
              #${element57mm} {
                width: 80mm; /* Ensure the content fits within the paper size */
                font-size: 8px; /* Adjust the font size as needed */
              }
              #invoice-container-80mm p, h4{
                font-size: 10px;
              }
              #invoice-container-80mm h4{
              margin-bottom: 0;
              padding: 0;
              }
              .card {
                  background-clip: padding-box;
                  box-shadow: none;
              }
              .card-body{
              margin: 0;
              padding: 0;
              }
              .card-body h6{
              margin: 0;
              padding: 0;
              font-size: 10px;
              }
              .table > thead{
                border-style: hidden;
                color: black;
              }
              .table:not(.table-dark) th{
                color: black;
              }
              .table > tr > td{
                border-style: unset;
                border-width: 0;
                font-size: 10px;
                font-weight: 1000;
                color: black;
                border-bottom-width: 0px;
              }
              .table > :not(caption) > * > *{
                border-style: hidden;
                border-bottom-width: 0px;
              }
              .table > :not(caption) > * > *{
                padding: 5px 0;
                font-size: 10px;
                color: black;
              }
            }
          </style>
        `);

        // Copy the content of the element
        printWindow.document.write('</head><body>');
        printWindow.document.write(element57mm.innerHTML);
        printWindow.document.write('</body></html>');

        printWindow.document.close();

        // Add a load event listener to ensure the content is fully loaded before printing
        printWindow.onload = () => {
          printWindow.print();
          printWindow.onafterprint = () => {
            printWindow.close();
          };
        };
      }
    }
} 
else if (paperSize === '80mm') {
  const element80mm = document.getElementById('invoice-container-80mm');
    if (element80mm) {
    // Create a new window and copy the content of the element
    const printWindow = window.open('');
    if (printWindow) {
      // printWindow.document.write('<html><head><link rel="icon" href="https://phicsart.com/uploads/images/top-page/menu/logo.svg"> <link rel="icon" type="image/x-icon" href="./assets/img/favicon/favicon.ico" /> <link rel="preconnect" href="https://fonts.googleapis.com" /><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin /><link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/fonts/boxicons.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/core.css" class="template-customizer-core-css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/theme-default.css" class="template-customizer-theme-css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/css/demo.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/apex-charts/apex-charts.css" /> <title>Print</title></head><body>');
      // printWindow.document.write(element.innerHTML);
      // printWindow.document.write('</body></html>');
      // printWindow.document.close();

      // // Add a load event listener to ensure the content is fully loaded before printing
      // printWindow.onload = () => {
      //   printWindow.print();
        
      //   printWindow.onafterprint = () => {
      //     printWindow.close();
      //   };
      // };
      printWindow.document.write('<html><head><link rel="icon" href="https://phicsart.com/uploads/images/top-page/menu/logo.svg"> <link rel="icon" type="image/x-icon" href="./assets/img/favicon/favicon.ico" /> <link rel="preconnect" href="https://fonts.googleapis.com" /><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin /><link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/fonts/boxicons.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/core.css" class="template-customizer-core-css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/theme-default.css" class="template-customizer-theme-css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/css/demo.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/apex-charts/apex-charts.css" /><title>Print</title>');
      // Add CSS styles for print media
      printWindow.document.write(`
        <style>
          @media print {
            body {
              margin: 20px; /* Reset margin for print */
              font-size: 10px !important; /* Adjust the font size as needed */
              width: 80mm; /* Ensure the content fits within the paper size */
            }

            /* Add more print styles here based on the paper size */
            @page {
              size: 80mm; /* Set the paper size */
              margin: 20px; /* Set margins as needed */
            }

            /* Add other print styles specific to your design */
            /* Example: */
            #${element80mm} {
              width: 80mm; /* Ensure the content fits within the paper size */
              font-size: 8px; /* Adjust the font size as needed */
            }
            #invoice-container-80mm p, h4{
              font-size: 10px;
            }
            #invoice-container-80mm h4{
            margin-bottom: 0;
            padding: 0;
            }
            .card {
                background-clip: padding-box;
                box-shadow: none;
            }
            .card-body{
            margin: 0;
            padding: 0;
            }
            .card-body h6{
            margin: 0;
            padding: 0;
            font-size: 10px;
            }
            .table > thead{
              border-style: hidden;
              color: black;
            }
            .table:not(.table-dark) th{
              color: black;
            }
            .table > tr > td{
              border-style: unset;
              border-width: 0;
              font-size: 10px;
              font-weight: 1000;
              color: black;
              border-bottom-width: 0px;
            }
            .table > :not(caption) > * > *{
              border-style: hidden;
              border-bottom-width: 0px;
            }
            .table > :not(caption) > * > *{
              padding: 5px 0;
              font-size: 10px;
              color: black;
            }
          }
        </style>
      `);

      // Copy the content of the element
      printWindow.document.write('</head><body>');
      printWindow.document.write(element80mm.innerHTML);
      printWindow.document.write('</body></html>');

      printWindow.document.close();

      // Add a load event listener to ensure the content is fully loaded before printing
      printWindow.onload = () => {
        printWindow.print();
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
    }
    }
}
 else {
   // Default to A4 size
  let elementA4 = document.getElementById('invoice-container');
 if(elementA4){
    const printWindow = window.open('');
    if (printWindow) {
      printWindow.document.write('<html><head><link rel="icon" href="https://phicsart.com/uploads/images/top-page/menu/logo.svg"> <link rel="icon" type="image/x-icon" href="./assets/img/favicon/favicon.ico" /> <link rel="preconnect" href="https://fonts.googleapis.com" /><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin /><link href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/fonts/boxicons.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/core.css" class="template-customizer-core-css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/css/theme-default.css" class="template-customizer-theme-css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/css/demo.css" /><link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" /> <link rel="stylesheet" href="https://pos.phicsart.com/assets/vendor/libs/apex-charts/apex-charts.css" /><title>Print</title>');
      // Add CSS styles for print media
      printWindow.document.write(`
        <style>
          @media print {
            body {
              margin: 10px; /* Reset margin for print */
              font-size: 7px !important; /* Adjust the font size as needed */
             /* width: A4;  Ensure the content fits within the paper size */
             background-color: white !important;
            }

            /* Add more print styles here based on the paper size */
            @page {
               /*size: 80mm; Set the paper size */
              /* margin: 20px; Set margins as needed */
            }

            /* Add other print styles specific to your design */
            /* Example: */
            #${elementA4} {
              /* width: 80mm; Ensure the content fits within the paper size */
               /* font-size: 8px;Adjust the font size as needed */
            }
          }
        </style>
      `);

      // Copy the content of the element
      printWindow.document.write('</head><body>');
      printWindow.document.write(elementA4.innerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();

      // Add a load event listener to ensure the content is fully loaded before printing
      printWindow.onload = () => {
        printWindow.print();
        
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
    }
 }        
}

// ... rest of your code remains unchanged ...
},
async downloadInvoice(invoice: any) {
   const element = document.getElementById('invoice-container');
   const invoiceNumber = invoice.invoice_no;

   // Generate the PDF content using html2pdf
   const pdfBlob = await html2pdf(element).output('blob');

   // Create a Blob URL for the PDF content
   const blobUrl = URL.createObjectURL(pdfBlob);

   // Create a download link
   const downloadLink = document.createElement('a');
   downloadLink.href = blobUrl;

   // Set the download attribute with the desired filename
   downloadLink.download = `${invoiceNumber}.pdf`;

   // Append the download link to the document
   document.body.appendChild(downloadLink);

   // Trigger a click event on the download link to start the download
   downloadLink.click();

   // Wait for a short delay before removing the download link
   setTimeout(() => {
     // Remove the download link from the document
     document.body.removeChild(downloadLink);
   }, 100); // Adjust the delay as needed
 },
},
mounted() {
 // Set isLoading to true when the component is mounted
//  this.fetchProductFunc();
// console.log('user_data', this.userData.store[0].type_id);
// this.generateBarcodes();

},
})
</script>