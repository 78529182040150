<template>
    <div class="layout-page">
    <TopBarView />
    <div class="content-wrapper">
      <div>
          <div>
            <div class="flex-grow-1 container-p-y px-4">
              <div class="d-flex justify-content-between aling-items-center">
                <div>
                  <h4 class="fw-bold py-3 mb-4">
                    <span class="text-muted fw-light">Store </span> Details
                  </h4>
                </div>
                <div>
                  <router-link :to="{ path: '/store' }" class="btn btn-primary mt-2">
                     Back to  List
                  </router-link>
                </div>
              </div>
                <!-- <Search /> -->
                <div class="" v-if="isDataTrue == false">
                  <div v-if="successReport" class="alert alert-success alert-dismissible" role="alert">
                    Successfully
                    <button type="button" class="btn-close"  aria-label="Close"></button>
                  </div>
                  <div class="card">
                    <div class="card-body">
                      <h3 class="card-title px-3">Store/ Dealer &mdash; Information</h3>
                      <!-- show the data in the table. The table th will be on left and td will be right side  -->
                      <div class="table-responsive text-nowrap mt-4">
                        <table class="table" style="border-color: transparent;">
                          <tbody class="">
                            <tr>
                              <th style="width: 20%;">Store Name</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.name }}</td>
                            </tr>
                            <tr>
                              <th>Type</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.type_id }}</td>
                            </tr>
                            <tr>
                              <th>Address</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.address }}</td>
                            </tr>
                            <tr>
                              <th>Phone</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.phone }}</td>
                            </tr>
                            <tr>
                              <th>Email</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.email }}</td>
                            </tr>
                            <tr>
                              <th>District</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.district }}</td>
                            </tr>
                            <tr>
                              <th>Division</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.division }}</td>
                            </tr>
                            <tr>
                              <th>Address</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.address }}</td>
                            </tr>
                            <tr>
                              <th>Trade License</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.trade_license }}</td>
                            </tr>
                            <tr>
                              <th>Facebook</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.facebook_url }}</td>
                            </tr>
                            <tr>
                              <th>Twitter</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.twitter_url }}</td>
                            </tr>
                            <tr>
                              <th>Instagram</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.instagram_url }}</td>
                            </tr>
                            <tr>
                              <th>Logo</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.logo }}</td>
                            </tr>
                            <tr>
                              <th>Cover Image</th>
                              <th style="width: 2%;">:</th>
                              <td>{{ formData.cover_img }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> 
            </div>
          </div>
        </div>
    <Footer />
      <!-- / Footer -->
      <div class="content-backdrop fade"></div>
    </div>
    <!-- / Layout wrapper -->
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
// import CkEditor from "../../../components/Ckeditor.vue";
import axios from 'axios';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";
import Footer from "../../FooterView.vue";
import TopBarView from "../../TopBarView.vue";
import { RouteLocationNormalized } from 'vue-router';
interface Post {
  id: number;
  company_id: string;
  email: string;
  name: string;
  phone: string;
  address: string;
  
  user: object;
  user_id: string; // Assuming this property exists
}
export default defineComponent({
  // components: { CkEditor },
  name: "store-view-data", // Rename the component to a multi-word name
  components: { Footer, TopBarView, },
    data() {
      const store = useStore();
      const token = store.state.auth.token || localStorage.getItem('token');
      const userId = store.state.auth.userId || localStorage.getItem('userId');
      const storeId = store.state.auth.storeId || localStorage.getItem('storeId');
        return {
            siteInfoData: ref([] as Post[]),
            successReport: false,
            storeId: '',
            itemId: '',
            companyId: '',
            store,
            token,
            userId, 
            formData: {
              id: Number,
              company_id: "",
              name: "",
              email: "",
              phone: "",
              division: "",
              district: "",
              trade_license: "",
              address: "",
              logo: "",
              cover_img: "",
              website_url: "",
              facebook_url: "",
              twitter_url: "",
              instagram_url: "",
              user_id: "",
              is_default: 0,
              type_id: '',
            },
            submitBtn: false,
            fetchTypeList: [{
              id: Number,
              title: '',
            }],  
        }
    },
  props: {
    isDataTrue: Boolean
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fetchData(newVal);
          this.itemId = newVal;
        }
      },
    },
  },
  computed: {
    isRouteParamIdPresent(): boolean {
      return (this.$route as RouteLocationNormalized).params.id !== undefined;
    }
  },
  methods: {
    async fetchData(id: number) {
      try {   
        if (!this.token) {
          throw new Error('Token not found');
        }
        const response = await (axios as any).get(DOAMIN_PATH + `store/${id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        console.log('_page_data_by_ID_', response);
        if (response.status === 200) {
          const data = response.data;
          this.formData = data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchTypeFunc() {
      // try {
      //   if (!this.token) {
      //     throw new Error('Token not found');
      //   }
      //   const response = await (axios as any).get(DOAMIN_PATH +`type`, {
      //     headers: {
      //       Authorization: `Bearer ${this.token}`,
      //       storeId: this.storeId,
      //     },
      //   });
      //   console.log('_type_list', response);
      //   if (response.status === 200) {
      //     this.fetchTypeList = response.data.data.data;
      //     //  this.fetchTypeList = response.data.data.data;
      //   }
      // } catch (error) {
      //   console.error(error);
      // }
    },  
    async handleSubmit() {
      try {
        this.submitBtn = true;
        if (this.itemId) {
          const response = await (axios as any).put(
            DOAMIN_PATH + 'store/' + this.itemId,
            this.formData,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              }
            }
          );
          if
          (response.status == 204) {
            console.log();
            this.successReport = true;
            this.submitBtn = false;
            setTimeout(() => { this.successReport = false; }, 3000);
          }
        }  
        else {
          this.submitBtn = true;
          this.formData.is_default = 0;
          this.formData.user_id = this.userId;
          const response = await (axios as any).post(DOAMIN_PATH + 'store', this.formData,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              }
            }
          );
          if (response.status == 201) {
            // console.log('_save_success', response);
            this.formData = {
              id: Number,
              company_id: "",
              name: "",
              email: "",
              phone: "",
              division: "",
              district: "",
              trade_license: "",
              address: "",
              logo: "",
              cover_img: "",
              website_url: "",
              facebook_url: "",
              twitter_url: "",
              instagram_url: "",
              user_id: this.userId,
              is_default: 0,
              type_id: '',
            };
            this.successReport = true;
            this.submitBtn = false;
            setTimeout(() => { this.successReport = false; }, 3000);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

  },
mounted() {
    // Set isLoading to true when the component is mounted
    this.fetchTypeFunc();
  },
})
</script>