import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container-xxl" }
const _hoisted_2 = { class: "authentication-wrapper authentication-basic container-p-y" }
const _hoisted_3 = {
  class: "authentication-inner",
  style: {"max-width":"550px","margin":"0 auto"}
}
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = ["required"]
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "mb-3" }
const _hoisted_12 = { class: "mb-3" }
const _hoisted_13 = { class: "mb-3" }
const _hoisted_14 = { class: "mb-3" }
const _hoisted_15 = { class: "mb-3" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 1,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_19 = {
  key: 2,
  class: "visually-show"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"app-brand justify-content-center\"><a href=\"/\" class=\"app-brand-link gap-2\"><span class=\"app-brand-text demo text-body fw-bold text-uppercase mb-3\"><img src=\"https://pos-file.phicsart.com/profile_user.png\" alt=\"n/a\" class=\"img-fluid\" style=\"width:80px;height:80px;\"></span></a></div><h4 class=\"mb-2 text-center\">Add Business Info</h4>", 2)),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[6] || (_cache[6] = [
                  _createElementVNode("div", { class: "d-flex justify-content-center align-items-center" }, [
                    _createElementVNode("div", {
                      class: "spinner-border text-primary",
                      role: "status"
                    }, [
                      _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                    ])
                  ], -1)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("form", {
                    id: "formAuthentication",
                    class: "mb-3",
                    onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.CompanyRegistration && _ctx.CompanyRegistration(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[8] || (_cache[8] = _createElementVNode("label", {
                        for: "companyEmail",
                        class: "form-label"
                      }, "Store Type", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "form-select",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.storeForm.type_id) = $event)),
                        required: _ctx.storeForm.type_id == null
                      }, [
                        _cache[7] || (_cache[7] = _createElementVNode("option", {
                          select: "",
                          value: "null",
                          disabled: ""
                        }, "-- Select Category-- ", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeForm, (typeForms) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: typeForms.id,
                            value: typeForms.id
                          }, _toDisplayString(typeForms.title), 9, _hoisted_10))
                        }), 128))
                      ], 8, _hoisted_9), [
                        [_vModelSelect, _ctx.storeForm.type_id]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _cache[9] || (_cache[9] = _createElementVNode("label", {
                        for: "companyName",
                        class: "form-label"
                      }, "Store Name", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "form-control",
                        id: "companyName",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.storeForm.name) = $event)),
                        placeholder: "Store Name"
                      }, null, 512), [
                        [_vModelText, _ctx.storeForm.name]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _cache[10] || (_cache[10] = _createElementVNode("label", {
                        for: "companyEmail",
                        class: "form-label"
                      }, "Store Email", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "email",
                        class: "form-control",
                        id: "companyEmail",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.storeForm.email) = $event)),
                        placeholder: "Store Email",
                        required: ""
                      }, null, 512), [
                        [_vModelText, _ctx.storeForm.email]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _cache[11] || (_cache[11] = _createElementVNode("label", {
                        for: "companyphone",
                        class: "form-label"
                      }, "Store Phone", -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "form-control",
                        id: "companyphone",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.storeForm.phone) = $event)),
                        placeholder: "Store Phone",
                        required: ""
                      }, null, 512), [
                        [_vModelText, _ctx.storeForm.phone]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _cache[12] || (_cache[12] = _createElementVNode("label", { class: "form-label" }, "Store Address", -1)),
                      _withDirectives(_createElementVNode("textarea", {
                        class: "form-control",
                        placeholder: "Store Address",
                        rows: "3",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.storeForm.address) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.storeForm.address]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("button", {
                        type: "submit",
                        class: "btn btn-primary w-100 justify-content-center",
                        disabled: _ctx.submitBtn
                      }, [
                        (!_ctx.submitBtn)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Save"))
                          : _createCommentVNode("", true),
                        (_ctx.submitBtn)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18))
                          : _createCommentVNode("", true),
                        (_ctx.submitBtn)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_19, " Saving ...."))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_16)
                    ])
                  ], 32)
                ]))
          ])
        ])
      ])
    ])
  ]))
}