import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-success alert-dismissible",
  role: "alert"
}
const _hoisted_3 = { class: "card d-none d-md-block" }
const _hoisted_4 = {
  class: "card-body",
  id: "invoice-container"
}
const _hoisted_5 = {
  key: 0,
  class: "table card-table text-center"
}
const _hoisted_6 = { class: "table-border-bottom-0" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "badge bg-success" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "badge bg-danger" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "mobile-view d-block d-md-none" }
const _hoisted_13 = {
  key: 0,
  class: "card"
}
const _hoisted_14 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.successReport)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createTextVNode(" Successfully "),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "aria-label": "Close"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.fettchDataArray.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", null, "Id"),
                  _createElementVNode("th", null, "Type"),
                  _createElementVNode("th", null, "Quantity"),
                  _createElementVNode("th", null, "Unit"),
                  _createElementVNode("th", null, "Stock Status"),
                  _createElementVNode("th", null, "Action")
                ])
              ], -1)),
              _createElementVNode("tbody", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: dataItem.id
                  }, [
                    _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                    _createElementVNode("td", null, _toDisplayString(dataItem.type), 1),
                    _createElementVNode("td", null, [
                      (dataItem.type == 'IN')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                            _createElementVNode("span", _hoisted_8, _toDisplayString(dataItem.quantity), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _cache[1] || (_cache[1] = _createTextVNode()),
                      (dataItem.type == 'OUT')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                            _createElementVNode("span", _hoisted_10, _toDisplayString(dataItem.quantity), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("td", null, _toDisplayString(dataItem.product.unit), 1),
                    _cache[4] || (_cache[4] = _createElementVNode("td", null, [
                      _createElementVNode("small", null, " WH- 00 | DE- 00 | ST- 00")
                    ], -1)),
                    _createElementVNode("td", null, [
                      _createElementVNode("a", {
                        href: `https://pos-api.raidatrade.com/api/barcode-by-stock-id/${dataItem.id}`,
                        target: "_blank",
                        rel: "noopener noreferrer",
                        class: "me-2 btn btn-sm btn-primary"
                      }, _cache[2] || (_cache[2] = [
                        _createElementVNode("i", { class: "fa fa-print me-1 fs-6" }, null, -1),
                        _createTextVNode(" PDF ")
                      ]), 8, _hoisted_11),
                      _cache[3] || (_cache[3] = _createElementVNode("button", { class: "btn btn-sm btn-secondary" }, [
                        _createElementVNode("i", { class: "fa fa-cog me-1 fs-6" }),
                        _createTextVNode(" More ")
                      ], -1))
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[6] || (_cache[6] = [
            _createElementVNode("div", { class: "card-body" }, [
              _createElementVNode("div", { class: "d-flex justify-content-center align-items-center" }, [
                _createElementVNode("div", {
                  class: "spinner-border text-primary",
                  role: "status"
                }, [
                  _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                ])
              ])
            ], -1)
          ])))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "card mb-4",
          key: dataItem.id
        }, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("small", null, "Remarks: " + _toDisplayString(dataItem.remarks), 1),
            _cache[7] || (_cache[7] = _createTextVNode()),
            _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
            _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("small", null, "Time:" + _toDisplayString(_ctx.formatDateTime(dataItem.created_at)), 1)
          ])
        ]))
      }), 128))
    ]),
    _cache[10] || (_cache[10] = _createStaticVNode("<div class=\"offcanvas offcanvas-start\" tabindex=\"-1\" id=\"offcanvasExample\" aria-labelledby=\"offcanvasExampleLabel\"><div class=\"offcanvas-header\"><h5 class=\"offcanvas-title\" id=\"offcanvasExampleLabel\">Offcanvas</h5><button type=\"button\" class=\"btn-close\" data-bs-dismiss=\"offcanvas\" aria-label=\"Close\"></button></div><div class=\"offcanvas-body\"></div></div>", 1))
  ]))
}