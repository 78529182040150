import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-success alert-dismissible",
  role: "alert"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "card py-3" }
const _hoisted_6 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_7 = {
  key: 1,
  class: "row invoice-preview"
}
const _hoisted_8 = { class: "col-xl-9 col-md-8 col-12 mb-md-0 mb-4" }
const _hoisted_9 = { class: "card invoice-preview-card" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "d-flex justify-content-between flex-xl-row flex-md-column flex-sm-row flex-column p-sm-3 p-0" }
const _hoisted_12 = { class: "mb-2" }
const _hoisted_13 = { class: "fw-medium" }
const _hoisted_14 = { class: "card-body" }
const _hoisted_15 = { class: "row p-sm-3 p-0" }
const _hoisted_16 = { class: "col-xl-12 col-md-12 col-sm-7 col-12" }
const _hoisted_17 = { class: "table-responsive mx-4" }
const _hoisted_18 = { class: "table table-bordered m-0 text-center" }
const _hoisted_19 = { class: "col-xl-3 col-md-4 col-12 invoice-actions" }
const _hoisted_20 = { class: "card" }
const _hoisted_21 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.successReport)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
            _createTextVNode(" Successfully "),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "aria-label": "Close"
            }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[2] || (_cache[2] = [
                _createElementVNode("div", {
                  class: "spinner-border text-primary",
                  role: "status"
                }, [
                  _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                ], -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"mb-xl-0 mb-4\"><div class=\"d-flex svg-illustration mb-4 gap-2\"><span class=\"app-brand-text demo menu-text fw-bolder ms-2 text-uppercase\">Company</span></div><p class=\"mb-1\">House: E/15, Block: E, Zakir Hossain Road</p><p class=\"mb-1\">Lalmatia, Dhaka - 1207</p><p class=\"mb-0\">+880 1881 242071, +880 1710 001337</p></div>", 1)),
                        _createElementVNode("div", null, [
                          _createElementVNode("h4", null, "Invoice No: " + _toDisplayString(_ctx.orderData.invoice_no), 1),
                          _createElementVNode("div", _hoisted_12, [
                            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "me-1" }, "Date Issues:", -1)),
                            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.orderData.created_at), 1)
                          ])
                        ])
                      ])
                    ]),
                    _cache[14] || (_cache[14] = _createElementVNode("hr", { class: "my-0" }, null, -1)),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _cache[9] || (_cache[9] = _createElementVNode("h6", { class: "pb-2" }, "Bill To:", -1)),
                          _createElementVNode("table", null, [
                            _createElementVNode("tbody", null, [
                              _createElementVNode("tr", null, [
                                _cache[5] || (_cache[5] = _createElementVNode("td", { class: "pe-3" }, "Cutomer Name", -1)),
                                _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer_name), 1)
                              ]),
                              _createElementVNode("tr", null, [
                                _cache[6] || (_cache[6] = _createElementVNode("td", { class: "pe-3" }, "Customer Type", -1)),
                                _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer_type), 1)
                              ]),
                              _createElementVNode("tr", null, [
                                _cache[7] || (_cache[7] = _createElementVNode("td", { class: "pe-3" }, "Customer Phone", -1)),
                                _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer_phone), 1)
                              ]),
                              _createElementVNode("tr", null, [
                                _cache[8] || (_cache[8] = _createElementVNode("td", { class: "pe-3" }, "Address:", -1)),
                                _createElementVNode("td", null, _toDisplayString(_ctx.orderData?.customer_address), 1)
                              ])
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("table", _hoisted_18, [
                        _cache[13] || (_cache[13] = _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, "Id"),
                            _createElementVNode("th", null, "Name"),
                            _createElementVNode("th", null, "Price"),
                            _createElementVNode("th", null, "Quantity"),
                            _createElementVNode("th", null, "Total")
                          ])
                        ], -1)),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderData.order_items, (dataItem) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: dataItem.id
                            }, [
                              _createElementVNode("td", null, _toDisplayString(dataItem.id), 1),
                              _createElementVNode("td", null, _toDisplayString(dataItem.product_id), 1),
                              _createElementVNode("td", null, _toDisplayString(dataItem.sell_price), 1),
                              _createElementVNode("td", null, _toDisplayString(dataItem.quantity), 1),
                              _createElementVNode("td", null, _toDisplayString(dataItem.total), 1)
                            ]))
                          }), 128))
                        ]),
                        _createElementVNode("tfoot", null, [
                          _createElementVNode("tr", null, [
                            _cache[10] || (_cache[10] = _createElementVNode("th", {
                              colspan: "4",
                              class: "text-end"
                            }, "Sub Total =", -1)),
                            _createElementVNode("th", null, _toDisplayString(_ctx.orderData.sub_total), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[11] || (_cache[11] = _createElementVNode("th", {
                              colspan: "4",
                              class: "text-end"
                            }, "Discount = ", -1)),
                            _createElementVNode("th", null, _toDisplayString(_ctx.orderData.discount), 1)
                          ]),
                          _createElementVNode("tr", null, [
                            _cache[12] || (_cache[12] = _createElementVNode("th", {
                              colspan: "4",
                              class: "text-end"
                            }, "Total =", -1)),
                            _createElementVNode("th", null, _toDisplayString(_ctx.orderData.total), 1)
                          ])
                        ])
                      ])
                    ]),
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "card-body" }, [
                      _createElementVNode("div", { class: "row" }, [
                        _createElementVNode("div", { class: "col-12" }, [
                          _createElementVNode("span", null, [
                            _createTextVNode("Software Design & Development by ❤️ "),
                            _createElementVNode("strong", null, "PhicsArt"),
                            _createTextVNode("!")
                          ])
                        ])
                      ])
                    ], -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _cache[16] || (_cache[16] = _createElementVNode("button", {
                        class: "btn btn-primary d-grid w-100 mb-3",
                        "data-bs-toggle": "offcanvas",
                        "data-bs-target": "#sendInvoiceOffcanvas"
                      }, [
                        _createElementVNode("span", { class: "d-flex align-items-center justify-content-center text-nowrap" }, [
                          _createElementVNode("i", { class: "bx bx-paper-plane bx-xs me-1" }),
                          _createTextVNode("Send Invoice To Cutomer")
                        ])
                      ], -1)),
                      _cache[17] || (_cache[17] = _createElementVNode("button", { class: "btn btn-label-secondary btn-default border d-grid w-100 mb-3" }, " Download ", -1)),
                      _createElementVNode("button", {
                        class: "btn btn-label-secondary border d-grid w-100 mb-3",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.printInvoice && _ctx.printInvoice(...args)))
                      }, " Print ")
                    ])
                  ])
                ])
              ]))
        ])
      ])
    ])
  ], 64))
}