import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "layout-navbar navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme",
  id: "layout-navbar"
}
const _hoisted_2 = {
  class: "navbar-nav-right d-flex align-items-center",
  id: "navbar-collapse"
}
const _hoisted_3 = { class: "navbar-nav align-items-center" }
const _hoisted_4 = { class: "nav-item d-flex align-items-center" }
const _hoisted_5 = { class: "m-0 p-0 text-primary" }
const _hoisted_6 = { class: "navbar-nav flex-row align-items-center ms-auto" }
const _hoisted_7 = { class: "nav-item lh-1 me-3" }
const _hoisted_8 = { class: "nav-item navbar-dropdown dropdown-user dropdown" }
const _hoisted_9 = { class: "dropdown-menu dropdown-menu-end" }
const _hoisted_10 = {
  class: "dropdown-item",
  href: "#"
}
const _hoisted_11 = { class: "d-flex" }
const _hoisted_12 = { class: "flex-grow-1" }
const _hoisted_13 = { class: "fw-semibold d-block" }
const _hoisted_14 = { class: "text-muted" }
const _hoisted_15 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("nav", _hoisted_1, [
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none" }, [
        _createElementVNode("a", {
          class: "nav-item nav-link px-0 me-xl-4 theme-responsive-menu",
          onclick: "toggleSidebar()"
        }, [
          _createElementVNode("i", { class: "bx bx-menu bx-sm" })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h5", _hoisted_5, [
              _createElementVNode("span", null, [
                _createVNode(_component_router_link, { to: "/" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString((_ctx.userDefaultData as unknown as Array<{ id: number; name: string }>[0]).name), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ]),
        _createElementVNode("ul", _hoisted_6, [
          _createElementVNode("li", _hoisted_7, [
            _createVNode(_component_router_link, {
              to: "/setting",
              class: "github-button d-none d-md-inline-block"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString((_ctx.userData as unknown as Array<{ id: number; firstname: string }>[0]).firstname) + " " + _toDisplayString((_ctx.userData as unknown as Array<{ id: number; lastname: string }>[0]).lastname), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_8, [
            _cache[8] || (_cache[8] = _createElementVNode("a", {
              class: "nav-link dropdown-toggle hide-arrow",
              href: "javascript:void(0);",
              "data-bs-toggle": "dropdown"
            }, [
              _createElementVNode("div", { class: "avatar avatar-online" }, [
                _createElementVNode("img", {
                  src: "/pos_images/POS.png",
                  alt: "n/a",
                  class: "w-px-40 h-auto rounded-circle"
                })
              ])
            ], -1)),
            _createElementVNode("ul", _hoisted_9, [
              _createElementVNode("li", null, [
                _createElementVNode("a", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex-shrink-0 me-3" }, [
                      _createElementVNode("div", { class: "avatar avatar-online" }, [
                        _createElementVNode("img", {
                          src: "/pos_images/POS.png",
                          alt: "n/a",
                          class: "w-px-40 h-auto rounded-circle"
                        })
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString((_ctx.userData as unknown as Array<{ id: number; firstname: string }>[0]).firstname) + " " + _toDisplayString((_ctx.userData as unknown as Array<{ id: number; lastname: string }>[0]).lastname), 1),
                      _createElementVNode("small", _hoisted_14, _toDisplayString((_ctx.userData as unknown as Array<{ id: number; email: string }>[0]).email), 1),
                      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                      _createElementVNode("small", _hoisted_15, _toDisplayString((_ctx.userData as unknown as Array<{ id: number; name: string }>[0]).name), 1)
                    ])
                  ])
                ])
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("li", null, [
                _createElementVNode("div", { class: "dropdown-divider" })
              ], -1)),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: "/setting",
                  class: "dropdown-item",
                  href: "#"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "bx bx-user me-2" }, null, -1),
                    _createElementVNode("span", { class: "align-middle" }, "My Profile", -1)
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: "/securitysettings",
                  class: "dropdown-item",
                  href: "#"
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createElementVNode("i", { class: "bx bx-cog me-2" }, null, -1),
                    _createElementVNode("span", { class: "align-middle" }, "Security Settings", -1)
                  ])),
                  _: 1
                })
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("li", null, [
                _createElementVNode("div", { class: "dropdown-divider" })
              ], -1)),
              _createElementVNode("li", null, [
                _createElementVNode("button", {
                  class: "dropdown-item",
                  type: "submit",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("i", { class: "bx bx-power-off me-2" }, null, -1),
                  _createElementVNode("span", { class: "align-middle" }, "Log Out", -1)
                ]))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}