import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-success alert-dismissible",
  role: "alert"
}
const _hoisted_2 = { class: "card mb-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "input-group" }
const _hoisted_6 = { class: "card mb-4 d-none d-md-block" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_9 = {
  key: 1,
  class: "table-responsive"
}
const _hoisted_10 = {
  key: 0,
  class: "table card-table text-center"
}
const _hoisted_11 = { class: "table-border-bottom-0" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "mobile-view d-block d-md-none" }
const _hoisted_16 = {
  key: 0,
  class: "card"
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "card-body" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { key: 2 }
const _hoisted_22 = {
  key: 1,
  class: ""
}
const _hoisted_23 = { class: "d-flex justify-content-between flex-wrap" }
const _hoisted_24 = { "aria-label": "Page navigation" }
const _hoisted_25 = { class: "pagination flex-wrap" }
const _hoisted_26 = ["onClick", "innerHTML"]
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-12" }
const _hoisted_29 = { class: "input-group" }
const _hoisted_30 = {
  class: "modal fade",
  id: "modalCenter",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_31 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_32 = { class: "modal-content" }
const _hoisted_33 = { class: "modal-body" }
const _hoisted_34 = { class: "row mb-3" }
const _hoisted_35 = { class: "col-sm-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.successReport)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[7] || (_cache[7] = [
          _createTextVNode(" Successfully "),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "aria-label": "Close"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "input-group-prepend" }, null, -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control w-25",
              placeholder: "Enter Search Key !",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchKey) = $event))
            }, null, 512), [
              [_vModelText, _ctx.searchKey]
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary me-1 ms-1",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.searchData && _ctx.searchData(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'magnifying-glass'] })
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-default border",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchKey='', _ctx.fetchAllData()))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'broom'] })
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[9] || (_cache[9] = [
              _createElementVNode("div", {
                class: "spinner-border text-primary",
                role: "status"
              }, [
                _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
              ], -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_ctx.fettchDataArray.length > 0)
                ? (_openBlock(), _createElementBlock("table", _hoisted_10, [
                    _cache[10] || (_cache[10] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, "Id"),
                        _createElementVNode("th", null, "Name"),
                        _createElementVNode("th", null, "Quantity (WH | DE)"),
                        _createElementVNode("th", null, "Unit"),
                        _createElementVNode("th", null, "Action")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem, index) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: dataItem.id
                        }, [
                          _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                          _createElementVNode("td", null, _toDisplayString(dataItem.name) + " " + _toDisplayString(dataItem.id), 1),
                          _createElementVNode("td", null, _toDisplayString(dataItem.stocks[0]?.total_quantity), 1),
                          _createElementVNode("td", null, _toDisplayString(dataItem.unit), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "me-2 btn btn-sm btn-primary btn-disable",
                              onClick: ($event: any) => (_ctx.fetchItem(dataItem)),
                              "data-bs-toggle": "modal",
                              "data-bs-target": "#modalCenter"
                            }, " + Add Stock ", 8, _hoisted_12),
                            _createElementVNode("button", {
                              type: "button",
                              class: "me-2 btn btn-sm btn-secondary btn-disable",
                              onClick: ($event: any) => (_ctx.summaryFunc(dataItem.id.toString()))
                            }, " Summary ", 8, _hoisted_13)
                          ])
                        ]))
                      }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[11] || (_cache[11] = [
                    _createElementVNode("h5", { class: "text-center mb-0" }, "Please Add Some Product First", -1)
                  ])))
            ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[12] || (_cache[12] = [
            _createElementVNode("div", { class: "card-body" }, [
              _createElementVNode("div", { class: "d-flex justify-content-center align-items-center" }, [
                _createElementVNode("div", {
                  class: "spinner-border text-primary",
                  role: "status"
                }, [
                  _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                ])
              ])
            ], -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.fettchDataArray.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "card mb-4",
                key: dataItem.id
              }, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("strong", null, " Name: " + _toDisplayString(dataItem.name), 1),
                  _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("button", {
                    type: "button",
                    class: "me-2 btn btn-sm btn-primary btn-disable",
                    onClick: ($event: any) => (_ctx.fetchItem(dataItem)),
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#modalCenter"
                  }, " + Add Stock ", 8, _hoisted_19),
                  _createElementVNode("button", {
                    type: "button",
                    class: "me-2 btn btn-sm btn-secondary btn-disable",
                    onClick: ($event: any) => (_ctx.summaryFunc(dataItem.id.toString()))
                  }, " Summary ", 8, _hoisted_20)
                ])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[14] || (_cache[14] = [
            _createElementVNode("div", { class: "card" }, [
              _createElementVNode("div", { class: "card-body" }, [
                _createElementVNode("h5", { class: "text-center mb-0" }, "Please Add Some Product First")
              ])
            ], -1)
          ])))
    ]),
    (_ctx.fettchDataArray.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("nav", _hoisted_24, [
              _createElementVNode("ul", _hoisted_25, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.links, (page, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    class: _normalizeClass(["page-item myPagination m-1", { active: page.active }])
                  }, [
                    _createElementVNode("button", {
                      class: "page-link",
                      onClick: ($event: any) => (_ctx.goToPage(Number(page.label))),
                      innerHTML: page.label
                    }, null, 8, _hoisted_26)
                  ], 2))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _cache[16] || (_cache[16] = _createElementVNode("label", {
                    class: "input-group-text",
                    for: "inputGroupSelect01"
                  }, "Size", -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select",
                    id: "inputGroupSelect01",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageSize) = $event)),
                    onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.fetchData && _ctx.fetchData(...args)))
                  }, _cache[15] || (_cache[15] = [
                    _createStaticVNode("<option value=\"10\">10</option><option value=\"20\">20</option><option value=\"30\">30</option><option value=\"40\">40</option><option value=\"50\">50</option><option value=\"100\">100</option>", 6)
                  ]), 544), [
                    [_vModelSelect, _ctx.pageSize]
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_30, [
      _createElementVNode("div", _hoisted_31, [
        _createElementVNode("div", _hoisted_32, [
          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("h5", {
              class: "modal-title text-center",
              id: "modalCenterTitle"
            }, "Add Stock"),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            })
          ], -1)),
          _createElementVNode("form", {
            name: "",
            onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("div", _hoisted_34, [
                _cache[17] || (_cache[17] = _createElementVNode("label", {
                  class: "col-sm-12 col-form-label",
                  for: "basic-icon-default-store"
                }, "Quantity ", -1)),
                _createElementVNode("div", _hoisted_35, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    id: "basic-icon-default-fullname",
                    placeholder: "Quantity",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.addstock.quantity) = $event))
                  }, null, 512), [
                    [_vModelText, _ctx.addstock.quantity]
                  ])
                ])
              ])
            ]),
            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "modal-footer text-center justify-content-center" }, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-outline-secondary",
                "data-bs-dismiss": "modal",
                id: "close"
              }, " Close "),
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn-primary"
              }, "Confirm")
            ], -1))
          ], 32)
        ])
      ])
    ])
  ]))
}