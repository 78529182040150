import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, vShow as _vShow, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-4" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "input-group" }
const _hoisted_5 = { class: "input-group" }
const _hoisted_6 = { class: "card mb-4 d-none d-md-block" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center"
}
const _hoisted_9 = {
  key: 1,
  class: "table-responsive"
}
const _hoisted_10 = {
  key: 0,
  class: "table card-table text-center"
}
const _hoisted_11 = { class: "table-border-bottom-0" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = { key: 4 }
const _hoisted_17 = { key: 5 }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "mobile-view d-block d-md-none" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "card-body" }
const _hoisted_23 = { class: "text-wrap" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { key: 2 }
const _hoisted_27 = { key: 3 }
const _hoisted_28 = { key: 4 }
const _hoisted_29 = { key: 5 }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = { key: 1 }
const _hoisted_32 = {
  key: 0,
  class: ""
}
const _hoisted_33 = { class: "d-flex justify-content-between flex-wrap" }
const _hoisted_34 = { "aria-label": "Page navigation" }
const _hoisted_35 = { class: "pagination flex-wrap" }
const _hoisted_36 = ["onClick", "innerHTML"]
const _hoisted_37 = { class: "row" }
const _hoisted_38 = { class: "col-12" }
const _hoisted_39 = { class: "input-group" }
const _hoisted_40 = { class: "mt-3" }
const _hoisted_41 = { class: "p-3 bg-light" }
const _hoisted_42 = {
  class: "modal fade",
  id: "viewModal",
  tabindex: "-1",
  role: "dialog",
  "aria-labelledby": "modalCenterTitle",
  "aria-hidden": "true"
}
const _hoisted_43 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_44 = { class: "modal-content" }
const _hoisted_45 = { class: "modal-body" }
const _hoisted_46 = { key: 0 }
const _hoisted_47 = {
  key: 1,
  class: "row"
}
const _hoisted_48 = { class: "col-12" }
const _hoisted_49 = { class: "mb-3 text-center h4" }
const _hoisted_50 = { class: "mb-3 text-center alert-success h5 p-2" }
const _hoisted_51 = { key: 0 }
const _hoisted_52 = { key: 1 }
const _hoisted_53 = { key: 2 }
const _hoisted_54 = { key: 3 }
const _hoisted_55 = { class: "text-center" }
const _hoisted_56 = { class: "text-wrap" }
const _hoisted_57 = { class: "mb-3 text-center" }
const _hoisted_58 = { class: "text-wrap fs-6" }
const _hoisted_59 = { class: "text-wrap fs-6" }
const _hoisted_60 = { class: "text-wrap fs-6" }
const _hoisted_61 = { class: "text-wrap fs-6" }
const _hoisted_62 = { class: "mb-3 text-center alert-secondary py-3" }
const _hoisted_63 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "input-group-prepend" }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _cache[7] || (_cache[7] = _createElementVNode("span", {
                class: "input-group-text",
                id: "basic-addon1"
              }, "From", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "date",
                class: "form-control",
                placeholder: "Enter Search Key !",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.from_date) = $event))
              }, null, 512), [
                [_vModelText, _ctx.from_date]
              ]),
              _cache[8] || (_cache[8] = _createElementVNode("span", {
                class: "input-group-text",
                id: "basic-addon1"
              }, "To", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "date",
                class: "form-control",
                placeholder: "Enter Search Key !",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.to_date) = $event))
              }, null, 512), [
                [_vModelText, _ctx.to_date]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control w-25",
                placeholder: "Enter Search Key !",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchKey) = $event))
              }, null, 512), [
                [_vModelText, _ctx.searchKey]
              ]),
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-primary me-1 ms-1",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.searchData && _ctx.searchData(...args)))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'magnifying-glass'] })
              ]),
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-default border",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.searchKey = '', _ctx.from_date = '', _ctx.to_date = '', _ctx.fetchData()))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'broom'] })
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[10] || (_cache[10] = [
              _createElementVNode("div", {
                class: "spinner-border text-primary",
                role: "status"
              }, [
                _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
              ], -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_ctx.fettchDataArray.length > 0)
                ? (_openBlock(), _createElementBlock("table", _hoisted_10, [
                    _cache[11] || (_cache[11] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, "Id"),
                        _createElementVNode("th", null, "Code"),
                        _createElementVNode("th", null, "Barcode"),
                        _createElementVNode("th", null, "Status"),
                        _createElementVNode("th", null, "Action")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem, index) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: dataItem.id
                        }, [
                          _createElementVNode("td", null, _toDisplayString(index + 1), 1),
                          _createElementVNode("td", null, _toDisplayString(dataItem.barcode), 1),
                          _createElementVNode("td", null, [
                            (_openBlock(), _createElementBlock("svg", {
                              ref_for: true,
                              ref: el => _ctx.barcodeRefs2[dataItem.id] = el,
                              style: {"width":"30px","height":"10px"}
                            }, null, 512))
                          ]),
                          _createElementVNode("td", null, [
                            (dataItem.status == 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Initial "))
                              : _createCommentVNode("", true),
                            (dataItem.status == 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_13, "Printed "))
                              : _createCommentVNode("", true),
                            (dataItem.status == 2)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_14, "Dispatch "))
                              : _createCommentVNode("", true),
                            (dataItem.status == 3)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_15, "In Store "))
                              : _createCommentVNode("", true),
                            (dataItem.status == 4)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_16, "Activated "))
                              : _createCommentVNode("", true),
                            (dataItem.status == 5)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Claim "))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "me-2 btn btn-sm btn-primary btn-disable",
                              "data-bs-toggle": "modal",
                              "data-bs-target": "#viewModal",
                              onClick: ($event: any) => (_ctx.viewData(dataItem.id))
                            }, [
                              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'eye'] })
                            ], 8, _hoisted_18)
                          ])
                        ]))
                      }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[12] || (_cache[12] = [
                    _createElementVNode("h5", { class: "text-center mb-0" }, "There Is No Barcode", -1)
                  ])))
            ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_20, [
      (_ctx.fettchDataArray.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fettchDataArray, (dataItem) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "card mb-4",
                key: dataItem.id
              }, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("small", null, " Code: " + _toDisplayString(dataItem.barcode), 1),
                  _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("small", null, [
                    _cache[13] || (_cache[13] = _createTextVNode("Barcode: ")),
                    (_openBlock(), _createElementBlock("svg", {
                      ref_for: true,
                      ref: (el) => _ctx.barcodeRefs[dataItem.id] = el,
                      style: {"width":"30px","height":"10px"}
                    }, null, 512))
                  ]),
                  _createElementVNode("p", _hoisted_23, [
                    _cache[14] || (_cache[14] = _createTextVNode("Status: ")),
                    (dataItem.status == 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_24, "Initial "))
                      : _createCommentVNode("", true),
                    (dataItem.status == 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_25, "Printed "))
                      : _createCommentVNode("", true),
                    (dataItem.status == 2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_26, "Dispatch "))
                      : _createCommentVNode("", true),
                    (dataItem.status == 3)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_27, "In Store "))
                      : _createCommentVNode("", true),
                    (dataItem.status == 4)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_28, "Activated "))
                      : _createCommentVNode("", true),
                    (dataItem.status == 5)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_29, "Claim "))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("button", {
                    type: "button",
                    class: "me-2 btn btn-sm btn-primary btn-disable",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#viewModal",
                    onClick: ($event: any) => (_ctx.viewData(dataItem.id))
                  }, [
                    _createVNode(_component_font_awesome_icon, { icon: ['fas', 'eye'] })
                  ], 8, _hoisted_30),
                  _cache[16] || (_cache[16] = _createElementVNode("button", {
                    type: "button",
                    class: "me-2 btn btn-sm btn-primary btn-disable"
                  }, " Option ", -1))
                ])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_31, _cache[17] || (_cache[17] = [
            _createElementVNode("div", { class: "card" }, [
              _createElementVNode("div", { class: "card-body" }, [
                _createElementVNode("h5", { class: "text-center mb-0 text-wrap" }, "There Is No Barcode")
              ])
            ], -1)
          ])))
    ]),
    (_ctx.fettchDataArray.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
          _createElementVNode("div", _hoisted_33, [
            _createElementVNode("nav", _hoisted_34, [
              _createElementVNode("ul", _hoisted_35, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationData.links, (page, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    class: _normalizeClass(["page-item myPagination m-1", { active: page.active }])
                  }, [
                    _createElementVNode("button", {
                      class: "page-link",
                      onClick: ($event: any) => (_ctx.goToPage(Number(page.label))),
                      innerHTML: page.label
                    }, null, 8, _hoisted_36)
                  ], 2))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _cache[19] || (_cache[19] = _createElementVNode("label", {
                    class: "input-group-text",
                    for: "inputGroupSelect01"
                  }, "Size", -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select",
                    id: "inputGroupSelect01",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pageSize) = $event)),
                    onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.fetchData && _ctx.fetchData(...args)))
                  }, _cache[18] || (_cache[18] = [
                    _createStaticVNode("<option value=\"20\" data-v-4a16f16e>20</option><option value=\"30\" data-v-4a16f16e>30</option><option value=\"40\" data-v-4a16f16e>40</option><option value=\"50\" data-v-4a16f16e>50</option><option value=\"100\" data-v-4a16f16e>100</option>", 5)
                  ]), 544), [
                    [_vModelSelect, _ctx.pageSize]
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_40, [
            _createElementVNode("span", _hoisted_41, " Showing: " + _toDisplayString(_ctx.paginationData.from) + " - " + _toDisplayString(_ctx.paginationData.to) + " of " + _toDisplayString(_ctx.paginationData.total), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_42, [
      _createElementVNode("div", _hoisted_43, [
        _createElementVNode("div", _hoisted_44, [
          _cache[40] || (_cache[40] = _createElementVNode("div", { class: "modal-header" }, [
            _createElementVNode("h5", {
              class: "modal-title",
              id: "modalCenterTitle"
            }, "Warranty Details"),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("span", { "aria-hidden": "true" }, "×")
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_45, [
            (_ctx.modalloading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_46, _cache[20] || (_cache[20] = [
                  _createElementVNode("div", { class: "d-flex justify-content-center align-items-center" }, [
                    _createElementVNode("div", {
                      class: "spinner-border text-primary",
                      role: "status"
                    }, [
                      _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                    ])
                  ], -1)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_47, [
                  _createElementVNode("div", _hoisted_48, [
                    _createElementVNode("div", _hoisted_49, [
                      _cache[21] || (_cache[21] = _createTextVNode(" Code: ")),
                      _createElementVNode("span", null, _toDisplayString(_ctx.viewDataArray.barcode), 1)
                    ]),
                    _createElementVNode("div", _hoisted_50, [
                      (_ctx.viewDataArray.status == 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_51, "Initial (" + _toDisplayString(_ctx.viewDataArray.status) + ")", 1))
                        : (_ctx.viewDataArray.status == 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_52, "Printed (" + _toDisplayString(_ctx.viewDataArray.status) + ")", 1))
                          : (_ctx.viewDataArray.status == 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_53, "Dispatch (" + _toDisplayString(_ctx.viewDataArray.status) + ")", 1))
                            : (_ctx.viewDataArray.status == 3)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_54, "Delivered (" + _toDisplayString(_ctx.viewDataArray.status) + ")", 1))
                              : (_ctx.viewDataArray.status == 4)
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 4,
                                    class: _normalizeClass({'text-success': _ctx.viewDataArray.status == 4})
                                  }, "Activated (" + _toDisplayString(_ctx.viewDataArray.status) + ")", 3))
                                : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_55, [
                      _withDirectives(_createElementVNode("p", _hoisted_56, [
                        _cache[22] || (_cache[22] = _createElementVNode("strong", null, "Product:", -1)),
                        _cache[23] || (_cache[23] = _createTextVNode()),
                        _createElementVNode("span", null, _toDisplayString(_ctx.viewDataArray.product?.name), 1)
                      ], 512), [
                        [_vShow, _ctx.viewDataArray.product]
                      ]),
                      _createElementVNode("p", null, [
                        _cache[24] || (_cache[24] = _createElementVNode("strong", null, "Created at:", -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.formatDateTime (_ctx.viewDataArray.created_at)), 1)
                      ]),
                      _createElementVNode("p", null, [
                        _cache[25] || (_cache[25] = _createElementVNode("strong", null, "Updated at:", -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.formatDateTime (_ctx.viewDataArray.updated_at)), 1)
                      ]),
                      _withDirectives(_createElementVNode("p", null, "Store: " + _toDisplayString(_ctx.viewDataArray.store_id), 513), [
                        [_vShow, _ctx.viewDataArray.store_id]
                      ]),
                      _withDirectives(_createElementVNode("p", null, [
                        _cache[26] || (_cache[26] = _createTextVNode("Order: ")),
                        _createElementVNode("span", null, _toDisplayString(_ctx.viewDataArray.order_id), 1)
                      ], 512), [
                        [_vShow, _ctx.viewDataArray.order_id]
                      ])
                    ]),
                    _withDirectives(_createElementVNode("div", _hoisted_57, [
                      _cache[35] || (_cache[35] = _createElementVNode("div", { class: "py-3 alert-primary alert" }, [
                        _createElementVNode("h4", { class: "mb-0" }, "Customer Info")
                      ], -1)),
                      _withDirectives(_createElementVNode("p", _hoisted_58, [
                        _cache[27] || (_cache[27] = _createElementVNode("strong", null, "Name: ", -1)),
                        _cache[28] || (_cache[28] = _createTextVNode()),
                        _createElementVNode("span", null, _toDisplayString(_ctx.viewDataArray.customer?.firstname) + " " + _toDisplayString(_ctx.viewDataArray.customer?.lastname), 1)
                      ], 512), [
                        [_vShow, _ctx.viewDataArray.customer_id]
                      ]),
                      _withDirectives(_createElementVNode("p", _hoisted_59, [
                        _cache[29] || (_cache[29] = _createElementVNode("strong", null, "Phone:", -1)),
                        _cache[30] || (_cache[30] = _createTextVNode()),
                        _createElementVNode("span", null, _toDisplayString(_ctx.viewDataArray.customer?.phone), 1)
                      ], 512), [
                        [_vShow, _ctx.viewDataArray.customer_id]
                      ]),
                      _withDirectives(_createElementVNode("p", _hoisted_60, [
                        _cache[31] || (_cache[31] = _createElementVNode("strong", null, "Email:", -1)),
                        _cache[32] || (_cache[32] = _createTextVNode()),
                        _createElementVNode("span", null, _toDisplayString(_ctx.viewDataArray.customer?.email), 1)
                      ], 512), [
                        [_vShow, _ctx.viewDataArray.customer_id]
                      ]),
                      _withDirectives(_createElementVNode("p", _hoisted_61, [
                        _cache[33] || (_cache[33] = _createElementVNode("strong", null, "Address:", -1)),
                        _cache[34] || (_cache[34] = _createTextVNode()),
                        _createElementVNode("span", null, _toDisplayString(_ctx.viewDataArray.customer?.address), 1)
                      ], 512), [
                        [_vShow, _ctx.viewDataArray.customer_id]
                      ])
                    ], 512), [
                      [_vShow, _ctx.viewDataArray.customer?.id]
                    ]),
                    _createElementVNode("div", _hoisted_62, [
                      _createElementVNode("p", null, [
                        _cache[36] || (_cache[36] = _createElementVNode("strong", null, "Activation Date: ", -1)),
                        _cache[37] || (_cache[37] = _createTextVNode()),
                        _createElementVNode("span", null, _toDisplayString(_ctx.viewDataArray.activation_date), 1)
                      ]),
                      _createElementVNode("p", _hoisted_63, [
                        _cache[38] || (_cache[38] = _createElementVNode("strong", null, "Expiry Date: ", -1)),
                        _cache[39] || (_cache[39] = _createTextVNode()),
                        _createElementVNode("span", null, _toDisplayString(_ctx.viewDataArray.expire_date), 1)
                      ])
                    ])
                  ])
                ]))
          ]),
          _cache[41] || (_cache[41] = _createElementVNode("div", { class: "modal-footer" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              "data-bs-dismiss": "modal"
            }, "Close")
          ], -1))
        ])
      ])
    ])
  ]))
}