import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-success alert-dismissible",
  role: "alert"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-8 col-sm-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "row mb-3" }
const _hoisted_9 = { class: "col-sm-12" }
const _hoisted_10 = { class: "input-group input-group-merge" }
const _hoisted_11 = { class: "row mb-3" }
const _hoisted_12 = { class: "col-sm-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.successReport)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[3] || (_cache[3] = [
          _createTextVNode(" Successfully "),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "aria-label": "Close"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      name: "",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", {
                    class: "col-sm-12 col-form-label",
                    for: "basic-icon-default-fullname"
                  }, "Category Name", -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _cache[4] || (_cache[4] = _createElementVNode("span", {
                        id: "basic-icon-default-fullname2",
                        class: "input-group-text"
                      }, [
                        _createElementVNode("i", { class: "bx bx-buildings" })
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "form-control",
                        id: "basic-icon-default-fullname",
                        placeholder: "Name",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
                        required: ""
                      }, null, 512), [
                        [_vModelText, _ctx.formData.name]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", {
                    class: "col-sm-12 col-form-label",
                    for: "basic-icon-default-store"
                  }, "Category Description ", -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _withDirectives(_createElementVNode("textarea", {
                      class: "form-control",
                      id: "exampleFormControlTextarea1",
                      rows: "14",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.description) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.formData.description]
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"col-md-4 col-sm-12\"><div class=\"card\"><div class=\"card-body\"><div class=\"page-image\"><img src=\"https://www.imageeditexpert.com/uploads/images/about/about.png\" alt=\"n/a\" class=\"slider-setting-img img-fluid\"></div><div class=\"mb-3\"><label for=\"formFile\" class=\"form-label\">Image Choose</label><input class=\"form-control\" type=\"file\" id=\"formFile\"></div><div class=\"row justify-content-end\"><div class=\"col-sm-12\"><button type=\"submit\" class=\"btn btn-primary\">Submit</button></div></div></div></div></div>", 1))
      ])
    ], 32)
  ]))
}